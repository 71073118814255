import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { BsCurrencyRupee, BsFillPersonFill } from "react-icons/bs";
import { FaHandHolding, FaHandsHelping } from "react-icons/fa";
import { MdRememberMe } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { RiLuggageDepositFill } from "react-icons/ri";
import { BiPlusMedical } from "react-icons/bi";
import { NavLink, useParams } from "react-router-dom";
import IconButton from "../../components/IconButton";
import TextInput from "../../components/TextInput";
import { Button } from "../../components";
import { useContext, useState } from "react";
import { LoadingContext, UserContext } from "../../context/Context";
import {
  checkOldMemberIdRequest,
  reverseCoordinatorSecurityRequest,
} from "../../api";

const CoordinatorNav = () => {
  const params = useParams();
  const { setIsLoading } = useContext(LoadingContext);
  const { user } = useContext(UserContext);
  const id = params.id;
  const [memberIdError, setMemberIdError] = useState("");
  const [existingMemberId, setExistingMemberId] = useState();
  const [isMidValid, setIsMidValid] = useState(false);

  const checkMemberId = async (memberId) => {
    try {
      setIsLoading(true);
      const response = await checkOldMemberIdRequest(memberId);
      console.log(response);
      if (response.data === null) {
        throw new Error("No memebr found with this MID.");
      }
      const oldMemberName = response.data.name;
      let memberName;
      const firstName = user.personalInfo.firstName;
      const middleName = user.personalInfo.middleName;
      const lastName = user.personalInfo.lastName;
      if (middleName !== "") {
        memberName = `${firstName} ${middleName} ${lastName}`;
      } else {
        memberName = `${firstName} ${lastName}`;
      }
      if (oldMemberName !== memberName) {
        throw new Error(
          `MID does not match - ${memberId} is registered with ${oldMemberName}. `
        );
      }
      setMemberIdError("");
      setIsMidValid(true);
      setIsLoading(false);
      return true;
    } catch (error) {
      console.log("Error", error);
      setMemberIdError(error.message);
      setIsLoading(false);
    }
  };

  const updateMID = async () => {
    try {
      setIsLoading(true);
      await reverseCoordinatorSecurityRequest({
        coordinatorId: user._id,
        mid: existingMemberId,
      });
      setIsLoading(false);
      window.location.reload();
    } catch (error) {
      console.log("Error", error);
      setMemberIdError(error.message);
      setIsLoading(false);
    }
  };

  return (
    <div className="">
      <div className="py-2">
        {!user.mid && (
          <>
            <h1 className="text-3xl text-red-500 font-extrabold text-center ">
              Action Required
            </h1>
            <div className="flex flex-col justify-center items-center px-4 text-center">
              In order to link your account with your old Member ID, please
              provide the MID below. If you do not have an old MID, you may skip
              this step/
              <div className="flex justify-center items-center m-4">
                <TextInput
                  type="text"
                  onSelectType="text"
                  name="establishmetnName"
                  validate={async (text) => {
                    return checkMemberId(text);
                  }}
                  value={existingMemberId}
                  handleFormChange={(e) => {
                    setMemberIdError(null);
                    setExistingMemberId(e.target.value);
                  }}
                  placeholder="Member Id"
                  isPhoneNumber={false}
                  errorMessage={memberIdError}
                />
                <Button tyoe={"secondary"} text={"Check MID"} additional={""} />
              </div>
              <Button
                tyoe={"secondary"}
                text={"Update MID"}
                additional={""}
                handleClick={updateMID}
                disabled={!isMidValid}
              />
            </div>
            <div className="h-px m-2 bg-gray-400 overflow-hidden" />
          </>
        )}

        <div className="py-5 grid grid-cols-3">
          <NavLink to="/coordinator/profile">
            <IconButton icon={<BsFillPersonFill />} title={"My Profile"} />
          </NavLink>
          <NavLink to="/coordinator/deposit">
            <IconButton
              icon={<RiLuggageDepositFill />}
              title={"Bank Deposit"}
            />
          </NavLink>
        </div>

        <div className="h-px m-2 bg-gray-400 overflow-hidden" />

        <h1 className="text-3xl text-brandGreen-400 font-extrabold text-center ">
          Members
        </h1>
        <div className="my-10 grid grid-cols-3 gap-y-5">
          <NavLink to="/coordinator/memberForm">
            <IconButton
              icon={<AiOutlineUsergroupAdd />}
              title={"Create Member"}
            />
          </NavLink>
          <NavLink
            to={id ? `/coordinator/member/${id}` : "/coordinator/member"}
          >
            <IconButton icon={<MdRememberMe />} title={"My Members"} />
          </NavLink>
          <NavLink
            to={id ? `/coordinator/accounts/${id}` : "/coordinator/accounts"}
          >
            <IconButton icon={<BsCurrencyRupee />} title={"Manage Accounts"} />
          </NavLink>
          <NavLink to={"/coordinator/loan"}>
            <IconButton icon={<FaHandHolding />} title={"Loan"} />
          </NavLink>
        </div>

        <div className="h-px m-2 bg-gray-400 overflow-hidden" />

        <h1 className="text-3xl text-brandGreen-400 font-extrabold text-center ">
          Coordinators
        </h1>
        <div className="my-10 grid grid-cols-3 gap-y-5">
          <NavLink to="/coordinator/coordinatorForm">
            <IconButton
              icon={<FaHandsHelping />}
              title={"Create Coordinator"}
            />
          </NavLink>
          <NavLink to="/coordinator/coordinator">
            <IconButton icon={<CgProfile />} title={"Coordinator's Profile"} />
          </NavLink>
        </div>
        <div className="h-px m-2 bg-gray-400 overflow-hidden" />

        <h1 className="text-3xl text-brandGreen-400 font-extrabold text-center ">
          Services
        </h1>
        <div className="my-10 grid grid-cols-3 gap-y-5">
          <NavLink to="/coordinator/medicalEmpanelment">
            <IconButton
              icon={<BiPlusMedical />}
              title={"Medical Empanelment"}
            />
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default CoordinatorNav;
