import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getMemberAccountsRequest } from "../../api";
import AccountsDetails from "../../components/AccountsDetails";
import LoanList from "../../components/LoanList";
import ShareReceipt from "../../components/ShareReceipt";
import { accountCodes } from "../../constants";
import {
  LoadingContext,
  PrintContext,
  UserContext,
} from "../../context/Context";

const MemberAccounts = () => {
  const { user } = useContext(UserContext);
  const { setIsLoading } = useContext(LoadingContext);
  const { setPrintComponent, setPrintName } = useContext(PrintContext);
  const [member, setMember] = useState(null);
  const [accounts, setAccounts] = useState(null);
  const [loanAccounts, setLoanAccounts] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  const getAcccounts = async () => {
    try {
      setIsLoading(true);
      const result = await getMemberAccountsRequest(user.phoneNumber);
      setIsLoading(false);
      if (!result.data.user._id === user._id) {
        setErrorMessage("You do not have access to this member. ");
        return;
      }
      setMember(result.data.user);
      const accs = result.data.accounts.filter((account) =>
        accountCodes.account.includes(account.type)
      );
      const loans = result.data.accounts.filter((account) =>
        accountCodes.loan.includes(account.type)
      );
      setAccounts(accs);
      setLoanAccounts(loans);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAcccounts();
    return () => {};
  }, []);

  return (
    <div>
      <p className="text-red-500">{errorMessage}</p>
      {member && accounts && (
        <div className="flex flex-col justify-center items-center">
          <AccountsDetails member={member} accounts={accounts} />

          {/* map */}
          <div className="mb-5 w-full lg:px-10">
            <div className="w-full lg:px-20">
              <h1 className="font-bold text-center text-2xl ">Shares</h1>
              <div className="invisible lg:visible grid grid-cols-4 font-bold">
                <p>S No.</p>
                <p>Number of Shares</p>
                <p>Date Purchased</p>
                <p>Action</p>
              </div>
            </div>
            <div className="h-px bg-gray-600 w-full" />

            {member.shareDetails.map((detail, ind) => (
              <>
                <div className="flex flex-col lg:grid lg:grid-cols-4 w-full lg:px-20">
                  <div className="flex flex-row items-center">
                    <p className="lg:hidden">
                      <b>S No.: </b>
                    </p>
                    <p> {ind + 1}</p>
                  </div>
                  <div className="flex flex-row items-center">
                    <p className="lg:hidden">
                      <b>Number of Shares: </b>
                    </p>
                    <p> {detail.noOfShares}</p>
                  </div>
                  <div className="flex flex-row items-center">
                    <p className="lg:hidden">
                      <b>Date Purchased</b>
                    </p>
                    <p> {new Date(detail.datePurchased).toDateString()}</p>
                  </div>
                  <div className="flex flex-row items-center">
                    <p className="lg:hidden">
                      <b>Actions: </b>
                    </p>
                    <p
                      className="text-brandGreen-500 underline cursor-pointer"
                      onClick={() => {
                        setPrintComponent(
                          <ShareReceipt member={member} detailId={detail._id} />
                        );
                        navigate(`/print`);
                        setPrintName(
                          `Share_Receipt_${member.phoneNumber}_${new Date(
                            detail.datePurchased
                          ).toLocaleDateString()}`
                        );
                      }}
                    >
                      Generate Receipt
                    </p>
                  </div>
                </div>
                <div className="h-px bg-gray-400 w-full" />
              </>
            ))}
          </div>
        </div>
      )}
      <LoanList loanAccounts={loanAccounts} />
    </div>
  );
};

export default MemberAccounts;
