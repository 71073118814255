import React from "react";
import { images } from "../constants";

const CoordinatorIntroLetter = ({ coordinator }) => {
  return (
    <>
      {coordinator && (
        <div
          className={`w-[2480px] border-2 relative border-black m-2 py-80 h-[3508px] text-5xl p-40 flex flex-col justify-start items-center a4`}
        >
          <img
            src={images.kshitij_transparent}
            alt="logo"
            width="300"
            className="absolute top-10 right-10"
          />
          <img
            src={images.intro_letter_bg}
            alt="logo"
            className="absolute top-0 right-0 z-[-1]"
          />
          <img
            src={images.kccsl_transparent}
            alt="logo"
            width="500"
            className="absolute top-10 left-10"
          />
          <div className="p-20 my-64 w-full flex flex-col justify-center items-center text-6xl text-justify">
            <h2>(For Private Circulation Only)</h2>
            <div className="my-32 w-10/12 text-6xl">
              <p className="my-16">Dear Friends,</p>
              <p className="my-16">
                I am happy to inform you that I have been associated as
                'Coordinator' on 'Kshitij' application of KCCSL. This society is
                striving for development and growth of an eco-system which can
                nurture self-reliance and provide strength to each member by
                inclusive utilisaiton of not only financial services but all
                verticals of available resources.
              </p>
              <p className="my-16">
                I have been assigned the following authorisations on my 6
                application:
              </p>
              <div>
                <p>- To accept applications for membership.</p>
                <p>- To provide loans to members.</p>
                <p>- To accept deposits from members.</p>
                <p>
                  - To provide specially crafted products and savings schemes to
                  member.
                </p>
              </div>
              <p className="my-16">
                The membership of the society is voluntarily granted to any
                major Indian, by paying ₹10/- to purchase at least one share of
                the society and by paying ₹10/- (total ₹20/-/) as membership
                fee.
              </p>
              <p className="my-16">
                Please feel free to contact me for futher details.
              </p>
              <div className="my-16 font-bold">
                <p>
                  {coordinator.personalInfo.firstName}{" "}
                  {coordinator.personalInfo.middleName}{" "}
                  {coordinator.personalInfo.lastName}
                </p>
                <p>Coordinator ID: {coordinator.coordinatorId}</p>
                <p>Mobile Number: {coordinator.phoneNumber}</p>
                <p>www.kccsl.in</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CoordinatorIntroLetter;
