/* eslint-disable no-loop-func */
export function generateAndDownloadCsv(header, data) {
  console.log("Generating CSV");
  console.log(data);
  if (!data || data.length === 0) return "";
  let content = "data:text/csv;charset=utf-8,\n";
  header.forEach((heading) => {
    content += heading + ",";
  });
  for (const row of data) {
    content += "\n";
    header.forEach((heading) => {
      content += row[heading] + ",";
    });
  }
  console.log("CSV generated");
  return content;
}
