import { useEffect, useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { images } from "../../constants";
import { websiteContent } from "./content";

const Website = () => {
  const [pageNumber, setPageNumber] = useState(0);

  return (
    <div>
      <div className="w-screen min-h-screen flex flex-col justify-center items-center overflow-x-hidden">
        <Page>{websiteContent[pageNumber].element}</Page>
        {console.log(pageNumber, websiteContent.length)}
        {pageNumber !== 0 && (
          <div
            onClick={() => setPageNumber((pageNumber) => pageNumber - 1)}
            className="absolute flex justify-center items-center p-2 bg-white rounded-full shadow-md shadow-gray-500 top-2 lg:top-5 left-5 text-1xl font-extrabold text-brandGreen-500 hover:text-brandOrange-500 cursor-pointer"
          >
            {/* <AiOutlineArrowLeft className="" /> */}
            Previous Page
          </div>
        )}
        {pageNumber !== websiteContent.length - 1 && (
          <div
            onClick={() => setPageNumber((pageNumber) => pageNumber + 1)}
            className="absolute flex justify-center items-center p-2 bg-white rounded-full shadow-md shadow-gray-500 top-2 lg:top-5 right-5 text-1xl font-extrabold text-brandGreen-500 hover:text-brandOrange-500 cursor-pointer"
          >
            {/* <AiOutlineArrowRight /> */}
            Next Page
          </div>
        )}
      </div>
    </div>
  );
};

const Page = ({ children }) => {
  return (
    <div className="relative min-w-full min-h-screen flex justify-center items-center mt-5 lg:mt-0">
      <img
        src={images.intro_letter_bg}
        alt="logo"
        className="fixed bottom-0  z-[-1] -scale-x-100 opacity-20"
      />
      {/* content */}
      <div className="mx-10 min-h-fit px-2 lg:px-20 py-10">{children}</div>
    </div>
  );
};

export default Website;
