import { jsPDF } from "jspdf";
import { useContext } from "react";
import { Button } from "../components";
import { PrintContext } from "../context/Context";

const ToPrint = () => {
  const { printComponent, printName } = useContext(PrintContext);

  const download = () => {
    const pdf = new jsPDF("p", "pt");
    if (document.getElementById("to-print")) {
      pdf.html(document.getElementById("to-print") || document.body, {
        callback: function (pdf) {
          const pageCount = pdf.internal.getNumberOfPages();
          console.log(pageCount);
          pdf.deletePage(2);
          pdf.deletePage(3);
          pdf.deletePage(4);
          pdf.deletePage(5);
          pdf.deletePage(6);
          pdf.save(`${printName}.pdf`);
        },
      });
    } else {
      console.log("receipt not found");
    }
  };

  const PDFFile = ({ children, ...props }) => {
    return <div id="to-print">{children}</div>;
  };

  return (
    <main>
      <div className="flex flex-col relative">
        {!printComponent && <p>Nothing to download.</p>}
        <div className="absolute top-14">
          <PDFFile>{printComponent}</PDFFile>
        </div>
        <div>
          <button>
            <Button
              text={"Download PDF"}
              additional="m-2"
              disabled={!printComponent}
              handleClick={download}
            />
          </button>
        </div>
      </div>
    </main>
  );
};

export default ToPrint;
