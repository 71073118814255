import React, { useState } from "react";
import { VscClose } from "react-icons/vsc";
import { AiOutlineCheck } from "react-icons/ai";
import { approveDepositRequest, denyDepositRequest } from "../api";

const ListDeposits = ({ deposits, isAdmin, setIsLoading }) => {
  const [errorMessage, setErrorMessage] = useState("");

  const approveDepost = async (depositId) => {
    try {
      setIsLoading(true);
      await approveDepositRequest({ depositId });
      setIsLoading(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
      setErrorMessage(error.message);
    }
  };

  const denyDeposit = async (depositId) => {
    try {
      setIsLoading(true);
      await denyDepositRequest({ depositId });
      setIsLoading(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="bg-gray-300 w-full py-8 flex flex-col justify-center items-center">
      <h1 className="text-brandGreen-500 font-bold text-2xl text-center">
        Previous Deposits
      </h1>
      {errorMessage ? (
        <p className="font-medium text-red-600 my-2">{errorMessage}</p>
      ) : (
        <></>
      )}
      {deposits.map((deposit) => {
        return (
          <div
            key={deposit._id}
            className="py-5 my-5 bg-white w-11/12 shadow-sm shadow-gray-400"
          >
            <div className="flex flex-col md:flex-row justify-evenly items-cente">
              <div className="flex flex-col justify-center items-center min-w-[300px]">
                <p className="flex w-full justify-between">
                  <b>Name: </b>
                  {deposit.coordinatorName}
                </p>
                <p className="flex w-full justify-between">
                  <b>Amount: </b>₹{deposit.amount}
                </p>
                <p className="flex w-full justify-between">
                  <b>Date: </b>
                  {new Date(deposit.dateRaised).toDateString()}
                </p>
                <p className="flex w-full justify-between">
                  <b>Status: </b>
                  {deposit.status === "APPROVED" ? (
                    <p className="text-green-600">{deposit.status}</p>
                  ) : (
                    <p className="text-red-500">{deposit.status}</p>
                  )}
                </p>
              </div>
              <div className="flex justify-center">
                <img
                  src={deposit.proof}
                  alt="proof"
                  className="shadow-md shadow-gray-400 rounded-3xl"
                />
              </div>
            </div>

            {isAdmin && deposit.status === "PENDING" && (
              <div className="flex justify-center">
                <VscClose
                  className="mx-5 text-5xl p-2 mt-4 bg-red-100 rounded-full text-red-500 cursor-pointer shadow-md shadow-gray-400"
                  onClick={() => {
                    denyDeposit(deposit._id);
                  }}
                />
                <AiOutlineCheck
                  className="mx-5 text-5xl p-2 mt-4 bg-green-100 rounded-full text-green-600 cursor-pointer shadow-md shadow-gray-400"
                  onClick={() => {
                    approveDepost(deposit._id);
                  }}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ListDeposits;
