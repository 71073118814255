import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getMemberRequest } from "../api";
import MemberForm from "../components/MemberForm";
import { LoadingContext, UserContext } from "../context/Context";

const MemberFormPage = () => {
  const urlParams = useParams();
  const [member, setMember] = useState(null);
  const { user } = useContext(UserContext);
  const { setIsLoading } = useContext(LoadingContext);

  useEffect(() => {
    const getUser = async (id) => {
      setIsLoading(true);
      const user = await getMemberRequest(id);
      setIsLoading(false);
      setMember(user.data);
    };

    const userId = urlParams.id;
    if (userId) {
      getUser(userId);
    }

    return () => {};
  }, [urlParams.id]);

  return (
    <div className="py-3 px-4 lg:px-12 flex flex-col justify-center items-center">
      {member ? (
        member.parentIds.includes(user._id) ? (
          <MemberForm member={member} />
        ) : (
          <div>
            <h1>You don't have access to this member.</h1>
          </div>
        )
      ) : (
        <MemberForm />
      )}
    </div>
  );
};

export default MemberFormPage;
