const IconButton = ({ icon, title }) => {
  return (
    <div className="flex flex-col justify-center items-center text-brandGreen-500 hover:text-brandOrange-500">
      <div className="text-3xl lg:text-5xl bg-white p-2 lg:p-4 rounded-full shadow-md cursor-pointer m-2 flex justify-center items-center text-center">
        {icon}
      </div>
      <p className="text-xs lg:text-2xl font-extrabold cursor-pointer text-center">
        {title}
      </p>
    </div>
  );
};

export default IconButton;
