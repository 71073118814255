import React, { useContext, useEffect, useState } from "react";
import { LoadingContext, UserContext } from "../context/Context";
import TextInput from "./TextInput";
import Button from "./Button";
import {
  createLoanRequest,
  getCurrentRateRequest,
} from "../api";
import { Link } from "react-router-dom";

const LoanApply = () => {
  const { user } = useContext(UserContext);
  const { setIsLoading } = useContext(LoadingContext);
  const [loanAmount, setLoanAmount] = useState();
  const [loanType, setLoanType] = useState("default");
  const [loanTenure, setLoanTenure] = useState("");
  const [hasAccepted, setHasAccepted] = useState(false);
  const [applicableInterest, setApplicableInterest] = useState(null);
  const [currentRate, setCurrentRate] = useState();

  const today = new Date().getDate();

  let ordinal;
  switch (today) {
    case 1:
      ordinal = "st";
      break;
    case 2:
      ordinal = "nd";
      break;
    case 3:
      ordinal = "rd";
      break;

    default:
      ordinal = "th";
      break;
  }

  const getCurrentRate = async () => {
    setIsLoading(true);
    const rate = await getCurrentRateRequest();
    setIsLoading(false);
    setCurrentRate(rate.data);
  };

  useEffect(() => {
    getCurrentRate();
    return () => {};
  }, []);

  const generateLoanApplication = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      const newAccount = await createLoanRequest({
        accountHolderDetails: {
          name:
            user.personalInfo.firstName +
            " " +
            user.personalInfo.middleName +
            " " +
            user.personalInfo.lastName,
          dateOfBirth: user.personalInfo.dateOfBirth,
          gender: user.personalInfo.gender,
          emailAddress: user.personalInfo.emailAddress,
          aadharNumber: user.personalInfo.aadharNumber,
          panNumber: user.personalInfo.panNumber,
        },
        credits: [],
        debits: [],
        userId: user.phoneNumber,
        type: loanType.toUpperCase(),
        creatorId: user._id,
        principalAmounts: [loanAmount],
        balance: loanAmount,
        duration: loanTenure,
        interestApplicable: [currentRate._id],
        monthlyDate: today + ordinal,
        isActive: true,
        isLoan: true,
        status: "PENDING",
        guarenters: [],
      });
      setIsLoading(false);
      console.log(newAccount);
      window.location.reload();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      alert("Something went wrong, please try again or contact admin.");
    }
  };

  return (
    <div className="bg-gray-300 flex flex-col justify-center items-center p-4">
      <h1 className="text-3xl font-bold text-brandGreen-500">
        Apply For New Loan
      </h1>
      <p>
        <b>Available Credit:</b> ₹{user.creditLine}
      </p>
      {user.creditLine > 0 &&  (
        <>
          <form
            className="flex flex-col justify-center items-center  p-4"
            onSubmit={generateLoanApplication}
          >
            <div>
              <TextInput
                type="Number"
                onSelectType="Number"
                name="amount"
                validate={(text) => {
                  if (parseInt(text) < 1) {
                    setLoanAmount(null);
                    return false;
                  }
                  return true;
                }}
                value={loanAmount}
                handleFormChange={(e) => setLoanAmount(e.target.value)}
                placeholder="Loan Amount"
                isPhoneNumber={true}
                countryCode="₹"
                errorMessage={"Can't be less than ₹1."}
              />
            </div>

            <div className="w-fit">
              <select
                className="py-1.5 px-4 h-full rounded-lg w-full my-2"
                name="accountType"
                onChange={(e) => {
                  setLoanType(e.target.value);
                  if (e.target.value === "FIXED") {
                    setLoanTenure("");
                  } else {
                    setLoanTenure("0");
                  }
                  setApplicableInterest(currentRate[e.target.value]);
                  console.log(currentRate);
                }}
                value={loanType}
              >
                <option value="default" disabled>
                  Loan Type
                </option>
                <option value="FLEX">Flexi Term Loan</option>
                <option value="FIXED">Fixed Term Loan</option>
              </select>
            </div>

            {loanType === "FIXED" && (
              <div>
                <div className="flex flex-col justify-center items-center">
                  <TextInput
                    type="Number"
                    onSelectType="Number"
                    name="tenure"
                    validate={(text) => {
                      if (parseInt(text) < 1 || parseInt(text) > 15) {
                        setLoanTenure(null);
                        return false;
                      }
                      return true;
                    }}
                    value={loanTenure}
                    handleFormChange={(e) => setLoanTenure(e.target.value)}
                    placeholder="Loan Tenure"
                    errorMessage={"Must be between 1 and 15"}
                  />
                  <p className="text-sm text-gray-600">(In Number of Years)</p>
                </div>
              </div>
            )}

            <p>Interest Rate: {applicableInterest}%</p>

            <div>
              <input
                type="checkbox"
                onChange={(e) => setHasAccepted(!hasAccepted)}
                id="has-accepted"
              />
              <label htmlFor="has-accepted">
                {" "}
                I have read and agree to the{" "}
                <Link
                  className="cursor-pointer text-brandGreen-500 underline"
                  to="/loan/t&c"
                >
                  {" "}
                  terms and conditions{" "}
                </Link>
                of the loan.
              </label>
            </div>

            <button
              type="submit"
              disabled={
                !loanAmount ||
                loanType === "default" ||
                !loanTenure ||
                !hasAccepted
              }
            >
              <Button
                text={"Apply"}
                additional={"m-4"}
                disabled={
                  !loanAmount ||
                  loanType === "default" ||
                  !loanTenure ||
                  !hasAccepted
                }
              />
            </button>
          </form>
          <p className="text-gray-500">
            If loan amount is greater than available credit, coordinator may
            approve after sourcing amount from other members.
          </p>
        </>
      )}
    </div>
  );
};

export default LoanApply;
