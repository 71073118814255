import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../components";
import { FaSearch } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { accountCodes, countryCodes } from "../../constants";
import { getMemberAccountsRequest } from "../../api";
import { LoadingContext, UserContext } from "../../context/Context";
import AccountsDetails from "../../components/AccountsDetails";
import LoanList from "../../components/LoanList";

const CoordinatorTransaction = () => {
  const params = useParams();
  const [phoneNumber, setPhoneNumber] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const [member, setMember] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [loanAccounts, setLoanAccounts] = useState([]);
  const { user } = useContext(UserContext);
  const { setIsLoading } = useContext(LoadingContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (params.id) {
      setPhoneNumber(params.id.slice(3));
      getMemberAccounts(params.id);
    }
    return () => {};
  }, [params.id]);

  const getMemberAccounts = async (id) => {
    try {
      setIsLoading(true);
      const result = await getMemberAccountsRequest(id);
      setIsLoading(false);
      if (!result.data.user.parentIds.includes(user._id)) {
        setErrorMessage("You do not have access to this member. ");
        return;
      }
      setMember(result.data.user);
      const accs = result.data.accounts.filter((account) =>
        accountCodes.account.includes(account.type)
      );
      const loans = result.data.accounts.filter((account) =>
        accountCodes.loan.includes(account.type)
      );
      setAccounts(accs);
      setLoanAccounts(loans);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setErrorMessage("Member not found. ");
    }
  };

  const fetchMemberDetails = async (e) => {
    e.preventDefault();
    navigate(`/coordinator/accounts/+91${phoneNumber}`);
  };

  return (
    <div className="">
      <div className="shadow-lg bg-white flex flex-col lg:flex-row justify-center items-center lg:justify-evenly">
        <div className="rounded-xl m-8 relative overflow-hidden lg:w-1/3">
          <form onSubmit={fetchMemberDetails}>
            <input
              className="w-full border-2 border-gray-600 rounded-xl py-2 px-12 focus:outline-brandGreen-500"
              placeholder="Search Member"
              type="number"
              value={phoneNumber}
              onChange={(e) => {
                e.preventDefault();
                setPhoneNumber(e.target.value);
                setErrorMessage(null);
              }}
            />
            <div className="absolute top-1 left-1.5 p-1.5 rounded-l-lg  bg-gray-300">
              {countryCodes.INDIA}
            </div>

            {errorMessage ? (
              <p className="font-medium text-red-600 my-2">{errorMessage}</p>
            ) : (
              <></>
            )}
            <button type="submit">
              <div className="absolute top-0 right-0 bg-brandGreen-500 text-white hover:bg-brandGreen-600 p-3.5 cursor-pointer">
                <FaSearch />
              </div>
            </button>
          </form>
        </div>
      </div>
      <div>
        {member && (
          <div className="flex flex-col justify-center items-center">
            <AccountsDetails member={member} accounts={accounts} />
            <LoanList loanAccounts={loanAccounts} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CoordinatorTransaction;
