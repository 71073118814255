import React, { useContext } from "react";
import { UserContext } from "../context/Context";
import Button from "./Button";
import { images } from "../constants";

const NoticeBoard = () => {
  const { user } = useContext(UserContext);
  const role = localStorage.getItem("role");
  console.log(user);
  return (
    <div className=" flex flex-col justify-center items-center  shadow-gray-300 min-h-[180px] p-2 relative">
      <div className="flag absolute w-full h-full"></div>
      <h1 className="text-center text-gray-100 text-5xl font-extrabold z-10">
        Wishing Happy Independence Day 2023
      </h1>
      {/* <div className="text-brandGreen-600 text-1xl font-extrabold my-8 text-center">
        {role === "Coordinator" && (
          <>
            <h1 className="text-2xl">
              All coordinators are advised to deposit their collection with
              bank.
            </h1>
          </>
        )}
      </div> */}
    </div>
  );
};

export default NoticeBoard;
