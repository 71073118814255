import React, { useContext, useState } from "react";
import {
  BsFillCircleFill,
  BsFillEyeFill,
  BsFillEyeSlashFill,
} from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { resetPasswordRequest } from "../api";
import { Button } from "../components";
import { LoadingContext, RoleContext, UserContext } from "../context/Context";

const ResetPassword = () => {
  const [isEightChars, setIsEightChars] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNumeric, setHasNumeric] = useState(false);
  const [hasSpecialCharacter, setHasSpecialCharacter] = useState(false);

  const [newPassword, setNewPassword] = useState();
  const [showNewPassword, setShowNewPassword] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState();
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  const { user } = useContext(UserContext);
  const { role } = useContext(RoleContext);
  const { setIsLoading } = useContext(LoadingContext);

  const navigate = useNavigate();

  const redText = "text-red-500 flex justify-start items-start text-sm";
  const greenText = "text-green-600 flex justify-start items-start text-sm";

  const specialCharcters = "`~!@#$%^&*()-_=+[{]}|;:,<.>/?'" + '"';
  const numbers = "1234567890";
  const upperCase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowerCase = "abcdefghijklmnopqrstuvwxyz";

  const resetPassword = async (e) => {
    e.preventDefault();
    try {
      if (
        !isEightChars ||
        !hasUpperCase ||
        !hasNumeric ||
        !hasSpecialCharacter
      ) {
        setErrorMessage("Password doesn't match given criteria");
        return;
      }
      if (newPassword !== confirmPassword) {
        setErrorMessage("Passwords must match");
        return;
      }

      setIsLoading(true);
      await resetPasswordRequest({
        phoneNumber: user.phoneNumber,
        newPassword,
      });
      setIsLoading(false);
      switch (role) {
        case "Coordinator":
          navigate("/coordinator/profile");
          break;
        case "Member":
          navigate("/member/profile");
          break;
        case "Admin":
          navigate("/admin");
          break;

        default:
          break;
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setErrorMessage("Something went wrong, please try again.");
    }
  };

  const checkPassword = (e) => {
    e.preventDefault();
    setNewPassword(e.target.value);

    const value = e.target.value;
    //TIDIL Add regex
    if (value.length >= 8) {
      setIsEightChars(true);
    } else {
      setIsEightChars(false);
    }

    for (let char of specialCharcters) {
      if (value.includes(char)) {
        setHasSpecialCharacter(true);
        break;
      } else {
        setHasSpecialCharacter(false);
      }
    }

    for (let char of numbers) {
      if (value.includes(char)) {
        setHasNumeric(true);
        break;
      } else {
        console.log(false);
        setHasNumeric(false);
      }
    }

    for (let char of upperCase) {
      if (value.includes(char)) {
        setHasUpperCase(true);
        break;
      } else {
        setHasUpperCase(false);
      }
    }

    for (let char of lowerCase) {
      if (value.includes(char)) {
        setHasLowerCase(true);
        break;
      } else {
        setHasLowerCase(false);
      }
    }
  };

  return (
    <div className="flex justify-center items-center w-screen h-screen p-4">
      <div className="p-5 bg-white w-fit shadow-md shadow-gray-400 rounded-lg flex flex-col ">
        <h1 className="text-3xl font-bold text-brandGreen-500 my-2 text-center">
          Reset Password
        </h1>

        <div className="px-6 py-6">
          <ul>
            <li className={isEightChars ? greenText : redText}>
              <BsFillCircleFill className="text-xs mr-2 mt-1" /> Must have at
              least eight characters
            </li>
            <li className={hasUpperCase ? greenText : redText}>
              <BsFillCircleFill className="text-xs mr-2 mt-1" /> Must have at
              least one uppercase letter
            </li>
            <li className={hasLowerCase ? greenText : redText}>
              <BsFillCircleFill className="text-xs mr-2 mt-1" /> Must have at
              least one lowercase letter
            </li>
            <li className={hasNumeric ? greenText : redText}>
              <BsFillCircleFill className="text-xs mr-2 mt-1" /> Must have at
              least one numeric character
            </li>
            <li className={hasSpecialCharacter ? greenText : redText}>
              <BsFillCircleFill className="text-xs mr-2 mt-1" /> Must have at
              least one special character
            </li>
          </ul>
        </div>

        <form onSubmit={resetPassword}>
          <div className="relative">
            <input
              className="w-full border-2 border-gray-300 rounded-xl py-2 px-4 my-2 focus:outline-brandGreen-500"
              placeholder="New Password"
              value={newPassword}
              onChange={checkPassword}
              type={showNewPassword ? "" : "password"}
            />
            <div
              className="absolute top-3 right-3 text-3xl text-brandGreen-500"
              onClick={() => {
                setShowNewPassword(!showNewPassword);
              }}
            >
              {showNewPassword ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
            </div>
          </div>

          <div className="relative">
            <input
              className="w-full border-2 border-gray-300 rounded-xl py-2 px-4 my-2 focus:outline-brandGreen-500"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => {
                e.preventDefault();
                setConfirmPassword(e.target.value);
                setErrorMessage(null);
              }}
              type={showConfirmPassword ? "" : "password"}
            />
            <div
              className="absolute top-3 right-3 text-3xl text-brandGreen-500"
              onClick={() => {
                setShowConfirmPassword(!showConfirmPassword);
              }}
            >
              {showNewPassword ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
            </div>
          </div>
          <p className="text-red-500">{errorMessage}</p>
          <button type="submit" className="w-full">
            <Button text={"Change Password"} additional={"my-4 w-full"} />
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
