import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../components";
import { FaSearch } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { countryCodes } from "../../constants";
import { getCoordinatorRequest } from "../../api";
import CoordinatorDetails from "../../components/CoordinatorDetails";
import { LoadingContext, UserContext } from "../../context/Context";

const AdminCoordinator = () => {
  const params = useParams();
  const [phoneNumber, setPhoneNumber] = useState(params.id);
  const [errorMessage, setErrorMessage] = useState(null);
  const [coordinator, setCoordinator] = useState(null);
  const { setIsLoading } = useContext(LoadingContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (params.id) {
      setPhoneNumber(params.id.slice(3));
      getDetails(params.id);
    }
    return () => {};
  }, [params.id]);

  const getDetails = async (id) => {
    try {
      setIsLoading(true);
      const existingCoordinator = await getCoordinatorRequest(id);
      setIsLoading(false);
      setCoordinator(existingCoordinator.data);
    } catch (error) {
      setIsLoading(false);
      setErrorMessage("Coordinator not found. ");
    }
  };

  const fetchMemberDetails = async (e) => {
    e.preventDefault();
    navigate(`/admin/coordinator/+91${e.target.value}`);
  };

  return (
    <div className="">
      <div className="shadow-lg bg-white flex flex-col lg:flex-row justify-center items-center pt-8 lg:justify-evenly lg:p-8">
        <Button
          tyoe={"primary"}
          text={"Create Coordinator"}
          additional={"lg:text-xl"}
          handleClick={() => {
            navigate("/admin/coordinatorForm");
          }}
        />
        <div className="rounded-xl m-8 relative overflow-hidden lg:w-1/3">
          <form onSubmit={fetchMemberDetails}>
            <input
              className="w-full border-2 border-gray-600 rounded-xl py-2 px-12 focus:outline-brandGreen-500"
              placeholder="Search Coordinator"
              type="number"
              value={phoneNumber}
              onChange={(e) => {
                e.preventDefault();
                setPhoneNumber(e.target.value);
                setErrorMessage(null);
              }}
            />
            <div className="absolute top-1 left-1.5 p-1.5 rounded-l-lg  bg-gray-300">
              {countryCodes.INDIA}
            </div>

            {errorMessage ? (
              <p className="font-medium text-red-600 my-2">{errorMessage}</p>
            ) : (
              <></>
            )}
            <button type="submit">
              <div className="absolute top-0 right-0 bg-brandGreen-500 text-white hover:bg-brandGreen-600 p-3.5 cursor-pointer">
                <FaSearch />
              </div>
            </button>
          </form>
        </div>
      </div>
      <div>
        {coordinator && <CoordinatorDetails coordinator={coordinator} />}
      </div>
    </div>
  );
};

export default AdminCoordinator;
