import React, { useContext, useEffect, useState } from "react";
import {
  CameraContext,
  LoadingContext,
  UserContext,
} from "../../context/Context";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import { createDepositRequest } from "../../api";
import ListDeposits from "../../components/ListDeposits";
import { getCoordinatorDeposits } from "../../api";

const CoordinatorDeposit = () => {
  const [previousDeposits, setPreviousDeposits] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  const { user } = useContext(UserContext);
  const { setIsLoading } = useContext(LoadingContext);
  const getPreviousDeposits = async () => {
    try {
      setIsLoading(true);
      const deposits = await getCoordinatorDeposits(user.coordinatorId);
      setIsLoading(false);
      setPreviousDeposits(deposits.data);
    } catch (error) {
      console.log(error);
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    getPreviousDeposits();

    return () => {};
  }, []);

  return (
    <div className="flex flex-col justify-center items-center py-5 ">
      <h1 className="text-brandGreen-500 font-bold text-4xl">Bank Deposit</h1>
      {errorMessage ? (
        <p className="font-medium text-red-600 my-2">{errorMessage}</p>
      ) : (
        <></>
      )}
      <DepositForm user={user} setIsLoading={setIsLoading} />
      <div className="h-px bg-gray-400 w-full" />

      {previousDeposits && (
        <ListDeposits
          deposits={previousDeposits}
          setIsLoading={setIsLoading}
          isAdmin={user.roles.includes("admin")}
        />
      )}
    </div>
  );
};

const DepositForm = ({ user, setIsLoading }) => {
  const { cameraState, setCameraState } = useContext(CameraContext);

  const [amount, setAmount] = useState();
  const [paymentType, setPaymentType] = useState("default");
  const [proof, setProof] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("creating deposit");
    try {
      const depositData = {
        coordinatorId: user.coordinatorId,
        coordinatorName:
          user.personalInfo.firstName +
          " " +
          user.personalInfo.middleName +
          " " +
          user.personalInfo.lastName,
        amount,
        paymentType,
        proof,
      };
      setIsLoading(true);
      await createDepositRequest(depositData);
      setIsLoading(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="bg-gray-300 w-screen flex flex-col justify-center items-center mt-8 py-4">
      <h1 className="text-brandGreen-500 font-bold text-2xl text-center">
        Create New Deposit
      </h1>
      {errorMessage ? (
        <p className="font-medium text-red-600 my-2">{errorMessage}</p>
      ) : (
        <></>
      )}

      <form className="flex flex-col items-center" onSubmit={handleSubmit}>
        <div className="flex flex-col md:flex-row justify-evenly w-screen px-4">
          <div className="flex flex-col justify-center">
            <TextInput
              type="Number"
              onSelectType="Number"
              name="amount"
              validate={(text) => {
                if (parseInt(text) < 1) {
                  setAmount(null);
                  return false;
                }
                return true;
              }}
              value={amount}
              handleFormChange={(e) => setAmount(e.target.value)}
              placeholder="Deposit Amount"
              isPhoneNumber={true}
              countryCode="₹"
              errorMessage={"Can't be less than ₹1."}
            />
            <select
              className="py-1.5 px-4  rounded-lg w-full my-2"
              name="accountType"
              onChange={(e) => {
                setPaymentType(e.target.value);
              }}
              value={paymentType}
            >
              <option value="default" disabled>
                Payment Method
              </option>
              <option value="CASH">Cash</option>
              <option value="CHEQUE">Cheque</option>
              <option value="UPI">UPI</option>
            </select>
          </div>
          <div className="flex flex-col justify-center items-center">
            <h2 className="text-center">Proof of Deposit</h2>
            <div
              className="p-4 cursor-pointer photo flex justify-center items-center text-xl font-semibold text-gray-600"
              onClick={() => {
                setCameraState({
                  ...cameraState,
                  isOpen: true,
                  setTargetImage: setProof,
                });
              }}
            >
              {proof !== "" ? (
                <img
                  src={proof}
                  alt={"proof"}
                  className="aspect-square object-cover"
                />
              ) : (
                <input
                  className="bg-transparent text-center text-gray-600 cursor-pointer"
                  name="proofPhoto"
                  disabled={true}
                  placeholder="Photo of Cheque/Deposit Reciept"
                />
              )}
            </div>
          </div>
        </div>
        <button
          type="submit"
          disabled={!amount || paymentType === "default" || !proof}
        >
          <Button
            text={"Apply"}
            additional={"m-4"}
            disabled={!amount || paymentType === "default" || !proof}
          />
        </button>
      </form>
    </div>
  );
};

export default CoordinatorDeposit;
