import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getMemberAccountRequest, getMemberRequest } from "../api";
import { LoadingContext, UserContext } from "../context/Context";

const AccountStatement = () => {
  const { user } = useContext(UserContext);
  const { setIsLoading } = useContext(LoadingContext);
  const [account, setAccount] = useState();
  const [member, setMember] = useState();
  const [accountType, setAccountType] = useState();
  const [errorMessage, setErrorMessage] = useState("Loading...");
  const [transactions, setTransactions] = useState([]);
  const params = useParams();

  const getInfo = async () => {
    try {
      const accountNumber = params.accountNumber;
      setIsLoading(true);
      const account = await getMemberAccountRequest(accountNumber);
      const member = await getMemberRequest(account.data.userId);
      setIsLoading(false);
      setAccount(account.data);
      setMember(member.data);
      console.log(account);
      const trans = [...account.data.credits, ...account.data.debits];
      console.log(1, trans);
      trans.sort((a, b) => {
        const aDate = new Date(a.createdAt);
        const bDate = new Date(b.createdAt);
        const diff = aDate - bDate;
        console.log(32, a, b, diff);
        return diff;
      });
      console.log(2, trans);
      setTransactions(trans);

      switch (account.data.type) {
        case "RDS" || "RDM" || "RDL":
          setAccountType("RD");
          break;

        case "FDS" || "FDM" || "FDL":
          setAccountType("FD");
          break;

        case "S":
          setAccountType("Savings");
          break;

        case "C":
          setAccountType("Collection");
          break;

        default:
          break;
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setErrorMessage(error.response.data);
    }
  };

  useEffect(() => {
    getInfo();
    return () => {};
  }, []);

  if (!member || !setAccount) {
    return <h1>{errorMessage}</h1>;
  }

  if (!member.parentIds.includes(user._id) && user._id !== member._id) {
    return <div>You don't have access to this statement. </div>;
  }

  return (
    <div className="flex flex-col justify-center items-center w-screen  p-4">
      <div className="p-4">
        <h2 className="text-2xl font-semibold">{accountType} Statement</h2>
        <h2 className="text-2xl font-semibold">
          Member Name: {account.accountHolderDetails.name}
        </h2>
        <h2 className="text-2xl font-semibold">
          Account Number: {account.accountNumber}
        </h2>
      </div>
      <h1 className="text-red-600">Failed to fetch information...</h1>
    </div>
  );
};

export default AccountStatement;
