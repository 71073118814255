import { images } from "../../constants";

export const websiteContent = [
  {
    pageNumber: 1,
    element: (
      <div className="overflow-hidden">
        <div className="flex flex-col justify-start items-center">
          <h1 className="text-3xl font-extrabold text-center mb-5 text-brandGreen-500">
            WE RESERGED
          </h1>
          <p className="text-xs">
            Since 2010 we are striving for upliftment of Members. After a
            turmoil due to economic slowdown, overall system collapse, covid and
            reverse circumstances for a long period, we have reawakened and
            converted the basic business model into an Eco-system –meant for
            overall growth of our members. The reasons for stall back are
            obvious but we have decided to reborn with a fully member-oriented
            society. Having registered to operate in nine states – Himanchal
            Pradesh, Uttarakhand, Punjab, Uttar Pradesh, Bihar, Madhya Pradesh,
            Rajasthan, Gujrat and Maharashtra with a wide recognition by more
            than 5,00,000 members.
          </p>
          <br></br>
          <p className="text-xs">
            In this rebirth of the society, Concerns about environment have been
            kept on top. All members are discouraged for use of paper and
            plastic. Fully digitalized environment is made available.
          </p>
          <br></br>
          <p className="text-xs">
            We are
            <b className="text-brandOrange-400">
              Kamuna Credit Co-operative Society Limited (KCCSL){" "}
            </b>{" "}
            - a registered Multistate Cooperative Society with registration NO.
            MSCS/CR/379/2010 duly registered with Central Registrar, Ministry of
            Cooperation, Government of India.
          </p>
        </div>
      </div>
    ),
  },

  {
    pageNumber: 2,
    element: (
      <div className="overflow-hidden">
        <div className="flex flex-col justify-start items-center">
          <h1 className="text-2xl font-extrabold text-center text-brandGreen-500">
            Our Guiding Principles
          </h1>
          <br></br>
          <div className="text-left">
            <b className="text-brandOrange-400">Vision</b>
            <p className="text-xs">
              We strive for development and growth of an eco-system which can
              nurture self-reliance and provide strength to each member by
              inclusive utilization of not only financial resources but all
              verticals of available resources.
            </p>
            <br></br>

            <b className="text-brandOrange-400">Mission</b>
            <p className="text-xs">
              To create the biggest, strongest, universal and uniform eco system
              to strengthen each individual member, by optimum utilization of
              Mass participation
            </p>
          </div>
          <br></br>
          <div className="h-px bg-gray-400 w-full" />
          <br></br>

          <h1 className="text-2xl font-extrabold text-center text-brandGreen-500">
            Strategic Goals
          </h1>
          <br></br>
          <div className="text-left">
            <b className="text-brandOrange-400">
              1. Enhance each Member's worth:
            </b>
            <p className="text-xs">
              Worth of any organization depends on Stakeholders value creation
              and its expansion. In the context of our Society, as a strategy,
              we have to strive for all around Member’s worth (Not Value) both
              in terms of financial gains and strength through the ecosystem. To
              achieve this goal, we are incorporating various steps by pushing
              initiatives through various schemes, products, and plans. At the
              same time, reduction in expenses to the lowest level is also one
              of such endeavor in which we have taken drastic steps and the cost
              of running the show has been reduced to a great extent.
            </p>
          </div>

          <div className="text-left">
            <br></br>
            <b className="text-brandOrange-400">
              2. Expand the stakeholder base:
            </b>
            <p className="text-xs">
              We strive for development and growth of an eco-system which can
              nurture self-reliance and provide strength to each member by
              inclusive utilization of not only financial resources but all
              verticals of available resources.
            </p>
            <br></br>
            <b className="text-brandOrange-400">
              3. Keep the bottom line intact:
            </b>
            <p className="text-xs">
              Our bottom line is parallel to the bottom line of our members.
              Every member must be Financially self-dependent, have a
              sustainable growth prospect and fear free with the reverse
              situations coming through the path of life. We are on Fastrack
              mode to flash out our vision to the maximum members.
            </p>
            <p className="text-xs">
              It became possible only with the optimum use of Digitalization.
              The Bottomline has been redefined to include bare minimum needs of
              each member and to move it towards catering the luxuries of life.
              Focus on the base is on health, employment, livelihood, and
              inclusion of resources. A Financial help on a touch, secured life,
              Fear less from trials, equal opportunity for earning, and
              ownership depending on involvement are a few pioneering attributes
              which have been incorporated as philosophy of the society.
            </p>
          </div>
          <br></br>
          <div className="text-left">
            <b className="text-brandOrange-400">
              4. Optimum Member satisfaction:
            </b>
            <p className="text-xs">
              Commencement of Helpline, Immediate response through mail and
              other communication systems, Hitech mobile application, robust web
              presence and what not. We are striving positively towards more and
              more deep connect with our all members. A Separate division is
              available relentlessly, first time by any cooperative Society to
              provide committed assistance and help to all members for their
              multi- dimensional issues including their personal financial
              concerns.
            </p>
          </div>
        </div>
      </div>
    ),
  },

  {
    pageNumber: 3,
    element: (
      <div className="overflow-hidden">
        <div className="flex flex-col justify-start items-center">
          <h1 className="text-3xl font-extrabold text-center text-brandGreen-500">
            The Team
          </h1>
          <br></br>
          <div className="text-left">
            <p className="text-xs">
              We are pioneers to introduce a 100% ‘By the members, For the
              members and Of the members’ Society. All members along with a
              voluntary helping hand called “Coordinators” comprise the team.
              This is resulting in their ownership and strong connection with
              the society while reducing cost of operations to unimaginably low.
            </p>
          </div>
        </div>
        <br></br>
        <br></br>
        <div className="flex flex-col justify-start items-center">
          <h1 className="text-3xl font-extrabold text-center text-brandGreen-500">
            The Member
          </h1>
          <br></br>
          <p className="text-xs">
            The Membership of the KCCSL is voluntarily granted to any Indian who
            is capable of entering into Contract under Indian Contract Act,
            1872. Any Indian citizen without discretion of caste, creed or
            culture can become a member by paying Rs.10/- to purchase at least
            One share of the society and by paying Rs.10/- as membership fee
            (Total Rs. 20/- only). Anyone can approach a ‘Coordinator’ to become
            a member.
          </p>
        </div>

        <br></br>
        <br></br>

        <div className="flex flex-col justify-start items-center">
          <h1 className="text-3xl font-extrabold text-center text-brandGreen-500">
            The Coordinator
          </h1>
          <br></br>
          <div className="text-left">
            <p className="text-xs">
              Coordinators of KCCSL are the volunteer members – Any member of
              the Society can opt to become a coordinator by depositing security
              amount only. A Coordinator can do the following activities on the
              Mobile Application and Software to facilitate and help all
              members: -
            </p>
            <br></br>
            <p className="text-xs">
              <b> A. </b> Can include any new citizen of India as member
              (Subject to approval of Board of Directors).
            </p>
            <p className="text-xs">
              <b>B. </b>Can create another Coordinator.
            </p>
            <p className="text-xs">
              <b> C. </b> Can disburse Loans to members independently as per the
              rules framed by the society in this behalf (Subject to Approval of
              Board of Directors).
            </p>
            <p className="text-xs">
              <b> D. </b> Can make aware all the members about new products and
              schemes of the society.
            </p>
            <p className="text-xs">
              <b> E. </b> Can work independently and freely for goals of the
              Society and at the same time can be incentivized also.
            </p>
            <p className="text-xs">
              <b> F. </b> Can accept deposits from members under various schemes
              of the Society.
            </p>
            <br></br>
            <p className="text-xs">
              Coordinators are entitled for the incentive on the basis of
              various activities taken care by them.
            </p>
          </div>
        </div>
      </div>
    ),
  },

  {
    pageNumber: 4,
    element: (
      <div className="overflow-hidden">
        <div className="flex flex-col justify-start items-center">
          <h1 className="text-3xl font-extrabold text-center text-brandGreen-500">
            The Verticles
          </h1>
          <br></br>
          <div>
            <p className="text-xs">
              <b className="text-brandOrange-400">
                {" "}
                1. Financial assistance to members -{" "}
              </b>{" "}
              Simplest ever – without any documentation, without any credit
              check and without any stress. Just a click away.
            </p>
            <p className="text-xs">
              <b className="text-brandOrange-400"> 2. Dhanvantari - </b>Health
              Umbrella Eco System
            </p>
            <p className="text-xs">
              <b className="text-brandOrange-400">
                3. BESE (Business, Entrepreneurship and Startup Encouragement)
                Club -{" "}
              </b>
              360-degree support from inception till shutter up
            </p>
            <p className="text-xs">
              <b className="text-brandOrange-400">
                4. Specially crafted Products Savings schemes -{" "}
              </b>
              lowering your cost of living but enhancing status of life - for
              your daily needs.
            </p>
            <p className="text-xs">
              <b className="text-brandOrange-400">
                5.Financial Inclusion by savings -{" "}
              </b>
              handsome interest rates on Daily, Recurring and Fixed Deposits.
            </p>
          </div>
        </div>
      </div>
    ),
  },

  {
    pageNumber: 5,
    element: (
      <div className="overflow-hidden">
        <div className="flex flex-col justify-start items-center">
          <h1 className="text-3xl font-extrabold text-center text-brandGreen-500">
            1 .Financial assistance to members
          </h1>
          <br></br>
          <p className="text-xs">
            The objective is to provide assistance to those members who cannot
            get financial help when they most require it – only because of
            detailed terms, formalities, credit checks and appraisals associated
            with the assistance provider or lender.
          </p>
          <br></br>

          <div className="px-4 lg:px-14">
            <p className="text-xs mb-1">
              <b> a. </b> Any member can apply for Loan to the extent of 70% of
              its deposit through his mobile application or by approaching his
              coordinator.
            </p>

            <p className="text-xs mb-1">
              <b> b. </b>For a Loan of above 70% of deposits, Coordinator can
              include guarantees of other members to the extent required. Loans
              can be secured or unsecured at the discretion of the coordinator
              and coordinator himself is empowered to approve the loan.
            </p>

            <p className="text-xs mb-1">
              <b> c. </b> Credit limit of a coordinator is also 70% of the
              deposits mobilized by him.
            </p>

            <p className="text-xs mb-1">
              <b> d. </b>The society has introduced two types of Loans. Fixed
              and Flexi. Depending on the need of the member,
            </p>

            <p className="text-xs mb-1">
              <b> e. </b>Customizable Flexibility in Term of the loan as well as
              amount of the loan. Member can opt any amount as minimum as Rs.
              1000/- only and any term as low as for a single day.
            </p>
            <p className="text-xs mb-1">
              <b> f. </b>Flexi Loan gives liberty to pay minimum interest based
              on reduced tenure and Fixed Loan provides a planned Loan at less
              interest rate for a bigger time period.
            </p>
            <p className="text-xs mb-1">
              <b> g. </b>In case of death of member, Loan will be written off
              and the security will be released to the legal heir.
            </p>
            <p className="text-xs mb-1">
              <b> h. </b>In case of default by the member, no recovery process
              will be initiated but member will be helped by helping him to
              creat alternate income source.
            </p>
          </div>

          <br></br>

          <div className="text-xs">
            <h1 className="text-center text-xl font-extrabold text-brandOrange-400">
              Loans
            </h1>

            <table className="tb">
              <thead>
                <tr>
                  <th className="tb">Scheme</th>
                  <th className="tb">Term (in months)</th>
                  <th className="tb">Interest %</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="tb">Flexi Loan</td>
                  <td className="tb">Any term</td>
                  <td className="tb">18.75%</td>
                </tr>
                <tr>
                  <td className="tb">Fixed Term Loan</td>
                  <td className="tb">1 Year</td>
                  <td className="tb">16.75%</td>
                </tr>
                <tr>
                  <td className="tb"></td>
                  <td className="tb">
                    Maximum 15 years or term of deposit whichever is earlier.
                  </td>
                  <td className="tb">Pay back of 1%, increasing every year</td>
                </tr>
              </tbody>
            </table>
          </div>

          <br></br>
          <div className="px-4 lg:px-14">
            <p className="text-xs mb-1">
              <b> 1. </b>Conversion from fixed to flexi and vice versa after 1
              year and can be availed only once in a year
            </p>
            <p className="text-xs mb-1">
              <b> 2. </b>Due date of interest will be the 15th of every month.
              Principal may be paid as per choice of borrower
            </p>
            <p className="text-xs mb-1">
              <b> 3. </b>Interest will be debited from Savings account and if
              savings account is not having balance, it will be reduced from
              member’s/coordinator’s deposit
            </p>
          </div>
        </div>
      </div>
    ),
  },

  {
    pageNumber: 6,
    element: (
      <div className="overflow-hidden">
        <div className="flex flex-col justify-start items-center">
          <h1 className="text-3xl font-extrabold text-center text-brandGreen-500">
            2. Dhanvantari
          </h1>
          <br></br>
          <p className="text-xs">
            Health Umbrella EcoSystem - Channeling for Medical Requirements of
            Members and their family – An advisory through empanelment of
            medical professionals is available for good health and lifestyle.
          </p>
          <br></br>

          <div className="px-4 lg:px-14">
            <p className="text-xs mb-1">
              <b> a. </b> Discounts upto 30% to members on empaneled medical
              facilities
            </p>

            <p className="text-xs mb-1">
              <b> b. </b>For members making deposits of less than Rs. 1,00,000/-
              reimbursement of Rs. 1,00,000 to meet out medical expenses in case
              of accident.
            </p>

            <p className="text-xs mb-1">
              <b> c. </b> For members making deposits/loan of more than Rs.
              1,00,000/- reimbursement of expenses (not insurance) equivalent to
              the value of deposit/loan for medical treatment in hospital.
            </p>

            <p className="text-xs mb-1">
              <b> d. </b>Discounted medicine for members from Apollo 24/7/1mg or
              any other platform.
            </p>

            <p className="text-xs mb-1">
              <b> e. </b>A dedicated portal providing access to empaneled
              medical professionals will be provided soon.
            </p>
          </div>
        </div>
      </div>
    ),
  },

  {
    pageNumber: 7,
    element: (
      <div className="overflow-hidden">
        <div className="flex flex-col justify-start items-center">
          <div>
            <h1 className="text-3xl font-extrabold text-center text-brandGreen-500">
              3. BESE (Business, Entrepreneurship and Startup Encouragement)
              Club
            </h1>
            <br></br>
            <p className="text-xs">
              360-degree support from inception till shutter up – being member,
              one is entitled for every support without spending for the same.
            </p>
            <br></br>
            <div className="px-4 lg:px-14">
              <p className="text-xs mb-1">
                <b> a. </b>Capital and Financial support to business
              </p>

              <p className="text-xs mb-1">
                <b> b. </b>Business advisory on procurement, supply-chain,
                logistics and marketing at no cost
              </p>
              <p className="text-xs mb-1">
                <b> c. </b>Advisory on compliances and legal assistance for
                business laws at no cost
              </p>
              <p className="text-xs mb-1">
                <b> d. </b>CA/CFO and related services at the choice of member.
              </p>

              <p className="text-xs mb-1">
                <b> e. </b>Complete profile analysis at no cost
              </p>
              <p className="text-xs mb-1">
                <b> f. </b>Tie ups for best franchisee models with virtually no
                risk to members.
              </p>
            </div>
            <br></br>
            <p className="text-xs">
              We promote every member to become an entrepreneur. Any member can
              join us in ENTREPRENEURSHIP Encouragement club by paying
              Registration Fee and get complete guidance to commence his own
              venture. It includes Virtually no member needs to infuse any
              capital to trigger & cater the business.
            </p>
            <br></br>
            <p className="text-xs">
              These facilities are available to all members merely by
              participating in the business of Society.
            </p>
          </div>
        </div>
      </div>
    ),
  },

  {
    pageNumber: 8,
    element: (
      <div className="overflow-hidden">
        <div className="flex flex-col justify-start items-center">
          <div>
            <h1 className="text-3xl font-extrabold text-center text-brandGreen-500">
              4. Specially crafted Products Savings schemes
            </h1>
            <br></br>
            <p className="text-xs">
              Lowering your cost of living but enhancing status of life - for
              your daily needs, we are negotiating products in bulk and creating
              a bridge to provide low-cost products to our members.
            </p>
            <br></br>

            <div className="px-4 lg:px-14">
              <p className="text-xs mb-1">
                <b> a. </b>Tie ups with product suppliers on the negotiated
                price, exclusively for our members
              </p>

              <p className="text-xs mb-1">
                <b> b. </b>Includes both FMCG and white goods.
              </p>
            </div>
          </div>
        </div>
      </div>
    ),
  },

  {
    pageNumber: 9,
    element: (
      <div className="overflow-hidden">
        <div className="flex flex-col justify-start items-center">
          <div>
            <h1 className="text-3xl font-extrabold text-center text-brandGreen-500">
              5. Financial Inclusion by savings
            </h1>

            <br></br>
            <p className="text-xs">
              The society encourages its members to cultivate habits for
              savings. Savings of every member should be spontaneous and not
              related to any specific purpose. On one hand we are providing
              practical financial solutions to the members hence making them
              comfortable for risk free savings. The amount collected as
              deposits shall be secured through various modes and at any point
              of time ratio of assets and liabilities shall be maintained at
              least 1:1.10.
            </p>
            <br></br>
            <p className="text-xs">
              The members can use saving account, recurring deposit account or
              Fixed deposit account as per their forthcoming requirements. The
              dedicated Coordinator is always available to help them in their
              financial and cash flow planning of entire portfolio. Let every
              member feel the strenghth his healthy financial portfolio
              resulting in increase in his confidence. For better understanding
              the rates earneable on various schemes are given below: -
            </p>
          </div>

          <br></br>
          <br></br>

          <div>
            <h1 className="text-xl font-extrabold text-center text-brandGreen-500">
              Schemes and Interest
            </h1>
            <br></br>
            <p className="flex justify-between">
              <b className="text-brandOrange-400">Saving Collection</b>
              <b> 4.5% Per annum</b>
            </p>
            <br></br>

            <b className="text-brandOrange-400">Recurring Deposit</b>
            <img
              src={images.rd_interest}
              width={"500px"}
              alt="Recurring Deposit Interest Details"
              className=""
            />
            <br></br>

            <b className="text-brandOrange-400">Fixed Deposit</b>
            <img
              src={images.fd_interest}
              width={"500px"}
              alt="Recurring Deposit Interest Details"
              className=""
            />
          </div>
          <br></br>
          <div className="px-4 lg:px-14">
            <p className="text-xs">
              <b>1. </b>Calculated on daily balance basis
            </p>
            <p className="text-xs">
              <b>2. </b>Late payment charges for RD collection is 12% per annum
              for delay in monthly deposit
            </p>
            <p className="text-xs">
              <b>3. </b>Premature payments are possible only for marriage, death
              of family member, child education and illness of family members
              for first 6 months
            </p>
            <p className="text-xs">
              <b>4. </b>Premature rate of interest will be reduced by 1% from
              applicable rate of interest.
            </p>
            <br></br>
            <p className="text-xs">
              (Applicable rate of interest means ROI applicable based on term
              utilised i.e. if an RD for 72 months is asked for premature
              payment after 40 months, ROI for 48 months -1% will be given)
            </p>
          </div>
        </div>
      </div>
    ),
  },

  {
    pageNumber: 10,
    element: (
      <div className="overflow-hidden">
        <div className="flex flex-col justify-start items-center">
          <div>
            <h1 className="text-3xl font-extrabold text-center text-brandGreen-500">
              Facilities to Members
            </h1>
            <br></br>
            <table className="tb lg:w-[900px] text-xs">
              <thead>
                <tr>
                  <th className="tb">S. No</th>
                  <th className="tb">Category of Member</th>
                  <th className="tb">Cost</th>
                  <th className="tb">Facilities</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="tb">1</td>
                  <td className="tb">
                    By paying Rs. 20 – Rs. 10 for one share (refundable) and Rs.
                    10 for membership fee (Non-refundable)
                  </td>
                  <td className="tb">Rs. 20/-</td>
                  <td className="tb">
                    1. Member can avail discounts on OPD facilities at
                    empanelled Medical Facilities, medical and laboratory tests
                    and medicines, near him,
                    <br></br>
                    2. Access to BSES Club by paying registration fee of Rs.
                    1000/-
                    <br></br>
                    3. Free Registration with Product Saving Scheme after two
                    months
                    <br></br>
                    4. Rs. 50,000 to be paid by society to the family in case of
                    Death of member.
                  </td>
                </tr>
                <tr>
                  <td className="tb">2</td>
                  <td className="tb">
                    Making deposit for less than Rs.1,00,000/- or taking loan
                    for less than Rs. 1,00,000/-
                  </td>
                  <td className="tb">Rs. 5000/- (refundable with interest)</td>
                  <td className="tb">
                    1. Member can avail discounts on OPD facilities at
                    empanelled Medical Facilities, medical and laboratory tests
                    and medicines, near him,
                    <br></br>
                    2. In case of accident Payment up to Rs. 1,00,000/- by
                    society for treatment.
                    <br></br>
                    3. Access to BSES Club by paying registration fee of Rs.
                    500/-
                    <br></br>
                    4. Free Registration with Product Saving Scheme after two
                    months
                    <br></br>
                    5. Rs. 50,000 to be paid by society to the family in case of
                    Death of member.
                  </td>
                </tr>
                <tr>
                  <td className="tb">3</td>
                  <td className="tb">
                    Making deposit for more than Rs.1,00,000/- or taking loan
                    for more than Rs. 1,00,000/-
                  </td>
                  <td className="tb">
                    Rs. 1,00,000/- (refundable with interest)
                  </td>
                  <td className="tb">
                    1. Member can avail discounts on OPD facilities at
                    empanelled Medical Facilities, medical and laboratory tests
                    and medicines, near him,
                    <br></br>
                    2. In case of accident Payment up to Rs. 1,00,000/- by
                    society for treatment.
                    <br></br>
                    3. Payment of equal amount of deposit or loan for
                    hospitalisation treatment of any illness.
                    <br></br>
                    4. Free Access to BSES Club Free Registration with Product
                    Saving Scheme after two months
                    <br></br>
                    5. Rs. 50,000 to be paid by society to the family in case of
                    Death of member.
                  </td>
                </tr>
                <tr>
                  <td className="tb">4</td>
                  <td className="tb">Coordinator</td>
                  <td className="tb">Rs. 10,000/- (refundable)</td>
                  <td className="tb">
                    1. Member can avail discounts on OPD facilities at
                    empanelled Medical Facilities, medical and laboratory tests
                    and medicines, near him,
                    <br></br>
                    2. In case of accident Payment up to Rs. 1,00,000/- by
                    society for treatment.
                    <br></br>
                    3. Payment of equal amount of deposit or loan for
                    hospitalisation treatment of any illness.
                    <br></br>
                    4. Access to BSES Club without paying registration fee.
                    <br></br>
                    5. Registration with Product Saving Scheme after two months
                    <br></br>
                    6. Rs. 50,000 to be paid by society to the family in case of
                    Death.
                    <br></br>
                    7. Incentive as per working
                    <br></br>
                    8. Fixed stipend of Rs. 15000/- for one month on achieving
                    the target.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    ),
  },
];
