import React, { useContext, useEffect, useState } from "react";
import { getCoordinatorRequest } from "../../api";
import { SignoutButton } from "../../components";
import { LoadingContext } from "../../context/Context";

const InactiveMember = ({ user }) => {
  const coordinatorId = user.parentIds[user.parentIds.length - 1];
  const [coordinator, setCoordinator] = useState(null);
  const { setIsLoading } = useContext(LoadingContext);

  useEffect(() => {
    const getCoordinator = async () => {
      setIsLoading(true);
      const c = await getCoordinatorRequest(coordinatorId);
      setIsLoading(false);
      console.log(c.data);
      setCoordinator(c.data);
    };
    getCoordinator();
    return () => {};
  }, [coordinatorId]);

  return (
    <>
      {coordinator ? (
        <div className="flex flex-col justify-center items-center p-4 lg:px-20 h-screen">
          <h1 className="text-brandGreen-500 font-bold text-2xl justify-self-start w-full">
            Dear {user.personalInfo.firstName},
          </h1>
          <p>
            Your account has not been activated yet. Please contact your
            coordinator to fill the complete membership form and activate your
            account.
          </p>
          <div className="flex flex-col justify-center items-center my-10">
            <p>
              <b>Coordinator Name: </b>
              {coordinator.personalInfo.firstName}{" "}
              {coordinator.personalInfo.middleName}{" "}
              {coordinator.personalInfo.lastName}
            </p>
            <p>
              <b>Coordinator Phone Number: </b>
              {coordinator.phoneNumber}
            </p>
          </div>
          <SignoutButton />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default InactiveMember;
