import React, { useContext, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import Webcam from "react-webcam";
import { CameraContext } from "../context/Context";
import { AiFillCamera, AiOutlineCheck } from "react-icons/ai";
import { VscClose } from "react-icons/vsc";
import { isMobile } from "react-device-detect";

const Camera = () => {
  const { cameraState, setCameraState } = useContext(CameraContext);
  const [imageUrl, setImageUrl] = useState(null);
const videoRef = useRef();

  const resetCameraState = () => {
    setCameraState({
      isOpen: false,
      width: 0,
      height: 0,
      setTargetImage: null,
    });
  };

  const videoConstraints = {
    facingMode: { exact: "environment" },
  };

  return (
    <div className="w-screen h-screen bg-black fixed z-30 bg-opacity-50 flex justify-center items-center">
      <AiOutlineClose
        className="absolute top-16 right-8 cursor-pointer text-white font-semibold text-5xl"
        onClick={resetCameraState}
      />
      <div className="bg-white rounded-lg lg:w-1/2 my-2">
        {!imageUrl ? (
          <div className="flex flex-col justify-evenly items-center p-4">
            {isMobile ? (
              <Webcam
                className="shadow-lg shadow-gray-300"
                ref={videoRef}
                width="300"
                height={"300"}
                videoConstraints={videoConstraints}
              />
            ) : (
              <Webcam
                className="shadow-lg shadow-gray-300"
                ref={videoRef}
                width="300"
                height={"300"}
              />
            )}
            <AiFillCamera
              className="text-6xl mt-4 text-red-500 cursor-pointer"
              onClick={() => {
                const imgSrc = videoRef.current.getScreenshot();
                setImageUrl(imgSrc);
              }}
            />
          </div>
        ) : (
          <div className="flex flex-col justify-evenly items-center p-4">
            <img
              src={imageUrl}
              alt="camera"
              className="shadow-lg shadow-gray-300"
            />
            <div className="flex justify-evenly w-full">
              <VscClose
                className="text-5xl p-2 mt-4 bg-red-100 rounded-full text-red-500 cursor-pointer shadow-md shadow-gray-400"
                onClick={() => {
                  setImageUrl(null);
                }}
              />
              <AiOutlineCheck
                className="text-5xl p-2 mt-4 bg-green-100 rounded-full text-green-600 cursor-pointer shadow-md shadow-gray-400"
                onClick={() => {
                  cameraState.setTargetImage(imageUrl);
                  resetCameraState();
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Camera;
