import { images } from "../constants";

const ShareReceipt = ({ member, detailId }) => {
  const details = member.shareDetails.find((de) => de._id === detailId);

  const breakDown = details.isMemberCreation
    ? `₹${10 * details.noOfShares + 10}`
    : `₹${10 * details.noOfShares}`;
  return (
    <>
      {member && (
        <div
          className={`w-[2480px] border-2 relative border-black m-2 py-80 h-[3508px] text-5xl p-40 flex flex-col justify-start items-center a4`}
        >
          <img
            src={images.kshitij_transparent}
            alt="logo"
            width="300"
            className="absolute top-10 right-10"
          />
          <img
            src={images.receipt_bg}
            alt="logo"
            className="absolute top-0 right-0 z-[-1]"
          />
          <img
            src={images.kccsl_transparent}
            alt="logo"
            width="500"
            className="absolute top-10 left-10"
          />
          <div className="p-20 w-full flex flex-col justify-center items-center">
            <div className="flex flex-col w-full justify-between items-center mb-10">
              <h1 className="text-8xl font-bold">Receipt</h1>
              <h1 className="text-6xl">(Non Transferable)</h1>
            </div>
            <div className="flex w-full justify-between">
              <h1 className="text-6xl">Receipt No: {details.rn}</h1>
              <h1 className="text-6xl">
                Date: {new Date(details.datePurchased).toLocaleDateString()}
              </h1>
            </div>

            {/* <div className="text-6xl mt-36 "> */}
            <p className="text-6xl mt-36">
              Received with thanks from{" "}
              <b className="underline">
                {member.personalInfo.firstName} {member.personalInfo.middleName}{" "}
                {member.personalInfo.lastName}
              </b>{" "}
              a sum of {breakDown} on account of following:
            </p>
            {/* </div> */}

            <div className="mt-16 w-5/6 text-6xl">
              {details.isMemberCreation ? (
                <>
                  <p>1. Membership fee - ₹10/-</p>
                  <p>
                    2. Share applicaiton fee for {details.noOfShares} share/s @
                    ₹10/- per share - ₹{10 * details.noOfShares}
                  </p>
                </>
              ) : (
                <p>
                  1. Share applicaiton fee for {details.noOfShares} share/s @
                  ₹10/- per share -{" "}
                  <b className="underline">₹{10 * details.noOfShares}</b>
                </p>
              )}
            </div>

            <h1 className="text-5xl w-full mt-16">Notes</h1>
            <div className="mt-16 w-5/6">
              <p className="flex p-2">1. Cheques are subject to realisation</p>
              <p className="flex p-2">
                2. Share allotment is subject to approval from the Board of
                Directors.
              </p>
              <p className="flex p-2">
                3. Successor of every member is entitled for exgratia payment of
                Rs. 50,000/- in case of demise of the member, after submission
                of claim form and affidavit.
              </p>
            </div>
            <h1 className="font-bold text-6xl w-full mt-16">Sincerely,</h1>
            <h1 className="font-bold text-6xl w-full mt-16">Team KCCSL</h1>
            <h1 className="mt-10">
              <i>
                (This is a computer generated receipt and requires no signature)
              </i>
            </h1>
          </div>
        </div>
      )}
    </>
  );
};

export default ShareReceipt;
