import React, { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import NoticeBoard from "../../components/NoticeBoard";
import SignoutButton from "../../components/SignoutButton";
import { UserContext } from "../../context/Context";
import InactiveMember from "./InactiveMember";
import MemberNav from "./MemberNav";

const Member = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }

    return () => {};
  }, []);

  if (user) {
    return user.roles.includes("member") ? (
      user.hasBeenActivated ? (
        <div>
          <NoticeBoard />
          <MemberNav />
        </div>
      ) : (
        <InactiveMember user={user} />
      )
    ) : (
      <>
        <h1>You do not have access, please sign in as member.</h1>
        <SignoutButton />
      </>
    );
  }

  return <>Something went wrong.</>;
};

export default Member;
