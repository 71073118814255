import axios from "axios";

const API = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });

// API.interceptors.request.use((req) => {
//   const auth = localStorage.getItem("auth");
//   if (auth) {
//     console.log("auth", auth);
//     req.headers.Authorization = `Bearer ${auth}`;
//   }
// });

//user requests
export const signInRequest = (formData) => API.post("/user/signin", formData);
export const resetPasswordRequest = (formData) =>
  API.post("/user/resetPassword", formData);
export const createUserRequest = (formData) =>
  API.post("user/create", formData);
export const updateMemberRequest = (formData) =>
  API.post("user/updateMember", formData);
export const makeCoordinatorRequest = (formData) =>
  API.post("user/makeCoordinator", formData);
export const checkUserIdRequest = (id) => API.get(`/user/checkid/${id}`);
export const getMemberRequest = (id) => API.get(`/user/member/${id}`);
export const getCoordinatorRequest = (id) => API.get(`/user/coordinator/${id}`);
export const checkOldMemberIdRequest = (memberId) =>
  API.get(`/user/checkOldMember/${memberId}`);
export const getUserByMidRequest = (mid) => API.get(`/user/getByMid/${mid}`);
export const updateOldMemberRequest = (formData) =>
  API.post(`/user/updateOldMember`, formData);

//account requests
export const getMemberAccountsRequest = (id) => API.get(`/account/${id}`);
export const getMemberAccountRequest = (id) =>
  API.get(`/account/account/${id}`);
export const getCoordinatorPendingLoansRequest = (id) =>
  API.get(`account/loan/${id}`);
export const createAccountRequest = (formData) =>
  API.post(`/account`, formData);
export const denyLoanRequest = (formData) =>
  API.post(`/account/loan/deny`, formData);
export const approveLoanRequest = (formData) =>
  API.post(`/account/loan/approve`, formData);
export const createLoanRequest = (formData) =>
  API.post(`/account/loan`, formData);
export const addGuarenterRequest = (formData) =>
  API.post(`/account/loan/addGuarenter`, formData);
export const getLoanGuarentersRequest = (loanId) =>
  API.get(`account/loan/${loanId}/guarenters`);
export const getUserGuaranteesRequest = (userId) =>
  API.get(`account/guarenter/${userId}`);

export const approveGuaranteeRequest = (formData) =>
  API.post(`account/Guarantee/approve`, formData);
export const denyGuaranteeRequest = (formData) =>
  API.post(`account/Guarantee/deny`, formData);

//transactions
export const reverseCoordinatorSecurityRequest = (formData) =>
  API.post(`transaction/reverseCoordinator`, formData);
export const getTransactionRequest = (id) => API.get(`/transaction/${id}`);

//deposit requests
export const getAllDeposits = () => API.get(`deposit`);
export const getCoordinatorDeposits = (coordinatorId) =>
  API.get(`deposit/${coordinatorId}`);
export const createDepositRequest = (formData) =>
  API.post("deposit/create", formData);
export const approveDepositRequest = (formData) =>
  API.post("deposit/approve", formData);
export const denyDepositRequest = (formData) =>
  API.post("deposit/deny", formData);

export const buySharesRequest = (formData) =>
  API.post("/account/shares", formData);
export const updateAccountRequest = (formData) =>
  API.patch("/account", formData);

//interesrt requests
export const getCurrentRateRequest = (id) => API.get(`/interest`);

//geolocation requests
export const getStatesRequest = () => API.get("/location/states");
export const getDistrictsRequest = (formData) =>
  API.get(`/location/districts/${formData}`);

//payment
export const makePaymentRequest = (formData) =>
  API.post("/transaction", formData);

//reports
export const getDemandsByDateRequest = (fromDate, toDate) =>
  API.get(`/demand/byDate/"${fromDate}"/"${toDate}"`);
export const getReportByDateRequest = (fromDate, toDate) =>
  API.get(`/demand/userReport/"${fromDate}"/"${toDate}"`);
export const makeDemandRequest = (formData) => API.post("/demand", formData);

//medical
export const generateEmpanelmentRequest = (formData) =>
  API.post(`/user/medical/generate`, formData);
export const updateConcentRequest = (formData) =>
  API.post(`/user/medical/updateConcent`, formData);
export const getMedicalEmpanelmentsRequest = (coordinatorId) =>
  API.get(`/user/medical/${coordinatorId}`);
export const getPendingEmpanelmentsRequest = () =>
  API.get(`/user/medical/pending`);
export const getAllEmpanelmentsRequest = () => API.get(`/user/medical/getAll`);
