import React from "react";

const EmpanelmentConsentDraft = () => {
  return (
    <div
      className={`w-[2480px] relative m-2 py-80  h-[3508px] text-5xl p-40 flex flex-col justify-start items-center a4 leading-[3.3rem] tracking-widest`}
    >
      <div className="p-20 mb-48 w-full flex flex-col justify-center items-start text-left">
        <div className="w-full">
          <h1 className="mt-10 text-center font-bold">
            <i>(Letterhead of your Medical Establishment with stamp)</i>
          </h1>
        </div>
        <br></br>
        <br></br>
        <p>
          <b>Date: </b> [Date]
          <br></br>
          <br></br>
          To,
          <br></br>
          <br></br>
          Kamuna Credit Co-operative Society Limited (KCCSL)
          <br></br>
          <br></br>
          <b>Subject: </b> Consent Form for Empanelment of Medical Establishment
          <br></br>
          <br></br>
          Dear Sir,,
        </p>
        <br></br>
        <p>
          With reference to our discussions with your Coordinator,
          [Coordinator's Name], I, [Your Name], owner/managing person of [Your
          Medical Establishment], hereby give my consent to KCCSL for
          empanelling my establishment situated at [Complete Address of Your
          Establishment] as a medical service provider to offer services to
          members of the Society in accordance with the norms of the Society.
          The details of my establishment are as follows:
        </p>
        <br></br>
        <p>
          Name of Establishment:<br></br>
          Name of Owner/Managing Director:<br></br>
          Phone Number:<br></br>
          Name of Contact Person:<br></br>
          Phone Number:<br></br>
          Email Address:<br></br>
          Website:<br></br>
          Aadhar Number of Owner/Managing Director:<br></br>
          Year of Establishment:<br></br>
          Category: [Specify if it's a Single Specialty Clinic/Multiple
          Specialty Clinic/Single Specialty Hospital/Multi-Specialty Hospital]
          <br></br>
          <br></br>
        </p>
        <p>
          Brief of Facilities Available: [Provide a brief description of the
          facilities available at your establishment]
          <br></br>
          <br></br>
        </p>
        <p>
          Please find the enclosed Consent Form for Empanelment. Kindly review,
          sign, and return the form to complete the empanelment process.
          <br></br>
          <br></br>
        </p>
        <p>
          Thank you for considering my establishment for empanelment. I look
          forward to serving the members of Kamuna Credit Co-operative Society
          Limited (KCCSL).
          <br></br>
          <br></br>
        </p>
        <p>
          Your sincerely,
          <br></br>
          <br></br>
          [Your Name]<br></br>
          [Your Designation]<br></br>
          [Your Phone Number]<br></br>
          [Your Email Address]<br></br>
        </p>
      </div>
    </div>
  );
};

export default EmpanelmentConsentDraft;
