import { Button } from "../.";
import { AiOutlineDown } from "react-icons/ai";
import TextInput from "../TextInput";

const BankDetailsForm = ({
  handleFormChange,
  isBankDetailsOpen,
  setIsBankDetailsOpen,
  setIsNomineeOpen,
  formData,
}) => {
  return (
    <div
      id="bank-form"
      className="relative bg-brandGreen-500 mb-2 pt-2 w-full border-b-2 border-white"
    >
      <h1 className="text-white mx-8 mb-2 font-bold">Bank Details</h1>
      <div
        className="text-white absolute top-0 right-0  w-full flex justify-end p-3 shadow-md  cursor-pointer"
        onClick={() => {
          setIsBankDetailsOpen(!isBankDetailsOpen);
        }}
      >
        <AiOutlineDown
          className={
            "transition-all duration-300 rotate-180" +
            (!isBankDetailsOpen ? "rotate-180" : "")
          }
        />
      </div>
      <div
        className={
          isBankDetailsOpen
            ? "transition-all duration-300 bg-gray-300 w-full flex flex-col justify-center items-center"
            : "transition-all duration-300 h-0"
        }
      >
        <div className={isBankDetailsOpen ? "visible" : "hidden"}>
          <div className="w-full flex flex-col">
            <div className=" w-full flex flex-col py-4 px-8 justify-center items-center lg:grid lg:grid-cols-3">
              <TextInput
                type="text"
                name="accountHoldersName"
                value={formData.accountHoldersName}
                id={"isName"}
                placeholder="Account Holder's Name"
                handleFormChange={handleFormChange}
                errorMessage={"Can't be empty"}
                validate={(text) => {
                  if (text.length === 0) return false;
                  return true;
                }}
              />
              <TextInput
                type="text"
                name="bankName"
                id={"isName"}
                value={formData.bankName}
                placeholder="Bank Name"
                handleFormChange={handleFormChange}
                errorMessage={"Can't be empty"}
                validate={(text) => {
                  if (text.length === 0) return false;
                  return true;
                }}
              />
              <TextInput
                type="number"
                onSelectType="number"
                name="accountNumber"
                value={formData.accountNumber}
                placeholder="Account Number"
                handleFormChange={handleFormChange}
                errorMessage={"Can't be empty"}
                validate={(text) => {
                  if (text.length === 0) return false;
                  return true;
                }}
              />

              <TextInput
                type="text"
                name="branchAddress"
                value={formData.branchAddress}
                placeholder="Branch Address"
                handleFormChange={handleFormChange}
                errorMessage={"Can't be empty"}
                validate={(text) => {
                  if (text.length === 0) return false;
                  return true;
                }}
              />
              <TextInput
                type="text"
                name="ifscCode"
                value={formData.ifscCode}
                placeholder="IFSC Code"
                handleFormChange={handleFormChange}
                errorMessage={"Can't be empty"}
                validate={(text) => {
                  if (text.length !== 11) return false;
                  return true;
                }}
              />
            </div>
            <div className="flex flex-col-reverse lg:flex-row w-full justify-center items-center mb-4">
              <Button
                text={"Continue"}
                type="primary"
                additional={"mx-4"}
                handleClick={() => {
                  setIsBankDetailsOpen(false);
                  setIsNomineeOpen(true);
                  const bankForm = document.getElementById("nominee-form");
                  bankForm.scrollIntoView({ behavior: "smooth" });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankDetailsForm;
