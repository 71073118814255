import { jsPDF } from "jspdf";
import Reciept from "./Reciept";

function TestPage() {
  const data = {
    number: 15137,
    name: "John Doe",
    date: "26/01/2023",
    rupees: 400,
    ruppesWords: "Twenty",
    chequeNumber: 123456,
    chequeDate: "12/12/2021",
    applicationShares: 4,
    sharesFee: 40,
  };

  function download() {
    const pdf = new jsPDF("p", "pt");
    if (document.getElementById("to-print")) {
      pdf.html(document.getElementById("to-print") || document.body, {
        callback: function (pdf) {
          const pageCount = pdf.internal.getNumberOfPages();
          console.log(pageCount);
          pdf.deletePage(2);
          pdf.deletePage(3);
          pdf.deletePage(4);
          pdf.deletePage(5);
          pdf.deletePage(6);
          pdf.save(`receipt${data.number}.pdf`);
        },
      });
    } else {
      console.log("receipt not found");
    }
  }

  const PDFFile = ({ children, ...props }) => {
    return <div id="to-print">{children}</div>;
  };

  return (
    <main>
      <div className="flex flex-col relative">
        <div className="absolute top-14">
          <PDFFile data={data}>
            <Reciept className="" />
          </PDFFile>
        </div>
        <button
          className="text-white absolute font-semibold px-4 py-2 rounded bg-blue-600 m-2"
          onClick={download}
        >
          Download PDF
        </button>
      </div>
    </main>
  );
}

export default TestPage;
