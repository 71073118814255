import { createContext } from "react";

export const UserContext = createContext(null);
export const RoleContext = createContext(null);
export const OpenNavbarContext = createContext(null);
export const CameraContext = createContext(null);
export const PaymentContext = createContext(null);
export const DemandContext = createContext(null);
export const LoadingContext = createContext(null);
export const PrintContext = createContext(null);
