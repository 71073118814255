import React, { useContext, useEffect } from "react";
import { LoginForm } from "../components";
import { RoleContext } from "../context/Context";

const AdminLogin = () => {
  const { role, setRole } = useContext(RoleContext);

  useEffect(() => {
    setRole("Admin");
  }, []);

  return (
    <div className="flex justify-center items-center h-screen lg:p-96 sm:p-48 p-12">
      <LoginForm role={role} />
    </div>
  );
};

export default AdminLogin;
