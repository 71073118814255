import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SignoutButton from "../../components/SignoutButton";
import { UserContext } from "../../context/Context";
import CoordinatorNav from "./CoordinatorNav";
import NoticeBoard from "../../components/NoticeBoard";

const Admin = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
    return () => {};
  }, []);

  if (user) {
    return user.roles.includes("member") ? (
      <div>
        <NoticeBoard />
        <CoordinatorNav />
      </div>
    ) : (
      <>
        <h1>You do not have access, please sign in as admin.</h1>
        <SignoutButton />
      </>
    );
  }

  return <>Something went wrong.</>;
};

export default Admin;
