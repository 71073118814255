import React, { useState, useContext } from "react";
import { RoleContext } from "../context/Context";
import { images, imageSources } from "../constants";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { LoginSelector, LoginForm } from "../components";

const Login = () => {
  return (
    <div className="flex flex-col-reverse lg:flex lg:flex-row w-full lg:h-screen overflow-hidden">
      <div
        id="login-about-us"
        className="w-full flex h-96 lg:h-screen lg:w-1/2 items-center justify-center mt-24 lg:mt-0"
      >
        <AboutSection />
      </div>
      <div className=" w-full flex h-screen lg:w-1/2 items-center justify-center overflow-hidden">
        <LoginSection />
      </div>
    </div>
  );
};

const LoginSection = () => {
  const [isRoleSelected, setIsRoleSelected] = useState(false);
  const { role, setRole } = useContext(RoleContext);

  const translationStatus = isRoleSelected ? "left" : "right";

  return (
    <div className="h-screen w-screen flex flex-col items-center justify-start">
      <div className="w-screen bg-green-100 flex justify-center items-center h-auto py-8 shadow-md">
        <img src={images.kshitij_transparent} width="150px" alt="logo" />
      </div>

      <div
        className={`h-screen flex flex-row justify-center items-start py-2 lg:w-screen scrollable-${translationStatus}`}
      >
        <LoginSelector
          setIsRoleSelected={setIsRoleSelected}
          setRole={setRole}
        />

        <div className="flex flex-col items-center w-screen lg:px-24 lg:w-half">
          <LoginForm role={role} />

          <div
            className="border-2 border-brandGreen-500 rounded-full absolute top-0 text-xs px-2 py-2 mt-2 shadow-lg text-brandGreen-500 hover:bg-brandGreen-500 hover:text-white transition duration-150 ease-in-out cursor-pointer"
            onClick={() => {
              setIsRoleSelected(false);
            }}
          >
            <AiOutlineArrowLeft />
          </div>
        </div>
      </div>
    </div>
  );
};

const AboutSection = () => {
  return (
    <div
      className="relative w-full h-full shadow-md shadow-gray-400 flex flex-col justify-center items-center"
      // style={{ backgroundImage: `url(${imageSources.receipt_bg})` }}
    >
      <img
        src={images.receipt_bg}
        alt="logo"
        className="absolute top-[-155px] left-0 z-[-1]"
      />
      <h1 className="text-brandGreen-500 font-bold text-5xl">About Us</h1>
      <div className="relative px-10 text-sm my-10">
        <p>
          <b>Our Time starts Now -</b> Since 2012 we are striving for upliftment
          of Members. After a turmoil due to economic slowdown, overall system
          collapse, covid and reverse circumstances for a long period, we have
          reawaken and converted the basic business model into an Eco-system -
          meant for overall growth of our members. The reasons for stall back
          are obvious but we have decided to reborn with a fully member-oriented
          society. Having registered to operate in nine states - Himachal
          Pradesh, Uttarakhand, Punjab, Uttar Pradesh, Bihar, Madhya Pradesh,
          Rajasthan, Gujrat and Maharashtra with a wide recognition by more than
          5,00,000 members.{" "}
        </p>
        <p>
          In this rebirth of the society, Concerns about environment have been
          kept on top. All members are discouraged for use of paper and plastic.
          Fully digitalized environment is made available.{" "}
        </p>
        <p>
          We are a registered Multistate Cooperative Society with registration
          NO. MSCS/CR/379/2010 duly registered with Central Registrar, Ministry
          of Cooperation, Government of India.
        </p>
      </div>
    </div>
  );
};

export default Login;
