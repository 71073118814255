import { jsPDF } from "jspdf";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { images } from "../constants";
import {
  getMemberAccountRequest,
  getMemberRequest,
  getShareReceiptNumberRequest,
} from "../api/index";
import { UserContext } from "../context/Context";
const AccountProvisionalReceipt = () => {
  const download = () => {
    const pdf = new jsPDF("p", "pt");
    if (document.getElementById("to-print")) {
      pdf.html(document.getElementById("to-print") || document.body, {
        callback: function (pdf) {
          const pageCount = pdf.internal.getNumberOfPages();
          console.log(pageCount);
          pdf.deletePage(2);
          pdf.deletePage(3);
          pdf.deletePage(4);
          pdf.deletePage(5);
          pdf.deletePage(6);
          pdf.save(`share_receipt.pdf`);
        },
      });
    } else {
      console.log("receipt not found");
    }
  };

  const PDFFile = ({ children, ...props }) => {
    return <div id="to-print">{children}</div>;
  };

  return (
    <main>
      <div className="flex flex-col relative">
        <div className="absolute top-14">
          <PDFFile>
            <ReceiptLayout />
          </PDFFile>
        </div>
        <button
          className="text-white absolute font-semibold px-4 py-2 rounded bg-blue-600 m-2"
          onClick={download}
        >
          Download PDF
        </button>
      </div>
    </main>
  );
};

const ReceiptLayout = () => {
  const params = useParams();
  const { user } = useContext(UserContext);
  const [account, setAccount] = useState();
  const [accountType, setAccountType] = useState();
  const getInfo = async () => {
    try {
      const accountNumber = params.id;
      const result = await getMemberAccountRequest(accountNumber);
      setAccount(result.data);
      console.log(result);

      switch (result.data.type) {
        case "RDS" || "RDM" || "RDL":
          setAccountType("Recurring Deposit");
          break;

        case "FDS" || "FDM" || "FDL":
          setAccountType("Fixed Deposit");
          break;

        case "S":
          setAccountType("Savings");
          break;

        default:
          break;
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInfo();
    return () => {};
  }, []);
  return (
    <>
      {account && (
        <div
          className={`w-[2480px] border-2 relative border-black m-2 py-80 h-[3508px] text-5xl p-40 flex flex-col justify-start items-center a4`}
        >
          <img
            src={images.kshitij_transparent}
            alt="logo"
            width="300"
            className="absolute top-10 right-10"
          />
          <img
            src={images.receipt_bg}
            alt="logo"
            className="absolute top-0 right-0 z-[-1]"
          />
          <img
            src={images.kccsl_transparent}
            alt="logo"
            width="500"
            className="absolute top-10 left-10"
          />
          <div className="p-20 w-full flex flex-col justify-center items-center">
            <div className="flex flex-col w-full justify-between items-center">
              <h1 className="text-7xl font-bold">Provisional Receipt</h1>
              <h1 className="text-6xl">(Non Transferable)</h1>
            </div>
            <p className="text-6xl mt-36 leading-[80px]">
              Received with thanks{" "}
              <b className="underline">₹{account.balance}</b> from Mr/Ms/Mrs. {}
              {user.personalInfo.firstName} {user.personalInfo.middleName}{" "}
              {user.personalInfo.lastName} having membership number{" "}
              {user.memberId} on account of {accountType} for {account.duration}{" "}
              months, under the terms of KCCSL.
            </p>

            <div className="mt-16 w-11/12">
              <p className="flex p-2">
                <b>1.</b>
                This is a provisional receipt subject to confirmation on
                software and receipt of money in Bank account nominated by the
                Society.
              </p>
              <p className="flex p-2">
                <b>2.</b>
                Final Receipt/Certificate will be sent on Mobile No/email
                address of the member with two working days of confirmation from
                the bank.
              </p>
            </div>
            <h1 className="font-bold text-6xl w-full mt-16">Sincerely,</h1>
            <h1 className="font-bold text-6xl w-full mt-16">Team KCCSL</h1>
            <h1 className="mt-10">
              <i>
                (This is a computer generated receipt and requires no signature)
              </i>
            </h1>
          </div>
        </div>
      )}
    </>
  );
};

export default AccountProvisionalReceipt;
