import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { Button } from "../../components";
import { getUserByMidRequest, updateOldMemberRequest } from "../../api";

const AdminEditMid = () => {
  const [mid, setMid] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const [oldMemberName, setOldMemberName] = useState(null);
  const [isNameUpdated, setIsNameUpdated] = useState(false);

  const fetchOldMember = async (e) => {
    e.preventDefault();
    try {
      const oldMember = await getUserByMidRequest(mid);
      setOldMemberName(oldMember.data.name);
    } catch (error) {
      console.log(error);
      setErrorMessage(error.response.data.message);
    }
  };

  const updateName = async (e) => {
    e.preventDefault();
    try {
      await updateOldMemberRequest({ mid: mid, name: oldMemberName });
      setIsNameUpdated(true);
    } catch (error) {
      console.log(error);
      setErrorMessage(error.response.data.message);
    }
  };

  return (
    <div className="shadow-lg bg-white flex flex-col justify-center items-center pt-8 lg:justify-evenly lg:p-8">
      <h1 className="text-4xl font-bold text-brandGreen-500 text-center">
        Edit Old Member
      </h1>

      <div className="rounded-xl m-8 relative overflow-hidden lg:w-1/3">
        <form onSubmit={fetchOldMember}>
          <input
            className="w-full border-2 border-gray-600 rounded-xl py-2 px-12 focus:outline-brandGreen-500"
            placeholder="Search Member"
            type="number"
            value={mid}
            onChange={(e) => {
              e.preventDefault();
              setMid(e.target.value);
              setErrorMessage(null);
            }}
          />

          {errorMessage ? (
            <p className="font-medium text-red-600 my-2">{errorMessage}</p>
          ) : (
            <></>
          )}
          <button type="submit">
            <div className="absolute top-0 right-0 bg-brandGreen-500 text-white hover:bg-brandGreen-600 p-3.5 cursor-pointer">
              <FaSearch />
            </div>
          </button>
        </form>
      </div>

      {oldMemberName && (
        <div className="my-10">
          <form
            className="flex flex-col justify-center"
            onSubmit={fetchOldMember}
          >
            <div className="flex mb-5 justify-center items-center">
              <p className="mx-2">Member Name: </p>
              <input
                className="w-[500px] border-2 border-gray-600 rounded-xl py-2 px-4 focus:outline-brandGreen-500"
                placeholder="Member Name"
                type="text"
                value={oldMemberName}
                onChange={(e) => {
                  e.preventDefault();
                  setOldMemberName(e.target.value);
                  setErrorMessage(null);
                }}
              />
            </div>
            {errorMessage ? (
              <p className="font-medium text-red-600 my-2">{errorMessage}</p>
            ) : (
              <></>
            )}
            <button type="submit">
              <Button text="Update" handleClick={updateName} />
            </button>
          </form>
          {isNameUpdated && (
            <h1 className="text-green-600 text-3xl text-center">Done!</h1>
          )}
        </div>
      )}
    </div>
  );
};

export default AdminEditMid;
