import React from "react";
import { images } from "../constants";

const CoordinatorCertificate = ({ coordinator }) => {
  return (
    <>
      {coordinator && (
        <div
          className={`w-[2480px] border-2 relative border-black m-2 py-80 h-[3508px] text-5xl p-40 flex flex-col justify-start items-center a4`}
        >
          <img
            src={images.kshitij_transparent}
            alt="logo"
            width="300"
            className="absolute top-10 right-10"
          />
          <img
            src={images.certificate_bg}
            alt="logo"
            className="absolute top-0 right-0 z-[-1]"
          />
          <img
            src={images.kccsl_transparent}
            alt="logo"
            width="500"
            className="absolute top-10 left-10"
          />
          <div className="p-20 my-48 w-full flex flex-col justify-center items-center text-justify">
            <h1 className="certificate-heading text-center font-bold text-blue-900">
              Certificate of Association
            </h1>
            <div className="my-32 text-7xl content p-4 w-10/12">
              <p className="w-full">
                We proudly announce that{" "}
                <b className="underline">
                  {coordinator.personalInfo.firstName}{" "}
                  {coordinator.personalInfo.middleName}{" "}
                  {coordinator.personalInfo.lastName}
                </b>{" "}
                has been associated as 'Coordinator' on 'Kshitij' application of
                'KCCSL' with Coordinator ID: {coordinator.coordinatorId}.
              </p>
              <p className="my-20">
                <b className="">
                  {coordinator.personalInfo.firstName}{" "}
                  {coordinator.personalInfo.middleName}{" "}
                  {coordinator.personalInfo.lastName}{" "}
                </b>
                is now a certified coordinator of the society and is authorised
                to do all the activities on the software as may be requried.
              </p>
            </div>
            <h1 className="mt-10">
              <i>
                (This is a computer generated receipt and requires no signature)
              </i>
            </h1>
          </div>
        </div>
      )}
    </>
  );
};

export default CoordinatorCertificate;
