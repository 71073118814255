import { useContext } from "react";
import { Menu, X } from "react-feather";
import { Link } from "react-router-dom";
import SignoutButton from "./SignoutButton";
import { OpenNavbarContext } from "../context/Context";

const Navbar = (props) => {
  const { openNavbar, setOpenNavbar } = useContext(OpenNavbarContext);
  const openClass =
    "md:relative fixed md:top-auto md:right-auto right-0 top-16 md:w-auto w-full transition-all z-50 right-[-100%]";
  const closeClass =
    "md:relative fixed md:top-auto md:right-auto right-0 top-16 md:w-auto w-full transition-all z-50 right-0";
  return (
    <nav className="bg-brandGreen-500 text-white h-16 flex flex-row items-center px-4 justify-between shadow-lg z-50">
      <div className="flex flex-row items-center justify-start md:w-auto w-full">
        <button
          className="px-4 py-2 md:hidden visible rounded"
          onClick={() => {
            setOpenNavbar(!openNavbar);
          }}
        >
          {openNavbar ? <Menu /> : <X />}
        </button>
        <Link
          to="/"
          className="px-4 py-2 md:text-3xl text-xl font-semibold rounded"
        >
          Home
        </Link>
      </div>
      {/* Menu */}
      <div className={openNavbar ? openClass : closeClass}>
        {props.children}
      </div>
      <div className="md:mx-4 ml-32 w-8/12 lg:w-fit flex justify-center">
        <SignoutButton />
      </div>
    </nav>
  );
};

export default Navbar;
