import { useContext, useEffect, useState } from "react";
import { BsCurrencyRupee, BsFillPersonFill } from "react-icons/bs";
import { FaHandHolding } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { getUserGuaranteesRequest } from "../../api";
import Guarantee from "../../components/Guarantee";
import IconButton from "../../components/IconButton";
import { LoadingContext, UserContext } from "../../context/Context";

const MemberNav = () => {
  const { user } = useContext(UserContext);
  const { setIsLoading } = useContext(LoadingContext);
  const [userGuarantees, setUserGuarantees] = useState();
  const getGuarantees = async () => {
    try {
      setIsLoading(true);
      const result = await getUserGuaranteesRequest(user._id);
      const gs = result.data.filter((g) => g.status === "PENDING");
      setUserGuarantees(gs);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getGuarantees();
    return () => {};
  }, []);

  return (
    <div className="">
      {userGuarantees && <Guarantee Guarantees={userGuarantees} />}
      <div className="py-2">
        <div className="py-5 grid grid-cols-3">
          <NavLink to="/member/profile">
            <IconButton icon={<BsFillPersonFill />} title={"My Profile"} />
          </NavLink>
        </div>

        <div className="h-px m-2 bg-gray-400 overflow-hidden" />

        <h1 className="text-3xl text-brandGreen-400 font-extrabold text-center ">
          Accounts
        </h1>
        <div className="my-10 grid grid-cols-3 gap-y-5">
          <NavLink to="/member/accounts">
            <IconButton icon={<BsCurrencyRupee />} title={"My Accounts"} />
          </NavLink>
          <NavLink to={`/member/loan/${user.phoneNumber}`}>
            <IconButton icon={<FaHandHolding />} title={"Loan"} />
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default MemberNav;
