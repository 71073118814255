import { useContext, useEffect, useState } from "react";
import { Button } from "../.";
import { AiOutlineDown } from "react-icons/ai";
import TextInput from "../TextInput";
import { getDistrictsRequest, getStatesRequest } from "../../api";
import { LoadingContext } from "../../context/Context";

const AddressForm = ({
  handleFormChange,
  isAddressOpen,
  setIsAddressOpen,
  formData,
  setFormData,
  setIsBankDetailsOpen,
}) => {
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [pState, setPState] = useState(true);
  const [cState, setCState] = useState(true);

  const [isSameAsPermanent, setIsSameAsPermanent] = useState(false);
  const { setIsLoading } = useContext(LoadingContext);
  useEffect(() => {
    const getStates = async () => {
      setIsLoading(true);
      const fetchedStates = await getStatesRequest();
      setIsLoading(false);
      setStates(fetchedStates.data);
    };
    getStates();

    return () => {};
  }, []);

  const selectPState = async (e) => {
    setFormData({ ...formData, pState: e.target.value, pDistrict: "default" });
    setIsLoading(true);
    const fetchedDistricts = await getDistrictsRequest(e.target.value);
    setIsLoading(false);
    setPState(false);
    setDistricts(fetchedDistricts.data);
  };

  const selectCState = async (e) => {
    setFormData({ ...formData, cState: e.target.value, cDistrict: "default" });
    setIsLoading(true);
    const fetchedDistricts = await getDistrictsRequest(e.target.value);
    setIsLoading(false);
    setCState(false);
    setDistricts(fetchedDistricts.data);
  };

  const makeSameAsPermanent = (e) => {
    if (e.target.checked) {
      setFormData({
        ...formData,
        cAddressLine1: formData.pAddressLine1,
        cAddressLine2: formData.pAddressLine2,
        cAddressLine3: formData.pAddressLine3,
        cPincode: formData.pPincode,
        cBlock: formData.pBlock,
        cTehsil: formData.pTehsil,
        cDistrict: formData.pDistrict,
        cState: formData.pState,
      });
    } else {
      setFormData({
        ...formData,
        cAddressLine1: "",
        cAddressLine2: "",
        cAddressLine3: "",
        cPincode: "",
        cBlock: "",
        cTehsil: "",
        cDistrict: "default",
        cState: "default",
      });
    }
    setIsSameAsPermanent(e.target.checked);
  };

  return (
    <div
      id="address-form"
      className="relative bg-green-700 mb-2 pt-2 w-full border-b-2 border-white"
    >
      <h1 className="text-white  mx-8 mb-2 font-bold">Address</h1>
      <div
        className="text-white absolute top-0 right-0  w-full flex justify-end p-3 shadow-md  cursor-pointer"
        onClick={() => {
          setIsAddressOpen(!isAddressOpen);
        }}
      >
        <AiOutlineDown
          className={
            "transition-all duration-300 rotate-180" +
            (!isAddressOpen ? "rotate-180" : "")
          }
        />
      </div>
      <div
        className={
          isAddressOpen
            ? "transition-all duration-300 bg-gray-300 w-full flex flex-col justify-center items-center"
            : "transition-all duration-300 h-0"
        }
      >
        <div className={isAddressOpen ? "visible" : "hidden"}>
          <div className="w-full flex flex-col">
            <div className=" w-full flex flex-col py-4 justify-center items-center lg:items-start">
              <h1 className="text-brandGreen-500 mx-2 text-2xl font-semibold">
                Permanent Address
              </h1>
              <div className="flex flex-col lg:flex-row justify-center items-center lg:justify-start lg:items-start">
                <TextInput
                  type="text"
                  name="pAddressLine1"
                  id={"isName"}
                  placeholder="Line 1"
                  title="Permanent Address Line 1"
                  value={formData.pAddressLine1}
                  handleFormChange={handleFormChange}
                  errorMessage={"Can't be empty"}
                  validate={(text) => {
                    if (text.length === 0) return false;
                    return true;
                  }}
                />
                <TextInput
                  type="text"
                  name="pAddressLine2"
                  id={"isName"}
                  placeholder="Line 2"
                  title="Permanent Address Line 2"
                  value={formData.pAddressLine2}
                  handleFormChange={handleFormChange}
                  errorMessage={"Can't be empty"}
                  validate={(text) => {
                    if (text.length === 0) return false;
                    return true;
                  }}
                />
                <TextInput
                  type="text"
                  name="pAddressLine3"
                  id={"isName"}
                  placeholder="Line 3"
                  title="Permanent Address Line 3"
                  value={formData.pAddressLine3}
                  handleFormChange={handleFormChange}
                  errorMessage={"Can't be empty"}
                  validate={(text) => {
                    if (text.length === 0) return false;
                    return true;
                  }}
                />
              </div>

              <div className="flex flex-col lg:flex-row justify-center items-center">
                <TextInput
                  type="numebr"
                  onSelectType="number"
                  name="pPincode"
                  id={"isName"}
                  placeholder="Pincode"
                  title="Permanent Address Pincode"
                  value={formData.pPincode}
                  handleFormChange={handleFormChange}
                  errorMessage={"Can't be empty"}
                  validate={(text) => {
                    if (text.length === 0) return false;
                    return true;
                  }}
                />
                <TextInput
                  type="text"
                  name="pBlock"
                  id={"isName"}
                  placeholder="Block"
                  title="Permanent Address Block"
                  value={formData.pBlock}
                  handleFormChange={handleFormChange}
                  errorMessage={"Can't be empty"}
                  validate={(text) => {
                    if (text.length === 0) return false;
                    return true;
                  }}
                />
                <TextInput
                  type="text"
                  name="pTehsil"
                  id={"isName"}
                  placeholder="Tehsil"
                  title="Permanent Address Tehsil"
                  value={formData.pTehsil}
                  handleFormChange={handleFormChange}
                  errorMessage={"Can't be empty"}
                  validate={(text) => {
                    if (text.length === 0) return false;
                    return true;
                  }}
                />
              </div>
              <div className="flex flex-col justify-center items-center lg:justify-start lg:items-start px-2 ">
                <div className="my-2">
                  <select
                    className="py-1.5 px-2 h-full w-11/12 isAddressOpen rounded-lg"
                    name="pState"
                    onChange={selectPState}
                    value={formData.pState}
                    title="Permanent Address State and District"
                  >
                    <option value="default" disabled>
                      State/UT
                    </option>
                    {states.map((state) => (
                      <option value={state}>{state}</option>
                    ))}
                  </select>
                </div>

                <div className="my-2">
                  <select
                    className="py-1.5 px-2 h-full w-11/12 isAddressOpen rounded-lg"
                    name="pDistrict"
                    onChange={handleFormChange}
                    disabled={pState}
                    value={formData.pDistrict}
                    title="Permanent Address State and District"
                  >
                    <option value="default" disabled>
                      District
                    </option>
                    {districts.map((district) => (
                      <option value={district}>{district}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="bg-gray-100 h-px w-full"></div>

            <div className=" w-full flex flex-col py-4 justify-center items-center lg:items-start">
              <h1 className="text-brandGreen-500 mx-2 text-2xl font-semibold">
                Current Address
              </h1>
              <div className="p-2">
                <label htmlFor="same-as-permanent">
                  Same as permanent address
                </label>
                <input
                  type="checkbox"
                  id="same-as-permanent"
                  className="mx-2"
                  onChange={makeSameAsPermanent}
                />
              </div>
              {isSameAsPermanent ? (
                <></>
              ) : (
                <>
                  <div className="flex flex-col lg:flex-row justify-center items-center lg:justify-start lg:items-start">
                    <TextInput
                      type="text"
                      id={"isName"}
                      name="cAddressLine1"
                      value={formData.cAddressLine1}
                      placeholder="Line 1"
                      handleFormChange={handleFormChange}
                      title="Current Address Line 1"
                      errorMessage={"Can't be empty"}
                      validate={(text) => {
                        if (text.length === 0) return false;
                        return true;
                      }}
                    />
                    <TextInput
                      type="text"
                      id={"isName"}
                      name="cAddressLine2"
                      value={formData.cAddressLine2}
                      placeholder="Line 2"
                      title="Current Address Line 2"
                      handleFormChange={handleFormChange}
                      errorMessage={"Can't be empty"}
                      validate={(text) => {
                        if (text.length === 0) return false;
                        return true;
                      }}
                    />
                    <TextInput
                      type="text"
                      id={"isName"}
                      name="cAddressLine3"
                      placeholder="Line 3"
                      value={formData.cAddressLine3}
                      title="Current Address Line 3"
                      handleFormChange={handleFormChange}
                      errorMessage={"Can't be empty"}
                      validate={(text) => {
                        if (text.length === 0) return false;
                        return true;
                      }}
                    />
                  </div>

                  <div className="flex flex-col lg:flex-row justify-center items-center">
                    <TextInput
                      type="numebr"
                      onSelectType="number"
                      name="cPincode"
                      id={"isName"}
                      placeholder="Pincode"
                      value={formData.cPincode}
                      title="Current Address Pincode"
                      handleFormChange={handleFormChange}
                      errorMessage={"Can't be empty"}
                      validate={(text) => {
                        if (text.length === 0) return false;
                        return true;
                      }}
                    />
                    <TextInput
                      type="text"
                      name="cBlock"
                      id={"isName"}
                      placeholder="Block"
                      title="Current Address Block"
                      handleFormChange={handleFormChange}
                      value={formData.cBlock}
                      errorMessage={"Can't be empty"}
                      validate={(text) => {
                        if (text.length === 0) return false;
                        return true;
                      }}
                    />
                    <TextInput
                      type="text"
                      name="cTehsil"
                      id={"isName"}
                      placeholder="Tehsil"
                      title="Current Address Tehsil"
                      value={formData.cTehsil}
                      handleFormChange={handleFormChange}
                      errorMessage={"Can't be empty"}
                      validate={(text) => {
                        if (text.length === 0) return false;
                        return true;
                      }}
                    />
                  </div>
                  <div className="flex flex-col justify-center items-center lg:justify-start lg:items-start px-2 ">
                    <div className="my-2">
                      <select
                        className="py-1.5 px-2 h-full w-11/12 isAddressOpen rounded-lg"
                        name="cState"
                        onChange={selectCState}
                        value={formData.cState}
                        title="Current Address State and District"
                      >
                        <option value="default" disabled>
                          State/UT
                        </option>
                        {states.map((state) => (
                          <option value={state}>{state}</option>
                        ))}
                      </select>
                    </div>

                    <div className="my-2">
                      <select
                        className="py-1.5 px-2 h-full w-11/12 isAddressOpen rounded-lg"
                        name="cDistrict"
                        onChange={handleFormChange}
                        disabled={cState}
                        value={formData.cDistrict}
                        title="Current Address State and District"
                      >
                        <option value="default" disabled>
                          District
                        </option>
                        {districts.map((district) => (
                          <option value={district}>{district}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="flex flex-col-reverse lg:flex-row w-full justify-center items-center mb-4">
              <Button
                text={"Continue"}
                type="primary"
                additional={"mx-4"}
                handleClick={() => {
                  setIsAddressOpen(false);
                  setIsBankDetailsOpen(true);
                  const bankForm = document.getElementById("bank-form");
                  bankForm.scrollIntoView({ behavior: "smooth" });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressForm;
