import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../components";
import { FaSearch } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { countryCodes } from "../../constants";
import { getMemberRequest } from "../../api";
import MemberDetails from "../../components/MemberDetails";
import {
  LoadingContext,
  RoleContext,
  UserContext,
} from "../../context/Context";

const AdminMember = () => {
  const params = useParams();
  const [phoneNumber, setPhoneNumber] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const [member, setMember] = useState(null);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const { role } = useContext(RoleContext);
  const { setIsLoading } = useContext(LoadingContext);

  useEffect(() => {
    if (params.id) {
      setPhoneNumber(params.id.slice(3));
      getDetails(params.id);
    }
    return () => {};
  }, [params.id]);

  const getDetails = async (id) => {
    try {
      console.log(id);
      setIsLoading(true);
      const existingMember = await getMemberRequest(id);
      setIsLoading(false);
      console.log(existingMember, user);
      if (!existingMember.data.parentIds.includes(user._id)) {
        setErrorMessage("You do not have access to this member. ");
        return;
      }
      setMember(existingMember.data);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setErrorMessage("Member not found. ");
    }
  };

  const fetchMemberDetails = async (e) => {
    e.preventDefault();
    console.log(e);
    navigate(`/admin/member/+91${phoneNumber}`);
  };

  return (
    <div className="">
      <div className="shadow-lg bg-white flex flex-col lg:flex-row justify-center items-center pt-8 lg:justify-evenly lg:p-8">
        <Button
          tyoe={"primary"}
          text={"Create Member"}
          additional={"lg:text-xl"}
          handleClick={() => {
            navigate("/admin/memberForm");
          }}
        />
        <Button
          tyoe={"primary"}
          text={"Edit Old Member"}
          additional={"lg:text-xl"}
          handleClick={() => {
            navigate("/admin/editMid");
          }}
        />
        <div className="rounded-xl m-8 relative overflow-hidden lg:w-1/3">
          <form onSubmit={fetchMemberDetails}>
            <input
              className="w-full border-2 border-gray-600 rounded-xl py-2 px-12 focus:outline-brandGreen-500"
              placeholder="Search Member"
              type="number"
              value={phoneNumber}
              onChange={(e) => {
                e.preventDefault();
                setPhoneNumber(e.target.value);
                setErrorMessage(null);
              }}
            />
            <div className="absolute top-1 left-1.5 p-1.5 rounded-l-lg  bg-gray-300">
              {countryCodes.INDIA}
            </div>

            {errorMessage ? (
              <p className="font-medium text-red-600 my-2">{errorMessage}</p>
            ) : (
              <></>
            )}
            <button type="submit">
              <div className="absolute top-0 right-0 bg-brandGreen-500 text-white hover:bg-brandGreen-600 p-3.5 cursor-pointer">
                <FaSearch />
              </div>
            </button>
          </form>
        </div>
      </div>
      <div>
        {member && (
          <div className="flex flex-col justify-center items-center">
            <MemberDetails member={member} />
            {(role === "Admin" || role === "Coordinator") && (
              <Button
                tyoe={"primary"}
                text={member.hasBeenActivated ? "Update" : "Activate"}
                additional={"lg:text-xl my-4"}
                handleClick={() =>
                  navigate(`/admin/memberForm/${member.phoneNumber}`)
                }
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminMember;
