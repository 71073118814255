import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from ".";

const SignoutButton = () => {
  const navigate = useNavigate();
  const handleSignOut = () => {
    localStorage.removeItem("user");
    navigate("/login");
  };
  return (
    <div>
      <Button
        text={"Log Out"}
        type="primary"
        handleClick={handleSignOut}
        additional={
          "text-xs py-2 px-2 bg-red-400 hover:bg-red-600 lg:text-base"
        }
      />
    </div>
  );
};

export default SignoutButton;
