import React, { useContext, useEffect, useState } from "react";
import { Button } from "./index";
import PersonalDetailsForm from "./CreateMemberComponents/PersonalDetails";
import AddressForm from "./CreateMemberComponents/Address";
import BankDetailsForm from "./CreateMemberComponents/BankDetails";
import NomineeDetails from "./CreateMemberComponents/NomineeDetails";
import {
  LoadingContext,
  PaymentContext,
  RoleContext,
  UserContext,
} from "../context/Context";
import { updateMemberRequest, createUserRequest } from "../api";
import TextInput from "./TextInput";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";

const MemberForm = ({ member = null }) => {
  const initialState = {
    selfPhoto: "",
    email: "",
    phoneNumber: "",
    firstName: "",
    middleName: "",
    lastName: "",
    fatherName: "",
    motherName: "",
    dateOfBirth: "",
    gender: "default",
    maritalStatus: "default",
    religion: "default",
    aadharFront: "",
    aadharBack: "",
    aadharNumber: "",
    pan: "",
    panNumber: "",
    occupation: "default",
    signature: "",
    pAddressLine1: "",
    pAddressLine2: "",
    pAddressLine3: "",
    pPincode: "",
    pBlock: "",
    pTehsil: "",
    pDistrict: "default",
    pState: "default",
    cAddressLine1: "",
    cAddressLine2: "",
    cAddressLine3: "",
    cPincode: "",
    cBlock: "",
    cTehsil: "",
    cDistrict: "default",
    cState: "default",
    accountHoldersName: "",
    bankName: "",
    accountNumber: "",
    branchAddress: "",
    ifscCode: "",
    nomineeName: "",
    nomineeAadharNumber: "",
    nomineeDateOfBirth: "",
    nomineePhoneNumber: "",
    nomineeAddressLine1: "",
    nomineeAddressLine2: "",
    nomineeAddressLine3: "",
    nomineeAddressBlock: "",
    nomineeAddressTehsil: "",
    nomineeAddressPincode: "",
    nomineeAddressState: "default",
    nomineeAddressDistrict: "default",
    nomineeRelationship: "",
  };

  const [isPersonalDataOpen, setIsPersonalDataOpen] = useState(true);
  const [isAddressOpen, setIsAddressOpen] = useState(false);
  const [isBankDetailsOpen, setIsBankDetailsOpen] = useState(false);
  const [isNomineeOpen, setIsNomineeOpen] = useState(false);
  const [noOfShares, setNoOfShares] = useState();
  const [isPaymentComplete, setIsPaymentComplete] = useState(false);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState();
  const [formErrorMessage, setFormErrorMessage] = useState();
  const [isPersonalDetailsFormValid, setIsPersonalDetailsFormValid] = useState({
    selfPhoto: false,
    phoneNumber: false,
    email: false,
    dob: false,
    firstName: false,
    lastName: false,
    fatherName: false,
    motherName: false,
    aadharNumber: false,
    panNumber: false,
  });
  const [heading, setHeading] = useState("Create");

  const { user } = useContext(UserContext);
  const { role } = useContext(RoleContext);
  const { setIsLoading } = useContext(LoadingContext);
  const { setPaymentState } = useContext(PaymentContext);
  const [formData, setFormData] = useState(initialState);
  const navigate = useNavigate();

  useEffect(() => {
    if (member !== null) {
      switch (member.hasBeenActivated) {
        case true:
          setHeading("Update");
          break;

        case false:
          setHeading("Activate");
          break;

        default:
          break;
      }
      const userPhoneNumber = member.phoneNumber.slice(3);
      const nomineePhoneNumber =
        member.nomineeDetails[0].nomineePhoneNumber.slice(3);
      setIsPaymentComplete(true);
      console.log(
        new Date(member.nomineeDetails[0].dateOfBirth)
          .toLocaleString()
          .split(",")[0]
      );
      setFormData({
        ...formData,
        selfPhoto: member.personalInfo.selfPhoto,
        phoneNumber: userPhoneNumber,
        email: member.personalInfo.emailAddress,
        firstName: member.personalInfo.firstName,
        middleName: member.personalInfo.middleName,
        lastName: member.personalInfo.lastName,
        fatherName: member.personalInfo.fatherName,
        motherName: member.personalInfo.motherName,
        dateOfBirth: new Date(member.personalInfo.dateOfBirth)
          .toLocaleString()
          .split(",")[0],
        maritalStatus: member.personalInfo.maritalStatus,
        religion: member.personalInfo.religion,
        gender: member.personalInfo.gender,
        aadharNumber: member.personalInfo.aadharNumber,
        aadharFront: member.personalInfo.aadharCardFront,
        aadharBack: member.personalInfo.aadharCardBack,
        panNumber: member.personalInfo.panNumber,
        pan: member.personalInfo.panCard,
        occupation: member.personalInfo.occupation,
        signature: member.personalInfo.signature,
        pAddressLine1: member.permanentAddress.addressLine1,
        pAddressLine2: member.permanentAddress.addressLine2,
        pAddressLine3: member.permanentAddress.addressLine3,
        pPincode: member.permanentAddress.pincode,
        pBlock: member.permanentAddress.block,
        pTehsil: member.permanentAddress.tehsil,
        pDistrict: member.permanentAddress.district,
        pState: member.permanentAddress.state,
        cAddressLine1: member.currentAddress.addressLine1,
        cAddressLine2: member.currentAddress.addressLine2,
        cAddressLine3: member.currentAddress.addressLine3,
        cPincode: member.permanentAddress.pincode,
        cBlock: member.currentAddress.block,
        cTehsil: member.currentAddress.tehsil,
        cDistrict: member.currentAddress.district,
        cState: member.currentAddress.state,
        accountHoldersName: member.bankAccountDetails.accountHoldersName,
        bankName: member.bankAccountDetails.bankName,
        accountNumber: member.bankAccountDetails.accountNumber,
        branchAddress: member.bankAccountDetails.branchAddress,
        ifscCode: member.bankAccountDetails.ifscCode,

        nomineeName: member.nomineeDetails[0].name,
        nomineeAadharNumber: member.nomineeDetails[0].aadharNumber,
        nomineeDateOfBirth: new Date(member.nomineeDetails[0].dateOfBirth)
          .toLocaleString()
          .split(",")[0],
        nomineePhoneNumber: nomineePhoneNumber,
        nomineeAddressLine1:
          member.nomineeDetails[0].nomineeAddress.addressLine1,
        nomineeAddressLine2:
          member.nomineeDetails[0].nomineeAddress.addressLine2,
        nomineeAddressLine3:
          member.nomineeDetails[0].nomineeAddress.addressLine3,
        nomineeAddressBlock: member.nomineeDetails[0].nomineeAddress.block,
        nomineeAddressTehsil: member.nomineeDetails[0].nomineeAddress.tehsil,
        nomineeAddressPincode: member.nomineeDetails[0].nomineeAddress.pincode,
        nomineeAddressState: member.nomineeDetails[0].nomineeAddress.state,
        nomineeAddressDistrict:
          member.nomineeDetails[0].nomineeAddress.district,
        nomineeRelationship: member.nomineeDetails[0].nomineeRelationship,
      });
    } else {
    }
    return () => {};
  }, [member]);

  const handleFormChange = (e) => {
    e.preventDefault();
    setFormErrorMessage("");
    if (e.target.id === "isName") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value.toUpperCase(),
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const getNewUserData = (isUpdate = false) => {
    const roles = isUpdate ? member.roles : ["member"];
    const newUser = {
      phoneNumber: `+91${formData.phoneNumber}`,
      roles: roles,
      creatorId: user._id,
      parentIds: [...user.parentIds, user._id],
      personalInfo: {
        selfPhoto: formData.selfPhoto,
        signature: formData.signature,
        firstName: formData.firstName,
        middleName: formData.middleName,
        lastName: formData.lastName,
        fatherName: formData.fatherName,
        motherName: formData.motherName,
        dateOfBirth: formData.dateOfBirth,
        gender: formData.gender,
        emailAddress: formData.email,
        maritalStatus: formData.maritalStatus,
        religion: formData.religion,
        aadharNumber: formData.aadharNumber,
        aadharCardFront: formData.aadharFront,
        aadharCardBack: formData.aadharBack,
        panNumber: formData.panNumber,
        panCard: formData.pan,
        occupation: formData.occupation,
      },
      permanentAddress: {
        addressLine1: formData.pAddressLine1,
        addressLine2: formData.pAddressLine2,
        addressLine3: formData.pAddressLine3,
        pincode: formData.pPincode,
        block: formData.pBlock,
        tehsil: formData.pTehsil,
        district: formData.pDistrict,
        state: formData.pState,
      },
      currentAddress: {
        addressLine1: formData.cAddressLine1,
        addressLine2: formData.cAddressLine2,
        addressLine3: formData.cAddressLine3,
        pincode: formData.cPincode,
        block: formData.cBlock,
        tehsil: formData.cTehsil,
        district: formData.cDistrict,
        state: formData.cState,
      },
      bankAccountDetails: {
        accountHoldersName: formData.accountHoldersName,
        bankName: formData.bankName,
        accountNumber: formData.accountNumber,
        branchAddress: formData.branchAddress,
        ifscCode: formData.ifscCode,
      },
      nomineeDetails: [
        {
          id: v4(),
          name: formData.nomineeName,
          aadharNumber: formData.nomineeAadharNumber,
          dateOfBirth: formData.nomineeDateOfBirth,
          nomineePhoneNumber: `+91${formData.nomineePhoneNumber}`,
          nomineeAddress: {
            addressLine1: formData.nomineeAddressLine1,
            addressLine2: formData.nomineeAddressLine2,
            addressLine3: formData.nomineeAddressLine3,
            block: formData.nomineeAddressBlock,
            tehsil: formData.nomineeAddressTehsil,
            pincode: formData.nomineeAddressPincode,
            state: formData.nomineeAddressState,
            district: formData.nomineeAddressDistrict,
          },
          nomineeRelationship: formData.nomineeRelationship,
        },
      ],
      hasBeenActivated: true,
    };
    console.log(newUser);
    return newUser;
  };

  const createMember = async () => {
    const newUser = getNewUserData();
    console.log(newUser);
    setIsLoading(true);
    const createdUser = await createUserRequest({
      ...newUser,
      noOfShares: parseInt(noOfShares),
    });
    console.log(createdUser);

    //remove from saved forms
    const savedFormsData = JSON.parse(localStorage.getItem("savedFormsData"));
    const oldSavedForms = savedFormsData.savedForms;
    const newSavedForms = oldSavedForms.filter(
      (form) => form.phoneNumber !== formData.phoneNumber
    );
    const newSavedFormsData = {
      savedForms: [newSavedForms],
    };
    localStorage.setItem(
      "savedFormsData",
      JSON.stringify("savedFormsData", newSavedFormsData)
    );
    console.log(313);
    setIsLoading(false);
    navigate(
      `/${role.toLowerCase()}/memberForm/${createdUser.data.result.phoneNumber}`
    );
  };

  const checkCanMakePayment = () => {
    // const pKeys = Object.keys(isPersonalDetailsFormValid);
    // for (const pKey of pKeys) {
    //   if (!pKey) {
    //     const value = isPersonalDetailsFormValid[pKey];
    //     console.log(value);
    //     setPaymentErrorMessage("Please fill/correct form.");
    //     return false;
    //   }
    // }

    if (!noOfShares) {
      setPaymentErrorMessage("Please enter number of shares");
      return false;
    } else {
      setPaymentErrorMessage(null);
    }
    return true;
  };

  const makePayment = async () => {
    if (user.roles.includes("admin") || user.roles.includes("coordinator")) {
      console.log(formData);
      if (!checkCanMakePayment() || !checkFormComplete()) {
        return;
      }
      const totalAmount = 10 * noOfShares + 10;
      const breakDown = [
        { itemName: "Membership Fee", value: 10, multiple: 1 },
        { itemName: "Shares", value: 10, multiple: noOfShares },
      ];
      setPaymentState({
        isOpen: true,
        totalAmount: totalAmount,
        breakDown: breakDown,
        handleSuccess: () => createMember(),
        handleFailure: () => setPaymentErrorMessage("Payment Failed"),
        accountId: user.collectionAccount,
        remark: `Member +91${formData.phoneNumber} created by ${user.phoneNumber}`,
        source: "Member",
        kind: "credit",
      });
    }
  };

  const checkFormComplete = () => {
    const defaults = [
      "gender",
      "maritalStatus",
      "occupation",
      "pState",
      "pDistrict",
      "cState",
      "cDistrict",
      "nomineeAddressState",
      "nomineeAddressDistrict",
    ];
    const keys = Object.keys(formData);
    for (const key of keys) {
      const value = formData[key];
      if (key === "middleName") {
        continue;
      }
      if (defaults.includes(key)) {
        if (value === "default") {
          const formField = document.getElementsByName(key);
          console.log(key);
          console.log(formField);
          const fieldName = formField[0].title;
          setFormErrorMessage(`Please fill ${fieldName}.`);
          return false;
        }
      } else {
        if (value === "") {
          const formField = document.getElementsByName(key);
          const fieldName = formField[0].title || formField[0].placeholder;
          setFormErrorMessage(`Please fill ${fieldName}.`);
          return false;
        }
      }
    }
    setFormErrorMessage(null);
    return true;
  };

  const updateMember = async () => {
    try {
      if (user.roles.includes("admin") || user.roles.includes("coordinator")) {
        if (!checkFormComplete()) {
          return;
        }
        console.log("requesting");
        console.log(member.phoneNumber);
        const data = getNewUserData(true);
        console.log(data);
        setIsLoading(true);
        const updatedUser = await updateMemberRequest({
          phoneNumber: member.phoneNumber,
          data,
        });
        console.log("User updated", updatedUser);
        setIsLoading(false);
        navigate(`/${role.toLowerCase()}/member/${member.phoneNumber}`);
      }
    } catch (error) {
      setIsLoading(false);
      setFormErrorMessage("Something went wrong... Please try again.");
    }
  };

  const saveFormToLocal = () => {
    try {
      setIsLoading(true);
      setFormErrorMessage("");
      if (formData.phoneNumber === "") {
        throw new Error("Must add phone number to save form");
      }
      const savedFormsData = JSON.parse(localStorage.getItem("savedFormsData"));
      if (savedFormsData !== null) {
        const oldSavedForms = savedFormsData.savedForms;
        const existingRecord = oldSavedForms.find(
          (form) => form.phoneNumber === formData.phoneNumber
        );
        let newSavedForms;
        if (existingRecord) {
          newSavedForms = oldSavedForms.map((form) => {
            if (form.phoneNumber === formData.phoneNumber) {
              return {
                phoneNumber: formData.phoneNumber,
                formData,
              };
            }
            return form;
          });
        } else {
          newSavedForms = [
            ...oldSavedForms,
            { phoneNumber: formData.phoneNumber, formData },
          ];
        }
        const newSavedFormsData = {
          savedForms: newSavedForms,
        };
        localStorage.setItem(
          "savedFormsData",
          JSON.stringify(newSavedFormsData)
        );
      } else {
        const newSavedFormsData = {
          savedForms: [{ phoneNumber: formData.phoneNumber, formData }],
        };
        localStorage.setItem(
          "savedFormsData",
          JSON.stringify(newSavedFormsData)
        );
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setFormErrorMessage(error.message);
    }
  };

  const SelectSavedForm = () => {
    const [selectedFormId, setSetselectedFormId] = useState("default");

    const savedFormsData = JSON.parse(localStorage.getItem("savedFormsData"));
    const savedForms = savedFormsData ? savedFormsData.savedForms : [];

    const selectForm = (e) => {
      setSetselectedFormId(e.target.value);

      //setFormData
      const savedForm = savedForms.find(
        (form) => form.phoneNumber === e.target.value
      ).formData;
      // console.log(savedForm);
      setFormData(savedForm);
    };
    return (
      <div className="px-2 w-9/12 mt-4 my-2">
        <select
          className="py-1.5 px-4 h-full w-full  rounded-lg"
          name="savedForms"
          onChange={selectForm}
          value={selectedFormId}
          title="Saved Forms"
        >
          <option value="default" disabled>
            Saved Forms
          </option>
          {savedForms &&
            savedForms.map((form) => {
              return (
                <option value={form.phoneNumber} key={form.phoneNumber}>
                  +91{form.phoneNumber}
                </option>
              );
            })}
        </select>
      </div>
    );
  };

  return (
    <form className="w-full shadow-sm shadow-gray-400" autoComplete="off">
      {/* menu-1 */}
      <h1 className="mb-3 text-4xl text-center font-semibold text-brandGreen-500">
        {heading} Member
      </h1>
      <div className="flex flex-col justify-center items-center p-2">
        {heading === "Create" && (
          <>
            <SelectSavedForm />
            <Button text={"Save Form"} handleClick={saveFormToLocal} />
          </>
        )}
        <p className="mx-2 text-center text-sm text-red-500">
          {formErrorMessage}
        </p>
      </div>
      <PersonalDetailsForm
        handleFormChange={handleFormChange}
        isPersonalDataOpen={isPersonalDataOpen}
        setIsPersonalDataOpen={setIsPersonalDataOpen}
        setIsAddressOpen={setIsAddressOpen}
        formData={formData}
        setFormData={setFormData}
        isMemberUpdate={isPaymentComplete}
        isPersonalDetailsFormValid={isPersonalDetailsFormValid}
        setIsPersonalDetailsFormValid={setIsPersonalDetailsFormValid}
      />
      <AddressForm
        handleFormChange={handleFormChange}
        isAddressOpen={isAddressOpen}
        setIsAddressOpen={setIsAddressOpen}
        formData={formData}
        setFormData={setFormData}
        setIsBankDetailsOpen={setIsBankDetailsOpen}
      />
      <BankDetailsForm
        handleFormChange={handleFormChange}
        isBankDetailsOpen={isBankDetailsOpen}
        setIsBankDetailsOpen={setIsBankDetailsOpen}
        setIsNomineeOpen={setIsNomineeOpen}
        formData={formData}
      />
      <NomineeDetails
        isNomineeOpen={isNomineeOpen}
        handleFormChange={handleFormChange}
        setIsNomineeOpen={setIsNomineeOpen}
        setFormData={setFormData}
        formData={formData}
      />
      {isPaymentComplete ? (
        <p className="mx-2 text-sm bg-green-200 w-fit p-5 border-l-8 border-green-400">
          Membership Payment Complete
        </p>
      ) : (
        <div className="flex flex-col w-full mt-4 justify-center mb-4">
          <TextInput
            type="number"
            onSelectType="number"
            placeholder="Number of Shares"
            handleFormChange={(e) => setNoOfShares(e.target.value)}
            validate={(text) => {
              if (parseInt(text) >= 1) return true;
              return false;
            }}
            errorMessage="Must be at least 1"
          />
          <p className="mx-2 text-sm">No. of Shares (Rs. 10 per share)</p>
          <Button
            text={"Make Payment"}
            type="primary"
            additional={"m-4 w-fit"}
            handleClick={makePayment}
          />
          <p className="mx-2 text-sm text-red-500">{paymentErrorMessage}</p>
        </div>
      )}
      <p className="mx-2 text-center text-sm text-red-500">
        {formErrorMessage}
      </p>
      <div className="flex flex-col-reverse lg:flex-row w-full mt-4 justify-center items-center">
        {!isPaymentComplete && (
          <Button
            text={"Reset"}
            type="secondary"
            additional={"mx-4 my-4"}
            handleClick={() => setFormData(initialState)}
          />
        )}
        <Button
          text={`${heading} Member`}
          type="primary"
          additional={"mx-4 my-4"}
          handleClick={updateMember}
          disabled={!isPaymentComplete}
        />
      </div>

      {!isPaymentComplete ? (
        <p className="text-center text-gray-500 mb-4">
          Complete payment to activate user
        </p>
      ) : (
        <></>
      )}
    </form>
  );
};

export default MemberForm;
