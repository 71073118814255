import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getCoordinatorPendingLoansRequest,
  getMemberAccountsRequest,
} from "../../api";
import LoanApply from "../../components/LoanApply";
import LoanList from "../../components/LoanList";
import { accountCodes } from "../../constants";
import { LoadingContext } from "../../context/Context";

const MemberLoan = () => {
  const params = useParams();
  const [loanAccounts, setLoanAccounts] = useState([]);
  const { setIsLoading } = useContext(LoadingContext);

  const getMemberLoans = async (id) => {
    try {
      setIsLoading(true);
      const result = await getMemberAccountsRequest(id);
      const la = result.data.accounts.filter((account) =>
        accountCodes.loan.includes(account.type)
      );
      setLoanAccounts(la);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (params.id) {
      getMemberLoans(params.id);
    }

    return () => {};
  }, [params.id]);
  return (
    <div>
      <LoanList loanAccounts={loanAccounts} />
      <LoanApply />
    </div>
  );
};

export default MemberLoan;
