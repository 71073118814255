import Button from "./Button";

const LoginSelector = ({ setIsRoleSelected, setRole }) => {
  return (
    <div className="flex flex-col items-center justify-center w-screen lg:w-half">
      <div className="flex flex-col w-full h-64 justify-evenly items-center">
        <Button
          additional={"w-2/3"}
          text={"Member Login"}
          type="primary"
          handleClick={() => {
            setIsRoleSelected(true);
            setRole("Member");
          }}
        />
        <Button
          additional={"w-2/3"}
          text={"Coordinator Login"}
          type="secondary"
          handleClick={() => {
            setIsRoleSelected(true);
            setRole("Coordinator");
          }}
        />
        <Button
          additional={"w-2/3"}
          text={"Continue as Guest"}
          type="secondary"
          handleClick={() => {
            setIsRoleSelected(true);
            setRole("Guest");
          }}
        />
      </div>
    </div>
  );
};

export default LoginSelector;
