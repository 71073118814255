import { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { OpenNavbarContext } from "../../context/Context";

const AdminNav = () => {
  const { setOpenNavbar } = useContext(OpenNavbarContext);
  const [memberDrop, setMemberDrop] = useState(false);

  const closeNav = () => {
    setOpenNavbar(true);
  };

  return (
    <Navbar>
      <ul className="w-full flex md:flex-row flex-col bg-brandGreen-600 md:px-0 px-4">
        <NavLink
          className={({ isActive }) => (isActive ? "bg-brandGreen-400" : "")}
          to="/admin/coordinator"
        >
          <li>
            <button
              onClick={closeNav}
              className="px-4 py-2 md:text-xl text-base font-semibold hover:bg-brandGreen-400 rounded transition-all w-full text-left"
            >
              Coordinator
            </button>
          </li>
        </NavLink>

        <NavLink
          className={({ isActive }) => (isActive ? "bg-brandGreen-400" : "")}
          to="/admin/member"
        >
          <li>
            <button
              onClick={closeNav}
              className="px-4 py-2 md:text-xl text-base font-semibold hover:bg-brandGreen-400 rounded transition-all w-full text-left"
            >
              Member
            </button>
          </li>
        </NavLink>

        <NavLink
          className={({ isActive }) => (isActive ? "bg-brandGreen-400" : "")}
          to="/admin/admin"
        >
          <li>
            <button
              onClick={closeNav}
              className="px-4 py-2 md:text-xl text-base font-semibold hover:bg-brandGreen-400 rounded transition-all w-full text-left"
            >
              Admin
            </button>
          </li>
        </NavLink>

        <NavLink
          className={({ isActive }) => (isActive ? "bg-brandGreen-400" : "")}
          to="/admin/data"
        >
          <li>
            <button
              onClick={closeNav}
              className="px-4 py-2 md:text-xl text-base font-semibold hover:bg-brandGreen-400 rounded transition-all w-full text-left"
            >
              Data
            </button>
          </li>
        </NavLink>
      </ul>
    </Navbar>
  );
};

export default AdminNav;

{
  /* <NavLink className={({ isActive }) => (isActive ? "bg-brandGreen-400" : "")}>
  <li className="relative">
    <button
      className="px-4 py-2 md:text-xl text-base font-semibold bg-brandGreen-600 hover:bg-brandGreen-400  transition-all w-full text-left"
      onClick={() => setMemberDrop(!memberDrop)}
    >
      Dropdown
    </button>
    <div
      className={
        " md:absolute md:top-16 md:left-0 md:w-auto w-full md:px-0 transition-all " +
        (memberDrop ? "visible" : "hidden")
      }
    >
      <div className="flex ml-14 md:ml-2 w-full flex-col bg-neutral-100 text-black rounded shadow-lg">
        <button
          className="lg:px-4 px-4 py-2 md:text-xl text-base font-semibold hover:bg-neutral-300 rounded transition-all w-full text-left"
          onClick={closeNav}
        >
          Create Member
        </button>
        <div className="h-px bg-gray-300"></div>
        <button
          className="lg:px-4 px-4 py-2 md:text-xl text-base font-semibold hover:bg-neutral-300 rounded transition-all w-full text-left"
          onClick={closeNav}
        >
          Item 2
        </button>
      </div>
    </div>
  </li>
</NavLink>; */
}
