import React, { useContext, useEffect, useState } from "react";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import {
  CameraContext,
  LoadingContext,
  PrintContext,
  UserContext,
} from "../../context/Context";
import { useNavigate } from "react-router-dom";
import MedicalEmpanelmentLetter from "../../components/MedicalEmpanelmentLetter";
import EmpanelmentConsentDraft from "../../components/EmpanelmentConsentDraft";
import { countryCodes } from "../../constants";
import {
  generateEmpanelmentRequest,
  getMedicalEmpanelmentsRequest,
  updateConcentRequest,
} from "../../api";

const MedicalEmpanelment = () => {
  return (
    <div>
      <EmpanelmentForm />
      <LetterTemplateContianer />
      <ConcentUpload />
    </div>
  );
};

const ConcentUpload = () => {
  const [empanelments, setEmpanelments] = useState();
  const { user } = useContext(UserContext);
  const getMedicalEmpanelments = async () => {
    try {
      const data = await getMedicalEmpanelmentsRequest(user._id);
      setEmpanelments(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMedicalEmpanelments();

    return () => {};
  }, []);

  return (
    <div className="flex justify-center items-center">
      <div className="flex flex-col justify-center items-center p-4 w-11/12">
        <h1 className="text-brandGreen-500 font-bold text-4xl">
          Existing Empanelments
        </h1>
        <div className=" w-full lg:px-2">
          <div className="w-full">
            <div className="invisible lg:visible grid grid-cols-4 font-bold">
              <p>Name</p>
              <p>phoneNumber</p>
              <p>Status</p>
              <p>Consent</p>
            </div>
          </div>
        </div>
        <div className="h-px bg-gray-600 w-full" />

        {empanelments &&
          empanelments.map((emp) => {
            return <Emp emp={emp} key={emp._id} />;
          })}
      </div>
    </div>
  );
};

const Emp = ({ emp }) => {
  const [proof, setProof] = useState(emp.concentLetter || "");
  const { cameraState, setCameraState } = useContext(CameraContext);
  const { setIsLoading } = useContext(LoadingContext);

  const uploadConcent = async () => {
    console.log(true);
    try {
      setIsLoading(true);
      await updateConcentRequest({ id: emp._id, proof });
      setIsLoading(false);
      window.location.relaod();
    } catch (error) {
      console.log(error);
      setIsLoading(true);
    }
  };

  return (
    <>
      <div className="flex flex-col lg:grid lg:grid-cols-4 w-full text-center p-4">
        <div className="flex flex-row items-center">
          <p className="lg:hidden">
            <b>Name: </b>
          </p>
          <p> {emp.name}</p>
        </div>

        <div className="flex flex-row items-center">
          <p className="lg:hidden">
            <b>Phone Number: </b>
          </p>
          <p> {emp.phoneNumber}</p>
        </div>

        <div className="flex flex-row items-center">
          <p className="lg:hidden">
            <b>Status: </b>
          </p>
          <p>{emp.status}</p>
        </div>
        <div className="flex flex-col justify-center items-center">
          <div
            className="p-4 cursor-pointer photo flex justify-center items-center text-xl font-semibold text-gray-600"
            onClick={() => {
              setCameraState({
                ...cameraState,
                isOpen: true,
                setTargetImage: setProof,
              });
            }}
          >
            {proof !== "" ? (
              <img
                src={proof}
                alt={"proof"}
                className="aspect-square object-cover"
              />
            ) : (
              <input
                className="bg-transparent text-center text-gray-600 cursor-pointer"
                name="proofPhoto"
                disabled={true}
                placeholder="Add Concent Letter"
              />
            )}
          </div>
          {!emp.concentLetter && (
            <Button
              text="Upload"
              additional={"mt-2"}
              handleClick={uploadConcent}
            />
          )}
        </div>
      </div>

      <div className="h-px bg-gray-400 w-full" />
    </>
  );
};

const EmpanelmentForm = () => {
  const [establishmentName, setEstablishmentName] = useState(null);
  const [establishmenAddress, setEstablishmenAddress] = useState(null);
  const [recipiantName, setRecipiantName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [recipiantDesignation, setRecipiantDesignation] = useState(null);

  const current = new Date();
  const date = `${current.getDate()} / ${
    current.getMonth() + 1
  } / ${current.getFullYear()}`;
  const { user } = useContext(UserContext);
  const { setPrintComponent, setPrintName } = useContext(PrintContext);

  const navigate = useNavigate();

  const generateEmpanelment = async () => {
    const requestData = {
      _id: phoneNumber,
      coordinatorId: user.phoneNumber,
      name: establishmentName,
      address: establishmenAddress,
      phoneNumber: phoneNumber,
      recipiantName: recipiantName,
      recipiantDesignation: recipiantDesignation,
      status: "GENERATED",
    };
    const data = await generateEmpanelmentRequest(requestData);
    console.log(data);
  };

  const getInvite = () => {
    setPrintComponent(
      <MedicalEmpanelmentLetter
        letterData={{
          establishmentName,
          establishmenAddress,
          recipiantName,
          recipiantDesignation,
          date,
          phoneNumber,
          coordinatorName:
            user.personalInfo.firstName +
            " " +
            user.personalInfo.middleName +
            " " +
            user.personalInfo.lastName,
        }}
      />
    );
    setPrintName("Coordinator_Certificate");
    navigate("/print");
  };

  return (
    <div className="bg-gray-300 flex flex-col justify-center items-center p-4">
      <h1 className="text-brandGreen-500 font-bold text-4xl">
        Medical Empanelment
      </h1>

      <div className="m-4">
        <TextInput
          type="text"
          onSelectType="text"
          name="establishmetnName"
          validate={(text) => {
            if (!text || text === "") {
              return false;
            }
            return true;
          }}
          value={establishmentName}
          handleFormChange={(e) => setEstablishmentName(e.target.value)}
          placeholder="Establishment Name"
          isPhoneNumber={false}
          errorMessage={"Can't be empty"}
        />
        <TextInput
          type="text"
          onSelectType="text"
          name="establishmentAddress"
          validate={(text) => {
            if (!text || text === "") {
              return false;
            }
            return true;
          }}
          value={establishmenAddress}
          handleFormChange={(e) => setEstablishmenAddress(e.target.value)}
          placeholder="Establishment Address"
          isPhoneNumber={false}
          errorMessage={"Can't be empty"}
        />
        <TextInput
          type="number"
          onSelectType="number"
          name="phoneNumber"
          placeholder="Phone Number"
          handleFormChange={(e) => setPhoneNumber(e.target.value)}
          isPhoneNumber={true}
          value={phoneNumber}
          countryCode={countryCodes.INDIA}
          validate={(text) => {
            return text.length === 10;
          }}
          errorMessage={"Must be valid phone number."}
        />
        <TextInput
          type="text"
          onSelectType="text"
          name="recipiantName"
          validate={(text) => {
            if (!text || text === "") {
              return false;
            }
            return true;
          }}
          value={recipiantName}
          handleFormChange={(e) => setRecipiantName(e.target.value)}
          placeholder="Recipiant Name"
          isPhoneNumber={false}
          errorMessage={"Can't be empty"}
        />
        <TextInput
          type="text"
          onSelectType="text"
          name="recipiantDesignation"
          validate={(text) => {
            if (!text || text === "") {
              return false;
            }
            return true;
          }}
          value={recipiantDesignation}
          handleFormChange={(e) => setRecipiantDesignation(e.target.value)}
          placeholder="Recipiant Designation"
          isPhoneNumber={false}
          errorMessage={"Can't be empty"}
        />
      </div>
      <Button
        text={"Add"}
        disabled={
          !(
            establishmentName &&
            establishmenAddress &&
            recipiantName &&
            recipiantDesignation &&
            phoneNumber
          )
        }
        handleClick={generateEmpanelment}
      />
    </div>
  );
};

const LetterTemplateContianer = () => {
  const { setPrintComponent, setPrintName } = useContext(PrintContext);
  const navigate = useNavigate();

  return (
    <div className="bg-white flex flex-col justify-center items-center p-4">
      <h1 className="text-brandGreen-500 font-bold text-4xl">
        {" "}
        Draft Consent From Medical Establishment
      </h1>
      <Button
        text={"Generate Draft"}
        handleClick={() => {
          setPrintComponent(<EmpanelmentConsentDraft />);
          setPrintName("Coordinator_Certificate");
          navigate("/print");
        }}
      />
    </div>
  );
};

export default MedicalEmpanelment;
