import React, { useContext, useEffect, useState } from "react";
import TextInput from "../../components/TextInput";
import { Button } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import { getTransactionRequest } from "../../api";
import { LoadingContext } from "../../context/Context";

const AdminTransactions = () => {
  const params = useParams();
  const [errorMessage, setErrorMessage] = useState(null);
  const [transactionId, setTransactionId] = useState("");
  const [transaction, setTransaction] = useState(null);
  const { setIsLoading } = useContext(LoadingContext);
  const navigate = useNavigate();

  const getAndSetTransaction = async () => {
    try {
      setIsLoading(true);
      const data = await getTransactionRequest(params.id);
      setTransaction(data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setErrorMessage(error.response.data.message || error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (params.id) {
      getAndSetTransaction();
    }
    return () => {};
  }, [params.id]);

  return (
    <div className="bg-gray-300 w-full py-8 flex flex-col justify-center items-center">
      <p className="font-medium text-red-600 my-2">{errorMessage}</p>
      <h1 className="text-brandGreen-500 font-bold text-4xl text-center">
        Transactions
      </h1>
      <div className="flex flex-col justify-center items-center">
        <TextInput
          placeholder="Transaction ID"
          validate={() => {
            return true;
          }}
          handleFormChange={(e) => setTransactionId(e.target.value)}
        />
        <Button
          type="primary"
          text="Get Transaction"
          handleClick={() => navigate(`/admin/transactions/${transactionId}`)}
        />
      </div>

      {transaction && (
        <div className="py-5 my-5 bg-white w-11/12 shadow-sm shadow-gray-400">
          <div className="flex flex-col md:flex-row justify-evenly items-cente">
            <div className="flex flex-col justify-center items-center min-w-[300px]">
              <p className="flex w-full justify-between">
                <b>Account: </b>
                {transaction.accountId}
              </p>
              <p className="flex w-full justify-between">
                <b>Amount: </b>₹{transaction.amount}
              </p>
              <p className="flex w-full justify-between">
                <b>Date: </b>
                {new Date(transaction.createdAt).toDateString()}
              </p>
              <p className="flex w-full justify-between">
                <b>Remark: </b>
                {transaction.remark}
              </p>
              <p className="flex w-full justify-between">
                <b>Source: </b>
                {transaction.source}
              </p>
              <p className="flex w-full justify-between">
                <b>Kind: </b>
                {transaction.kind}
              </p>
            </div>
            <div className="flex justify-center">
              <img
                src={transaction.proof}
                alt="proof"
                className="shadow-md shadow-gray-400 rounded-3xl"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminTransactions;
