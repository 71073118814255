import React, { useContext, useEffect, useState } from "react";
import {
  addGuarenterRequest,
  approveLoanRequest,
  denyLoanRequest,
  getCoordinatorPendingLoansRequest,
  getLoanGuarentersRequest,
} from "../../api";
import { Button } from "../../components";
import TextInput from "../../components/TextInput";
import { countryCodes } from "../../constants";
import { LoadingContext, UserContext } from "../../context/Context";
import { IoAddCircle } from "react-icons/io5";

const CoordinatorLoan = () => {
  const { user } = useContext(UserContext);
  const { setIsLoading } = useContext(LoadingContext);
  const [pendingLoans, setPendingLoans] = useState();

  const getLoans = async () => {
    setIsLoading(true);
    const result = await getCoordinatorPendingLoansRequest(user._id);
    const pl = result.data.filter((d) => d.loan.status === "PENDING");
    setPendingLoans(pl);
    setIsLoading(false);
  };

  useEffect(() => {
    getLoans();
    return () => {};
  }, []);

  return (
    <div className="flex flex-col justify-center items-center py-5 ">
      <h1 className="text-brandGreen-500 font-bold text-4xl">Loans</h1>
      <p>Coordinator Credit Limit: ₹{user.coordinatorCreditLimit}/-</p>

      {pendingLoans && (
        <div className="w-full p-4 lg:px-20">
          {pendingLoans.map((pendingLoan) => (
            <LoanCard pendingLoan={pendingLoan} />
          ))}
        </div>
      )}
    </div>
  );
};

const LoanCard = ({ pendingLoan }) => {
  const { setIsLoading } = useContext(LoadingContext);
  const { user } = useContext(UserContext);

  const [guarenterErrorMessage, setGuarenterErrorMessage] = useState(null);
  const [guarenterPhone, setGuarenterPhone] = useState();
  const [loanGuarenters, setLoanGuarenters] = useState([]);
  const [canApproveLoan, setCanApproveLoan] = useState(true);
  const [amount, setAmount] = useState();

  useEffect(() => {
    getLoanGuarenters();
    return () => {};
  }, []);

  useEffect(() => {
    checkCanApproveLoan();

    return () => {};
  }, [loanGuarenters]);

  const checkCanApproveLoan = () => {
    const approvedGs = loanGuarenters.filter((g) => g.status === "APPROVED");
    let sum = 0;
    approvedGs.forEach((g) => (sum += parseInt(g.amount)));
    const required =
      parseInt(pendingLoan.loan.balance) * -1 -
      parseInt(pendingLoan.user.creditLine);
    console.log(sum < required);
    if (sum < required) {
      setCanApproveLoan(false);
    } else {
      setCanApproveLoan(true);
    }
  };

  const getLoanGuarenters = async () => {
    try {
      setIsLoading(true);
      const result = await getLoanGuarentersRequest(pendingLoan.loan._id);
      setLoanGuarenters(result.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(true);
    }
  };

  const approveLoan = async (id) => {
    try {
      setIsLoading(true);
      await approveLoanRequest({ id: id, coordinatorId: user._id });
      window.location.reload();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      alert("Something went wrong, please try again.");
    }
  };

  const declineLoan = async (id) => {
    try {
      setIsLoading(true);
      await denyLoanRequest({ id: id, coordinatorId: user._id });
      window.location.reload();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      alert("Something went wrong, please try again.");
    }
  };

  const addGuarenter = async () => {
    try {
      const prefixedGID = `+91${guarenterPhone}`;
      if (prefixedGID === pendingLoan.user._id) {
        setGuarenterErrorMessage("Borrower can't be guarenter");
        return;
      }
      const existingGIDs = [];
      loanGuarenters.forEach((g) => {
        existingGIDs.push(g.guarenterDetails.id);
      });

      if (existingGIDs.includes(prefixedGID)) {
        setGuarenterErrorMessage("Guarenter already added");
        return;
      }
      setIsLoading(true);
      await addGuarenterRequest({
        memberId: pendingLoan.user._id,
        coordinatorId: user._id,
        guarenterId: prefixedGID,
        loanId: pendingLoan.loan._id,
        amount: amount,
      });
      setIsLoading(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setGuarenterErrorMessage(error.response.data);
    }
  };

  return (
    <div className="bg-gray-200 shadow-sm shadow-gray-400 p-4 w-full">
      <div
        key={pendingLoan._id}
        className="flex flex-col lg:flex-row justify-between items-center"
      >
        <div className="flex justify-center items-center mb-5 lg:mb-0">
          <img
            src={pendingLoan.user.personalInfo.selfPhoto}
            alt="member"
            width={50}
            className="rounded-full mx-4 object-cover aspect-square shadow-sm shadow-gray-400"
          />
          <div>
            <p>
              <b>Name: </b>
              {pendingLoan.loan.accountHolderDetails.name}
            </p>
            <p>
              <b>Phone Number: </b>
              {pendingLoan.user.phoneNumber}
            </p>
            <p>
              <b>Member Id: </b>
              {pendingLoan.user.memberId}
            </p>
            <p>
              <b>Available Credit: </b>₹{pendingLoan.user.creditLine}
            </p>
          </div>
        </div>
        <div>
          <p>
            <b>Amount Requested: </b>₹{pendingLoan.loan.balance * -1}
          </p>
        </div>
        <div className="flex flex-col mt-5 lg:mt-0">
          <Button
            text={"Approve"}
            additional={"m-1"}
            disabled={!canApproveLoan}
            handleClick={() => approveLoan(pendingLoan.loan._id)}
          />
          <Button
            text={"Decline"}
            additional={"m-1"}
            type={"secondary"}
            handleClick={() => declineLoan(pendingLoan.loan._id)}
          />
        </div>
      </div>
      <div className="h-px bg-gray-300 w-full my-2" />
      {pendingLoan.user.creditLine < pendingLoan.loan.balance * -1 && (
        <div>
          <div>
            <h1 className="text-center font-bold">Guarenters</h1>
            {
              // map guarenters here
              loanGuarenters.map((guarenter) => (
                <>
                  <div
                    key={guarenter._id}
                    className="px-2 flex flex-col lg:flex-row justify-evenly items-center"
                  >
                    <p>{guarenter.guarenterDetails.name}</p>
                    <p>{guarenter.amount}</p>
                    {guarenter.status === "APPROVED" ? (
                      <p className="text-green-600">{guarenter.status}</p>
                    ) : (
                      <p className="text-red-500">{guarenter.status}</p>
                    )}
                  </div>
                  <div className="h-px bg-gray-400 w-full my-2" />
                </>
              ))
            }
          </div>
          {!canApproveLoan && (
            <>
              <div className="h-px bg-gray-300 w-full my-2" />

              <p className="text-center text-xs text-gray-500">
                Add guarenters to full-fill the credit requriement
              </p>
              <div className="flex flex-col lg:flex-row w-full justify-evenly items-center">
                <div>
                  <TextInput
                    type="number"
                    onSelectType="number"
                    name="phoneNumber"
                    placeholder="Guearenter Phone Number"
                    handleFormChange={(e) => {
                      setGuarenterPhone(e.target.value);
                    }}
                    isPhoneNumber={true}
                    value={guarenterPhone}
                    countryCode={countryCodes.INDIA}
                    validate={(text) => {
                      return text.length === 10;
                    }}
                    errorMessage={"Must be 10 digits long"}
                  />
                </div>
                <div>
                  <TextInput
                    type="Number"
                    onSelectType="Number"
                    name="amount"
                    validate={(text) => {
                      if (parseInt(text) < 1) {
                        setAmount(null);
                        return false;
                      }
                      return true;
                    }}
                    value={amount}
                    handleFormChange={(e) => setAmount(e.target.value)}
                    placeholder="Loan Amount"
                    isPhoneNumber={true}
                    countryCode="₹"
                    errorMessage={"Can't be less than ₹1."}
                  />
                </div>
                {guarenterPhone && amount ? (
                  <div
                    className="text-3xl text-brandGreen-500 cursor-pointer"
                    onClick={addGuarenter}
                  >
                    <IoAddCircle />
                  </div>
                ) : (
                  <div className="text-3xl text-brandGreen-100">
                    <IoAddCircle />
                  </div>
                )}
              </div>
              <p className="text-red-500 text-center">
                {guarenterErrorMessage}
              </p>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default CoordinatorLoan;
