import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from ".";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { signInRequest } from "../api/.";
import { LoadingContext, UserContext } from "../context/Context";
import { countryCodes } from "../constants";

const LoginForm = ({ role }) => {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (user || localStorage.getItem("user") != null) {
      setUser(JSON.parse(localStorage.getItem("user")));
      navigate("/");
    }
  }, []);

  return (
    <div className="bg-white text-sm px-10 py-5 mt-10 shadow-lg flex flex-col rounded-lg w-11/12 h-fit">
      <h1 className="text-brandGreen-500 text-3xl font-semibold text-center">
        {role} Login
      </h1>
      {role === "Guest" ? <GuestLogin /> : <NormalLogin role={role} />}
    </div>
  );
};

const NormalLogin = ({ role }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  const { setUser } = useContext(UserContext);
  const { setIsLoading } = useContext(LoadingContext);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(null);

    if (phoneNumber.length !== 10) {
      setErrorMessage("Invalid Phone Number");
      return;
    }

    const formData = {
      phoneNumber: `${countryCodes.INDIA}${phoneNumber}`,
      password,
    };

    try {
      setIsLoading(true);
      const { data } = await signInRequest(formData);
      console.log(data);
      setIsLoading(false);

      if (!data.result.roles.includes(role.toLowerCase())) {
        setErrorMessage(`User is not a ${role}`);
        return;
      }

      localStorage.setItem("user", JSON.stringify(data.result));
      localStorage.setItem("auth", JSON.stringify(data.token));
      localStorage.setItem("role", role);
      setUser(data.result);
      console.log(data);
      if (!data.result.isPasswordReset) {
        console.log("User must reset password");
        navigate("/resetPassword");
        return;
      }
      switch (role) {
        case "Coordinator":
          navigate("/coordinator");
          break;
        case "Member":
          navigate("/member");
          break;
        case "Admin":
          navigate("/admin");
          break;

        default:
          break;
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setErrorMessage("Invalid Number or Password");
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="mt-4 text-sm">
          <div className="relative my-2 overflow-hidden rounded-lg">
            <input
              className="w-full border-2 border-gray-300 rounded-xl py-2 px-12 focus:outline-brandGreen-500"
              placeholder="Phone Number"
              type="number"
              value={phoneNumber}
              onChange={(e) => {
                e.preventDefault();
                const re = /^[0-9\b]+$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  setPhoneNumber(e.target.value);
                  setErrorMessage(null);
                }
              }}
            />
            <div className="absolute top-0 left-0 p-2.5 bg-gray-300">
              {countryCodes.INDIA}
            </div>
          </div>
          <div className="relative">
            <input
              className="w-full border-2 border-gray-300 rounded-xl py-2 px-4 my-2 focus:outline-brandGreen-500"
              placeholder="Password"
              value={password}
              onChange={(e) => {
                e.preventDefault();
                setPassword(e.target.value);
                setErrorMessage(null);
              }}
              type={showPassword ? "" : "password"}
            />
            <div
              className="absolute top-3 right-3 text-3xl text-brandGreen-500"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
            </div>
          </div>
          <div className="my-4 flex text-xs justify-between items-center">
            <div className="flex ">
              <input
                type="checkbox"
                id="remember-me"
                checked={rememberMe}
                onChange={() => {
                  setRememberMe(!rememberMe);
                }}
              />
              <label htmlFor="remember-me" className="ml-2 font-medium">
                Remember Me
              </label>
            </div>
            <Link className="font-medium  hover:text-brandOrange-500 cursor-pointer">
              Forgot Password?
            </Link>
          </div>
        </div>
        {errorMessage ? (
          <p className="font-medium text-red-600 my-2">{errorMessage}</p>
        ) : (
          <></>
        )}
        <button className="w-full" type="submit">
          <Button text={"Login"} type={"primary"} additional="w-full" />
        </button>
      </form>
    </>
  );
};

const GuestLogin = () => {
  const [phoneNumber, setPhoneNumber] = useState();
  const [email, setEmail] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const { setIsLoading } = useContext(LoadingContext);

  const handleGuestLogin = () => {
    if (!email.includes("@") || !email.includes(".")) {
      setErrorMessage("Invalid Email");
      return;
    }
    if (phoneNumber.length !== 10) {
      setErrorMessage("Invalid Phone Number");
      return;
    }
    window.location.href = "/about";
  };

  return (
    <>
      <div className="">
        <div>
          <input
            className="w-full border-2 border-gray-300 rounded-xl py-2 px-4 my-2"
            placeholder="Email"
            value={email}
            onChange={(e) => {
              e.preventDefault();
              setEmail(e.target.value);
            }}
          />
        </div>
        <div className="relative my-2 overflow-hidden rounded-lg">
          <input
            className="w-full border-2 border-gray-300 rounded-xl py-2 px-12 focus:outline-brandGreen-500"
            placeholder="Phone Number"
            type="number"
            value={phoneNumber}
            onChange={(e) => {
              e.preventDefault();
              const re = /^[0-9\b]+$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                setPhoneNumber(e.target.value);
                setErrorMessage(null);
              }
            }}
          />
          <div className="absolute top-0 left-0 p-2.5 bg-gray-300">
            {countryCodes.INDIA}
          </div>
        </div>
      </div>
      <p className="text-red-500">{errorMessage}</p>
      <Button
        text={"Login"}
        type={"primary"}
        additional="w-full mt-4"
        handleClick={handleGuestLogin}
      />
      <Link
        className="mt-4 text-gray-400 text-sm hover:text-gray-600 cursor-pointer"
        to="/about"
      >
        Continue without providing information
      </Link>
    </>
  );
};

export default LoginForm;
