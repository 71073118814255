import React, { useContext, useEffect, useRef, useState } from "react";
import { AiFillPlusCircle, AiOutlineDown } from "react-icons/ai";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import Button from "../Button";
import { v4 } from "uuid";
import TextInput from "../TextInput";
import { countryCodes } from "../../constants";
import { getDistrictsRequest, getStatesRequest } from "../../api";
import { LoadingContext } from "../../context/Context";

const NomineeDetails = ({
  isNomineeOpen,
  setIsNomineeOpen,
  setFormData,
  formData,
  handleFormChange,
}) => {
  const ref = useRef();

  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [isStateSelected, setIsStateSelected] = useState(false);
  const { setIsLoading } = useContext(LoadingContext);
  useEffect(() => {
    const getStates = async () => {
      setIsLoading(true);
      const fetchedStates = await getStatesRequest();
      setStates(fetchedStates.data);
      setIsLoading(false);
    };
    getStates();

    return () => {};
  }, []);

  const selectState = async (e) => {
    setFormData({
      ...formData,
      nomineeAddressState: e.target.value,
      nomineeDistrict: "default",
    });
    setIsLoading(true);
    const fetchedDistricts = await getDistrictsRequest(e.target.value);
    setIsLoading(false);
    setIsStateSelected(true);
    setDistricts(fetchedDistricts.data);
  };

  const [isSameAsPermanent, setIsSameAsPermanent] = useState(false);

  const makeSameAsPermanent = (e) => {
    console.log(formData.pDistrict);
    if (e.target.checked) {
      setFormData({
        ...formData,
        nomineeAddressLine1: formData.pAddressLine1,
        nomineeAddressLine2: formData.pAddressLine2,
        nomineeAddressLine3: formData.pAddressLine3,
        nomineeAddressPincode: formData.pPincode,
        nomineeAddressBlock: formData.pBlock,
        nomineeAddressTehsil: formData.pTehsil,
        nomineeAddressDistrict: formData.pDistrict,
        nomineeAddressState: formData.pState,
      });
    } else {
      setFormData({
        ...formData,
        nomineeAddressLine1: "",
        nomineeAddressLine2: "",
        nomineeAddressLine3: "",
        nomineeAddressPincode: "",
        nomineeAddressBlock: "",
        nomineeAddressTehsil: "",
        nomineeAddressDistrict: "default",
        nomineeAddressState: "default",
      });
    }
    setIsSameAsPermanent(e.target.checked);
  };

  // const [name, setName] = useState();
  // const [aadharNumber, setAadharNumber] = useState();
  // const [dob, setDob] = useState();

  // const [nominees, setNominees] = useState([]);
  // const [add, setAdd] = useState(false);

  // const addNominee = () => {
  //   const newNominee = {
  //     id: v4(),
  //     name: name,
  //     aadharNumber: aadharNumber,
  //     dateOfBirth: dob,
  //   };
  //   setAdd(false);
  //   setFormData({
  //     ...formData,
  //     nomineeDetails: [...formData.nomineeDetails, newNominee],
  //   });
  // };

  // const deleteNominee = (id) => {
  //   setFormData((formData) => {
  //     return {
  //       ...formData,
  //       nomineeDetails: formData.nomineeDetails.filter(
  //         (nominee) => nominee.id !== id
  //       ),
  //     };
  //   });
  // };

  return (
    <div
      id="bank-form"
      className="relative bg-blue-500 mb-2 pt-2 w-full border-b-2 border-white"
    >
      <h1 className="text-white mx-8 mb-2 font-bold">Nominee Details</h1>
      <div
        className="text-white absolute top-0 right-0  w-full flex justify-end p-3 shadow-md  cursor-pointer"
        onClick={() => {
          setIsNomineeOpen(!isNomineeOpen);
        }}
      >
        <AiOutlineDown
          className={
            "transition-all duration-300 rotate-180" +
            (!isNomineeOpen ? "rotate-180" : "")
          }
        />
      </div>
      <div
        className={
          isNomineeOpen
            ? "transition-all duration-300 bg-gray-300 w-full flex flex-col justify-center items-center"
            : "transition-all duration-300 h-0"
        }
      >
        <div className={isNomineeOpen ? "visible" : "hidden"}>
          <div className="w-full flex flex-col">
            <div className=" w-full flex flex-col py-4 px-8 justify-center items-center lg:grid lg:grid-cols-3">
              <TextInput
                type="text"
                name="nomineeName"
                value={formData.nomineeName}
                id={"isName"}
                placeholder="Nominee Name"
                handleFormChange={handleFormChange}
                errorMessage={"Can't be empty"}
                title="Nominee Name"
                validate={(text) => {
                  if (text.length === 0) return false;
                  return true;
                }}
              />
              <TextInput
                type="number"
                name="nomineeAadharNumber"
                value={formData.nomineeAadharNumber}
                title="Nominee Aadhar Number"
                placeholder="Nominee Aadhar No."
                handleFormChange={handleFormChange}
                errorMessage={"Must be 12 digits long"}
                validate={(text) => {
                  if (text.length !== 12) return false;
                  return true;
                }}
              />

              <TextInput
                type="text"
                onSelectType="date"
                name="nomineeDateOfBirth"
                title="Nominee Date of Birth"
                placeholder="Nominee Date Of Birth"
                ref={ref}
                onFocus={() => (ref.current.type = "date")}
                onBlur={() => (ref.current.type = "text")}
                handleFormChange={handleFormChange}
                value={formData.nomineeDateOfBirth}
                validate={(text) => {
                  const dob = new Date(text);
                  const current = new Date();
                  current.setFullYear(current.getFullYear() - 18);
                  const isValid = dob < current;
                  return isValid;
                }}
                errorMessage={"Member must be at least 18 years old."}
              />

              <TextInput
                type="text"
                name="nomineeRelationship"
                value={formData.nomineeRelationship}
                title="Nominee Relationship"
                placeholder="Nominee Relationship"
                handleFormChange={handleFormChange}
                errorMessage={"Can't be empty"}
                validate={(text) => {
                  if (text.length === 0) return false;
                  return true;
                }}
              />
              <div className="relative overflow-hidden rounded-lg bg-red">
                <TextInput
                  type="number"
                  onSelectType="number"
                  name="nomineePhoneNumber"
                  title="Nominee Phone Number"
                  placeholder="Phone Number"
                  handleFormChange={handleFormChange}
                  isPhoneNumber={true}
                  value={formData.nomineePhoneNumber}
                  countryCode={countryCodes.INDIA}
                  validate={(text) => {
                    if (text.length !== 10) {
                      return false;
                    }
                    return true;
                  }}
                  errorMessage={"Must be 10 digits long"}
                />
              </div>
            </div>

            <h1 className="text-brandGreen-500 mx-2 text-2xl font-semibold">
              Nominee Address
            </h1>

            <div className="p-2">
            <label htmlFor="same-as-permanent">
                Same as member's permanent address
              </label>
              <input
                type="checkbox"
                id="same-as-permanent"
                className="mx-2"
                onChange={makeSameAsPermanent}
              />
            </div>

            <div className="flex flex-col lg:flex-row justify-center items-center lg:justify-start lg:items-start">
              <TextInput
                type="text"
                name="nomineeAddressLine1"
                id={"isName"}
                placeholder="Line 1"
                title="Nominee Address Line 1"
                value={formData.nomineeAddressLine1}
                handleFormChange={handleFormChange}
                errorMessage={"Can't be empty"}
                validate={(text) => {
                  if (text.length === 0) return false;
                  return true;
                }}
              />
              <TextInput
                type="text"
                name="nomineeAddressLine2"
                id={"isName"}
                placeholder="Line 2"
                title="Nominee Address Line 2"
                value={formData.nomineeAddressLine2}
                handleFormChange={handleFormChange}
                errorMessage={"Can't be empty"}
                validate={(text) => {
                  if (text.length === 0) return false;
                  return true;
                }}
              />
              <TextInput
                type="text"
                name="nomineeAddressLine3"
                id={"isName"}
                placeholder="Line 3"
                title="Nominee Address Line 3"
                value={formData.nomineeAddressLine3}
                handleFormChange={handleFormChange}
                errorMessage={"Can't be empty"}
                validate={(text) => {
                  if (text.length === 0) return false;
                  return true;
                }}
              />
            </div>

            <div className="flex flex-col lg:flex-row justify-center items-center">
              <TextInput
                type="number"
                onSelectType="number"
                name="nomineeAddressPincode"
                id={"isName"}
                placeholder="Pincode"
                title="Nominee Address Pincode"
                value={formData.nomineeAddressPincode}
                handleFormChange={handleFormChange}
                errorMessage={"Can't be empty"}
                validate={(text) => {
                  if (text.length === 0) return false;
                  return true;
                }}
              />
              <TextInput
                type="text"
                name="nomineeAddressBlock"
                id={"isName"}
                placeholder="Block"
                title="Nominee Address Block"
                value={formData.nomineeAddressBlock}
                handleFormChange={handleFormChange}
                errorMessage={"Can't be empty"}
                validate={(text) => {
                  if (text.length === 0) return false;
                  return true;
                }}
              />
              <TextInput
                type="text"
                name="nomineeAddressTehsil"
                id={"isName"}
                placeholder="Tehsil"
                title="Nominee Address Tehsil"
                value={formData.nomineeAddressTehsil}
                handleFormChange={handleFormChange}
                errorMessage={"Can't be empty"}
                validate={(text) => {
                  if (text.length === 0) return false;
                  return true;
                }}
              />
            </div>
            <div className="flex flex-col justify-center items-center lg:justify-start lg:items-start px-2 ">
              <div className="my-2">
                <select
                  className="py-1.5 px-2 h-full w-11/12 isAddressOpen rounded-lg"
                  name="nomineeAddressState"
                  onChange={selectState}
                  value={formData.nomineeAddressState}
                  title="Permanent Address State and District"
                >
                  <option value="default" disabled>
                    State/UT
                  </option>
                  {states.map((state) => (
                    <option value={state}>{state}</option>
                  ))}
                </select>
              </div>

              <div className="my-2">
                <select
                  className="py-1.5 px-2 h-full w-11/12 isAddressOpen rounded-lg"
                  name="nomineeAddressDistrict"
                  onChange={handleFormChange}
                  disabled={!isStateSelected}
                  value={formData.nomineeAddressDistrict}
                  title="Permanent Address State and District"
                >
                  <option value="default" disabled>
                    District
                  </option>
                  {districts.map((district) => (
                    <option value={district}>{district}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex flex-col-reverse lg:flex-row w-full justify-center items-center mb-4">
              <Button
                text={"Continue"}
                type="primary"
                additional={"mx-4"}
                handleClick={() => {
                  setIsNomineeOpen(false);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // (
  // <div
  //   id="bank-form"
  //   className="relative bg-blue-500 mb-2 pt-2 w-full border-b-2 border-white"
  // >
  //   <h1 className="text-white mx-8 mb-2 font-bold">Nominee Details</h1>
  //   <div
  //     className="text-white absolute top-0 right-0  w-full flex justify-end p-3 shadow-md  cursor-pointer"
  //     onClick={() => {
  //       setIsNomineeOpen(!isNomineeOpen);
  //     }}
  //   >
  //     <AiOutlineDown
  //       className={
  //         "transition-all duration-300 rotate-180" +
  //         (!isNomineeOpen ? "rotate-180" : "")
  //       }
  //     />
  //   </div>
  //   <div
  //     className={
  //       isNomineeOpen
  //         ? "transition-all duration-300 p-4 bg-gray-300 w-full flex flex-col justify-center items-center"
  //         : "transition-all duration-300 h-0"
  //     }
  //   >
  //     <div className={isNomineeOpen ? "visible w-full" : "hidden"}>
  //       <div className="flex flex-col w-full">
  //         {formData.nomineeDetails.map((nominee, ind) => {
  //           return (
  //             <div key={nominee}>
  //               <div className="w-full m-2 flex justify-evenly items-center flex-col lg:flex-row">
  //                 <p>{ind + 1}.</p>
  //                 <p>Name: {nominee.name}</p>
  //                 <p>Aadhar Number: {nominee.aadharNumber}</p>
  //                 <p>Date of Birth: {nominee.dateOfBirth}</p>
  //                 <MdDelete
  //                   className="text-red-500 text-2xl cursor-pointer"
  //                   onClick={() => deleteNominee(nominee.id)}
  //                 />
  //               </div>
  //               <div className="h-px w-full my-2 bg-white"></div>
  //             </div>
  //           );
  //         })}
  //       </div>

  //       {add ? (
  //         <div className="w-full m-2 flex justify-evenly items-center flex-col lg:flex-row">
  //           <TextInput
  //             type="text"
  //             placeholder="Name"
  //             handleFormChange={(e) => setName(e.target.value.toUpperCase())}
  //             id={"isName"}
  //             errorMessage={"Can't be empty"}
  //             validate={(text) => {
  //               if (text.length === 0) return false;
  //               return true;
  //             }}
  //           />
  //           <TextInput
  //             type="number"
  //             onSelectType="number"
  //             name="aadharNumber"
  //             placeholder="Aadhar Number"
  //             handleFormChange={(e) => setAadharNumber(e.target.value)}
  //             errorMessage={"Must be 12 digits long"}
  //             validate={(text) => {
  //               if (text.length === 12) return true;
  //               return false;
  //             }}
  //           />
  // <TextInput
  //   type="text"
  //   onSelectType="date"
  //   name="dateOfBirth"
  //   placeholder="Date Of Birth"
  //   ref={ref}
  //   onFocus={() => (ref.current.type = "date")}
  //   onBlur={() => (ref.current.type = "text")}
  //   handleFormChange={(e) => setDob(e.target.value)}
  //   validate={(text) => {
  //     const dob = new Date(text);
  //     const current = new Date();
  //     current.setFullYear(current.getFullYear() - 18);
  //     const isValid = dob < current;
  //     return isValid;
  //   }}
  //   errorMessage={"Member must be at least 18 years old."}
  // />
  //           <AiFillPlusCircle
  //             className="text-4xl lg:text-8xl text-green-600 h-fit mx-2 cursor-pointer"
  //             onClick={addNominee}
  //           />
  //         </div>
  //       ) : (
  //         <></>
  //       )}

  //       {formData.nomineeDetails.length > 0 ? (
  //         <></>
  //       ) : (
  //         <div
  //           className="flex text-brandGreen-500 rounded-lg cursor-pointer border-2 border-brandGreen-500 justify-center items-center p-2 text-lg"
  //           onClick={() => setAdd(true)}
  //         >
  //           <BsFillPersonPlusFill className="mr-4" />
  //           Add Nominee
  //         </div>
  //       )}
  //       <div className="flex flex-col-reverse lg:flex-row w-full mt-4 justify-center items-center mb-4">
  //         <Button
  //           text={"Continue"}
  //           type="primary"
  //           additional={"mx-4"}
  //           handleClick={() => {
  //             setIsNomineeOpen(false);
  //           }}
  //         />
  //       </div>
  //     </div>
  //   </div>
  // </div>
  // );
};

export default NomineeDetails;
