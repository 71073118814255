import React, { useContext, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Button } from ".";
import { makeDemandRequest } from "../api";
import { DemandContext, LoadingContext, UserContext } from "../context/Context";

const Demand = () => {
  const { demandState, setDemandState } = useContext(DemandContext);
  const [amount, setAmount] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const { user } = useContext(UserContext);
  const { setIsLoading } = useContext(LoadingContext);

  const resetDemandState = () => {
    demandState.handleFailure();

    setDemandState({
      isOpen: false,
      account: null,
      handleSuccess: null,
      handleFailure: null,
    });
  };

  const sendRequest = async () => {
    try {
      setIsLoading(true);
      const newDemand = await makeDemandRequest({
        accountNumber: demandState.account.accountNumber,
        accountId: demandState.account._id,
        amount: amount,
        memberId: demandState.account.userId,
        withdrawerCoordinatorId: user.phoneNumber,
      });
      console.log(newDemand);
      setIsLoading(false);
      demandState.handleSuccess(amount);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      demandState.handleFailure();
    }
  };

  return (
    <div className="fixed w-screen h-screen bg-black  z-20 bg-opacity-50 flex justify-center items-center">
      <AiOutlineClose
        className="absolute top-2 right-2 cursor-pointer text-white font-semibold text-5xl"
        onClick={resetDemandState}
      />
      <div className="bg-white h-5/6 overflow-scroll px-4 py-4 w-11/12 flex flex-col justify-evenly items-center">
        <h1 className="text-brandGreen-500 lg:text-4xl font-bold text-2xl text-center">
          Generate Withdrawal Demand
        </h1>
        <div>
          <p>Account Holder: {demandState.account.accountHolderDetails.name}</p>
          <p>Account Number: {demandState.account.accountNumber}</p>
          <p>Account Type: {demandState.account.type}</p>
          <p>Balance: {demandState.account.balance}</p>
        </div>
        <div className="flex justify-center items-center">
          <p>₹</p>
          <input
            type="text"
            value={amount}
            placeholder="Amount"
            className="mx-2 border-2 border-gray-300 p-2 rounded-lg"
            onChange={(e) => {
              setErrorMessage(null);
              setAmount(e.target.value);
              if (e.target.value > demandState.account.balance) {
                setErrorMessage("Amount can't be more than current balance.");
              }
            }}
          />
        </div>
        <p>
          Remaining Balance: {amount && demandState.account.balance - amount}
        </p>
        <p className="text-red-500">{errorMessage}</p>
        <Button
          type={"primary"}
          text="Raise Demand"
          disabled={!amount || amount > demandState.account.balance}
          handleClick={sendRequest}
        />
      </div>
    </div>
  );
};

export default Demand;
