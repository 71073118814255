import React, { useContext } from "react";
import CoordinatorDetails from "../../components/CoordinatorDetails";
import { UserContext } from "../../context/Context";

const CoordinatorProfile = () => {
  const { user } = useContext(UserContext);
  return (
    <div>
      <CoordinatorDetails coordinator={user} />
    </div>
  );
};

export default CoordinatorProfile;
