import React, { useEffect, useState } from "react";
import {
  NavLink,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import AdminLogin from "./pages/AdminLogin";
import {
  CameraContext,
  DemandContext,
  LoadingContext,
  OpenNavbarContext,
  PaymentContext,
  PrintContext,
  RoleContext,
  UserContext,
} from "./context/Context";
import AdminMember from "./pages/admin/AdminMember";
import AdminCoordinator from "./pages/admin/AdminCoordinator";
import AdminAdmin from "./pages/admin/AdminAdmin";
import AdminData from "./pages/admin/AdminData";
import Admin from "./pages/admin";
import AdminHome from "./pages/admin/AdminHome";
import MemberFormPage from "./pages/MemberFormPage";
import Camera from "./components/Camera";

import ToPrint from "./pages/ToPrint";
import CoordinatorForm from "./components/CoordinatorForm";
import Member from "./pages/member";
import MemberProfile from "./pages/member/MemberProfile";
import { getMemberRequest } from "./api";
import Coordinator from "./pages/coordinator";
import CoordinatorProfile from "./pages/admin/CoordinatorProfile";
import CoordinatorMember from "./pages/coordinator/CoordinatorMember";
import CoordinatorCoordinator from "./pages/coordinator/CoordinatorCoordinator";
import CoordinatorTransactions from "./pages/coordinator/CoordinatorTransactions";
import CreateAccountForm from "./components/CreateAccountForm";
import PaymentPage from "./components/PaymentPage";
import Demand from "./components/Demand";
import AccountStatement from "./components/AccountStatement";
import TestPage from "./pages/TestPage";
import Loading from "./components/Loading";
import CoordinatorShares from "./pages/CoordinatorShares";
import MemberAccounts from "./pages/member/MemberAccounts";
import ShareReceipt from "./components/ShareReceipt";
import AccountProvisionalReceipt from "./components/AccountProvisionalReceipt";
import ResetPassword from "./pages/ResetPassword";
import MemberLoan from "./pages/member/MemberLoan";
import CoordinatorLoan from "./pages/coordinator/CoordinatorLoan";
import Website from "./pages/website";
import { AiFillBell, AiFillHome } from "react-icons/ai";
import { SignoutButton } from "./components";
import CoordinatorDeposit from "./pages/coordinator/CoordinatorDeposit";
import AdminNav from "./pages/admin/AdminNav";
import MedicalEmpanelment from "./pages/coordinator/MedicalEmpanelment";
import AdminEditMid from "./pages/admin/AdminEditMid";
import AdminTransactions from "./pages/admin/AdminTransactions";

const App = () => {
  const [user, setUser] = useState(
    localStorage.user ? JSON.parse(localStorage.user) : null
  );
  const [role, setRole] = useState(
    localStorage.role ? localStorage.role : null
  );
  const [openNavbar, setOpenNavbar] = useState(true);
  const [cameraState, setCameraState] = useState({
    isOpen: false,
    width: 0,
    height: 0,
    setTargetImage: null,
  });
  const [paymentState, setPaymentState] = useState({
    isOpen: false,
    totalAmount: 0,
    breakDown: [],
    handleSuccess: null,
    handleFailure: null,
    accountId: null,
    remark: null,
    source: null,
    kind: null,
    hasFollowTransaction: false,
  });

  const [demandState, setDemandState] = useState({
    isOpen: false,
    account: null,
    handleSuccess: null,
    handleFailure: null,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [printComponent, setPrintComponent] = useState(null);
  const [printName, setPrintName] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const refreshUser = async () => {
      setIsLoading(true);
      const u = await getMemberRequest(user.phoneNumber);
      setIsLoading(false);
      localStorage.setItem("user", JSON.stringify(u.data));
      setUser(u.data);
    };

    if (user) {
      refreshUser();
    }
    return () => {};
  }, []);

  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement(
  //     {
  //       pageLanguage: "en",
  //       autoDisplay: false,
  //     },
  //     "google_translate_element"
  //   );
  // };
  // useEffect(() => {
  //   var addScript = document.createElement("script");
  //   addScript.setAttribute(
  //     "src",
  //     "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  //   );
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;
  // }, []);

  const reload = () => window.location.reload();

  const Nav = () => {
    if (localStorage.getItem("role") === "Admin") {
      return <AdminNav />;
    }
    return (
      <nav className="z-30 relative shadow-gray-400 bg-brandGreen-500 h-[60px] shadow-md">
        <div className="w-full h-full flex justify-between items-center px-10">
          <NavLink
            className="text-5xl text-white hover:text-brandOrange-500"
            to={`/${role}`}
          >
            <AiFillHome />
          </NavLink>
          <div className="flex">
            {role === "Coordinator" && <CoordinatorNotification />}

            <SignoutButton />
          </div>
        </div>
      </nav>
    );
  };

  const CoordinatorNotification = () => {
    if (user.pendingLoans.length > 0) {
      return (
        <div
          className="text-white text-5xl h-full flex justify-center items-center px-10 cursor-pointer hover:text-brandOrange-500 animate-bounce"
          onClick={() => {
            navigate("/coordinator/loan");
          }}
        >
          <AiFillBell />
        </div>
      );
    }
    return (
      <div className="text-white text-5xl h-full flex justify-center items-center px-10 cursor-pointer hover:text-brandOrange-500">
        <AiFillBell />
      </div>
    );
  };

  return (
    <>
      {/* <div id="google_translate_element"></div> */}
      <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
        <UserContext.Provider value={{ user, setUser }}>
          <CameraContext.Provider value={{ cameraState, setCameraState }}>
            <RoleContext.Provider value={{ role, setRole }}>
              <PaymentContext.Provider
                value={{ paymentState, setPaymentState }}
              >
                <DemandContext.Provider value={{ demandState, setDemandState }}>
                  <PrintContext.Provider
                    value={{
                      printComponent,
                      setPrintComponent,
                      printName,
                      setPrintName,
                    }}
                  >
                    <OpenNavbarContext.Provider
                      value={{ openNavbar, setOpenNavbar }}
                    >
                      {isLoading ? <Loading /> : <></>}
                      {paymentState.isOpen ? <PaymentPage /> : <></>}
                      {cameraState.isOpen ? <Camera /> : <></>}
                      {demandState.isOpen ? <Demand /> : <></>}
                      {process.env.REACT_APP_IS_TEST === "true" && (
                        <h1 className="bg-red-500 text-white text-xs w-full text-center">
                          FOR TESTING AND DEMO PURPOSES ONLY
                        </h1>
                      )}
                      {user && <Nav />}
                      <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/about" element={<Website />} />
                        {/* <Route path="/testPage" element={<TestPage />} /> */}
                        <Route path="/login" element={<Login />} />
                        <Route
                          path="/resetPassword"
                          element={<ResetPassword />}
                        />
                        {/* <Route path="/reciept" element={<Reciept />} /> */}
                        <Route
                          path="/accountStatement/:accountNumber"
                          element={<AccountStatement />}
                        />

                        {/* admin */}
                        <Route path="/admin/login" element={<AdminLogin />} />
                        <Route path="/print" element={<ToPrint />} />
                        <Route path="/admin" element={<Admin />}>
                          <Route path="/admin/home" element={<AdminHome />} />
                          <Route
                            path="/admin/member"
                            element={<AdminMember />}
                          />
                          <Route
                            path="/admin/member/:id"
                            element={<AdminMember />}
                          />
                          <Route
                            path="/admin/transactions"
                            element={<AdminTransactions />}
                          />
                          <Route
                            path="/admin/transactions/:id"
                            element={<AdminTransactions />}
                          />
                          <Route
                            path="/admin/memberForm"
                            element={<MemberFormPage />}
                          />
                          <Route
                            path="/admin/memberForm/:id"
                            element={<MemberFormPage />}
                          />
                          <Route
                            path="/admin/coordinator"
                            element={<AdminCoordinator />}
                          />
                          <Route
                            path="/admin/coordinator/:id"
                            element={<AdminCoordinator />}
                          />
                          <Route path="/admin/admin" element={<AdminAdmin />} />
                          <Route path="/admin/data" element={<AdminData />} />
                          <Route
                            path="/admin/editMid"
                            element={<AdminEditMid />}
                          />

                          <Route
                            path="/admin/coordinatorForm"
                            element={<CoordinatorForm />}
                          />
                        </Route>

                        <Route
                          path="/member/profile"
                          element={<MemberProfile />}
                        />
                        <Route
                          path="/member/accounts"
                          element={<MemberAccounts />}
                        />
                        <Route
                          path="/member/loan/:id"
                          element={<MemberLoan />}
                        />
                        <Route path="/member" element={<Member />}></Route>

                        <Route
                          path="/accounts/receipt/:id"
                          element={<AccountProvisionalReceipt />}
                        />
                        <Route
                          path="/member/share/receipt/:id"
                          element={<ShareReceipt />}
                        />

                        <Route
                          path="/coordinator/profile"
                          element={<CoordinatorProfile />}
                        />
                        <Route
                          path="/coordinator/member"
                          element={<CoordinatorMember />}
                        />
                        <Route
                          path="/coordinator/accounts"
                          element={<CoordinatorTransactions />}
                        />
                        <Route
                          path="/coordinator/accounts/:id"
                          element={<CoordinatorTransactions />}
                        />
                        <Route
                          path="/coordinator/shares/:id"
                          element={<CoordinatorShares />}
                        />
                        <Route
                          path="/coordinator/accounts/:id/create"
                          element={<CreateAccountForm />}
                        />
                        <Route
                          path="/coordinator/member/:id"
                          element={<CoordinatorMember />}
                        />
                        <Route
                          path="/coordinator/memberForm"
                          element={<MemberFormPage />}
                        />
                        <Route
                          path="/coordinator/memberForm/:id"
                          element={<MemberFormPage />}
                        />
                        <Route
                          path="/coordinator/coordinatorForm"
                          element={<CoordinatorForm />}
                        />
                        <Route
                          path="/coordinator/coordinator"
                          element={<CoordinatorCoordinator />}
                        />
                        <Route
                          path="/coordinator/coordinator/:id"
                          element={<CoordinatorCoordinator />}
                        />
                        <Route
                          path="/coordinator/loan"
                          element={<CoordinatorLoan />}
                        />
                        <Route
                          path="/coordinator/deposit"
                          element={<CoordinatorDeposit />}
                        />
                        <Route
                          path="/coordinator/medicalEmpanelment"
                          element={<MedicalEmpanelment />}
                        />
                        <Route
                          path="/coordinator"
                          element={<Coordinator />}
                        ></Route>
                        <Route path="/sitemap.xml" onEnter={reload} />
                        <Route
                          path="/static/website/index.html"
                          onEnter={reload}
                        />
                      </Routes>
                    </OpenNavbarContext.Provider>
                  </PrintContext.Provider>
                </DemandContext.Provider>
              </PaymentContext.Provider>
            </RoleContext.Provider>
          </CameraContext.Provider>
        </UserContext.Provider>
      </LoadingContext.Provider>
    </>
  );
};

export default App;
