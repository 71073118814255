import React, { useContext, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { makePaymentRequest } from "../api";
import {
  CameraContext,
  LoadingContext,
  PaymentContext,
  UserContext,
} from "../context/Context";
import Button from "./Button";
import { images } from "../constants";

const PaymentPage = () => {
  const { paymentState, setPaymentState } = useContext(PaymentContext);
  const { cameraState, setCameraState } = useContext(CameraContext);
  const { user } = useContext(UserContext);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [password, setPassword] = useState("");
  const [paymentErrorMessage, setPaymentErrorMessage] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [proof, setProof] = useState(null);
  const [canPayCash, setCanPayCash] = useState(
    paymentState.totalAmount < 20000
  );
  const [amount, setAmount] = useState(paymentState.totalAmount);
  const [isSuccessFull, setIsSuccessFull] = useState(false);

  const launchCamera = () => {
    setCameraState({
      ...cameraState,
      isOpen: true,
      setTargetImage: setProof,
    });
  };

  const resetPaymentState = () => {
    paymentState.handleFailure();
    setPaymentState({
      isOpen: false,
      totalAmount: 0,
      breakDown: [],
      handleSuccess: null,
      handleFailure: null,
      accountId: null,
      remark: null,
      source: null,
      kind: null,
    });
  };

  const sendRequest = async () => {
    try {
      console.log(amount, paymentState.totalAmount);
      setIsLoading(true);
      const transaction = await makePaymentRequest({
        amount: amount,
        accountId: paymentState.accountId,
        remark: paymentState.remark,
        kind: paymentState.kind,
        breakDown: paymentState.breakDown,
        source: paymentState.source,
        method: paymentMethod,
        proof: proof,
        userId: user._id,
        password: password,
      });
      console.log(transaction);
      setIsLoading(false);

      if (paymentState.hasFollowTransaction) {
        setIsLoading(true);
        await paymentState.handleSuccess(amount);
        setIsLoading(false);
      } else {
        setIsLoading(true);
        await paymentState.handleSuccess();
        setIsLoading(false);
      }

      setPaymentErrorMessage(null);
      setPaymentState({
        isOpen: false,
        totalAmount: 0,
        breakDown: [],
        handleSuccess: null,
        handleFailure: null,
        accountId: null,
        remark: null,
        source: null,
        kind: null,
      });
      // setIsSuccessFull(true);

      // setTimeout(() => {}, 2000);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setPaymentErrorMessage(error.response.data);
    }
  };

  return (
    <div className="fixed w-screen h-screen bg-black overflow-y-hidden z-20 bg-opacity-50 flex justify-center items-center">
      <AiOutlineClose
        className="absolute top-2 right-2 cursor-pointer text-white -z-50 font-semibold text-5xl"
        onClick={resetPaymentState}
      />
      {isSuccessFull ? (
        <div className="bg-green-100 p-10 lg:p-20 border-green-600 border-8 lg:text-4xl text-green-600 flex justify-center items-center">
          <BsFillPatchCheckFill className="text-4xl lg:text-8xl mr-10" />
          <p>Payment Successfull!</p>
        </div>
      ) : (
        <div className="bg-white h-5/6 overflow-scroll py-4 w-11/12 flex flex-col justify-start items-center">
          <h1 className="text-brandGreen-500 text-4xl font-bold">Payment</h1>
          <div className="flex flex-col mt-4 lg:px-48 justify-center px-12 text-2xl w-full">
            {paymentState.totalAmount ? (
              <p>Total: ₹{paymentState.totalAmount}/-</p>
            ) : (
              <p>
                Total: ₹
                <input
                  type="number"
                  value={amount}
                  placeholder="Amount"
                  className="border-2 border-gray-300 p-2 rounded-lg"
                  onChange={(e) => {
                    setAmount(parseInt(e.target.value));
                    setCanPayCash(parseInt(e.target.value) < 20000);
                  }}
                />
                /-
              </p>
            )}
            <div className="h-px w-full bg-gray-400 my-2"></div>
            <div className="text-sm text-gray-500 ml-4">
              {paymentState.breakDown.map((item) => {
                return (
                  <div>
                    <p>
                      {item.itemName}: ₹{item.value}/- x {item.multiple}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="h-px w-full bg-gray-400 my-4"></div>

          <div className="flex flex-col -8 lg:px-48 justify-center items-center px-12 text-2xl w-full">
            <p className="text-3xl text-brandGreen-500">Payment Method</p>
            <div className="flex flex-col my-4 justify-start items-start">
              {canPayCash && (
                <div className="flex justify-center items-center">
                  <input
                    type="radio"
                    value="cash"
                    name="payment-method"
                    id="radio-cash"
                    disabled={!canPayCash}
                    onClick={() => {
                      setPaymentMethod("cash");
                      setProof("null");
                    }}
                  />
                  <label
                    htmlFor="radio-cash"
                    className={canPayCash ? "mx-4" : "mx-4 text-gray-400"}
                  >
                    Cash
                  </label>
                </div>
              )}
              {!canPayCash && (
                <p className="text-sm ml-8 text-gray-500">
                  Cannot pay cash for amount more than ₹20,000
                </p>
              )}
              <div className="flex justify-center items-center">
                <input
                  type="radio"
                  value="cheque"
                  name="payment-method"
                  id="radio-cheque"
                  onClick={() => {
                    setPaymentMethod("cheque");
                    setProof(null);
                  }}
                />
                <label htmlFor="radio-cheque" className="mx-4">
                  Cheque
                </label>
              </div>
              <div className="flex justify-center items-center">
                <input
                  type="radio"
                  value="upi"
                  name="payment-method"
                  id="radio-upi"
                  onClick={() => {
                    setPaymentMethod("upi");
                    setProof(null);
                  }}
                />
                <label htmlFor="radio-upi" className="mx-4">
                  UPI
                </label>
              </div>
            </div>
            {paymentMethod === "upi" && (
              <div className="flex flex-col justify-center items-center mb-10 text-center">
                <p className="text-sm font-bold">
                  Baranwal Management Consultancy Pvt. Ltd.
                </p>
                <p className="text-xs">
                  (Certified Payment & Collections Agent)
                </p>
                <img src={images.payment_qr} alt="upi qr code" />
              </div>
            )}
            {(paymentMethod === "cheque" || paymentMethod === "upi") && (
              <div
                className="photo flex justify-center items-center cursor-pointer text-center"
                onClick={launchCamera}
              >
                {proof ? (
                  <img src={proof} alt="proof" />
                ) : (
                  "TAKE PHOTO OF CHECK/UPI"
                )}
              </div>
            )}
          </div>

          <div className="h-px w-full bg-gray-400 my-2"></div>
          {/* <input
          type="password"
          value={password}
          placeholder="Password"
          className="mb-6 border-2 border-gray-300 p-2 rounded-lg"
          onChange={(e) => setPassword(e.target.value)}
        /> */}
          <p className="text-red-500">{paymentErrorMessage}</p>
          <Button
            type={"primary"}
            text="Make Payment"
            disabled={
              paymentMethod === null || proof === null || isLoading
              // || password.length < 8
            }
            handleClick={sendRequest}
          />
          {(paymentMethod === null || proof === null) && (
            <p className="text-gray-500 py-4 px-12 text-center">
              Please complete a payment method to proceed
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default PaymentPage;
