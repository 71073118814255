import React, { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  buySharesRequest,
  getMemberAccountsRequest,
  getMemberRequest,
} from "../api";
import { Button } from "../components";
import ShareReceipt from "../components/ShareReceipt";
import TextInput from "../components/TextInput";
import {
  LoadingContext,
  PaymentContext,
  PrintContext,
  UserContext,
} from "../context/Context";

const CoordinatorShares = () => {
  const params = useParams();
  const [errorMessage, setErrorMessage] = useState(null);
  const [member, setMember] = useState();
  const { setIsLoading } = useContext(LoadingContext);
  const { user } = useContext(UserContext);
  const { setPaymentState } = useContext(PaymentContext);
  const { setPrintComponent, setPrintName } = useContext(PrintContext);
  const [noOfShares, setNoOfShares] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.id) {
      getMemberAccounts(params.id);
    }
    return () => {};
  }, [params.id]);

  const getMemberAccounts = async (id) => {
    try {
      setIsLoading(true);
      const result = await getMemberRequest(id);
      setIsLoading(false);
      if (!result.data.parentIds.includes(user._id)) {
        setErrorMessage("You do not have access to this member. ");
        return;
      }
      setMember(result.data);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setErrorMessage("Member not found. ");
    }
  };

  const raiseSharePayment = (e) => {
    e.preventDefault();
    setPaymentState({
      isOpen: true,
      totalAmount: noOfShares * 10,
      breakDown: [
        {
          itemName: "Shares",
          value: noOfShares * 10,
          multiple: noOfShares,
        },
      ],
      handleSuccess: () => buyShares(),
      handleFailure: () => setErrorMessage("Payment Failed"),
      accountId: user.collectionAccount,
      remark: `Member ${member.phoneNumber} purchased shares of ${
        noOfShares * 10
      }`,
      source: "Member",
      kind: "credit",
    });
  };

  const buyShares = async () => {
    try {
      setIsLoading(true);
      await buySharesRequest({
        id: member.phoneNumber,
        noOfShares,
      });
      setIsLoading(false);
      navigate(`/coordinator/accounts/${member.phoneNumber}`);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setErrorMessage("An error occured, please check shares and try again");
    }
  };

  return (
    <>
      {member && (
        <div className="flex flex-col p-2 justify-center items-center my-10">
          <h1 className="font-bold text-2xl">Personal Details</h1>
          <img
            src={member.personalInfo.selfPhoto}
            alt="member"
            width={100}
            className="rounded-full object-cover aspect-square mx-5 my-5 shadow-lg shadow-gray-400"
          />
          <div className="flex flex-col lg:flex-row justify-center items-center">
            <div className=" flex flex-col justify-center items-start">
              <p>
                <b>Name:</b> {member.personalInfo.firstName}{" "}
                {member.personalInfo.middleName} {member.personalInfo.lastName}
              </p>
              <p className="flex">
                <b>Is Active: </b>
                {member.hasBeenActivated ? (
                  <p className="text-green-600">Yes</p>
                ) : (
                  <p className="text-red-600">No</p>
                )}
              </p>
              <p>
                <b>Shares:</b> {member.shares.length}
              </p>
            </div>
          </div>
          <div className="flex m-4 flex-col lg:flex-row w-full justify-evenly"></div>
          {!member.hasBeenActivated && (
            <p className="text-gray-500">
              Please complete the member form to activate member.
            </p>
          )}

          {/* map */}
          <div className="mb-5 w-full lg:px-10">
            <div className="w-full lg:px-20">
              <h1 className="font-bold text-center text-2xl ">Shares</h1>
              <div className="invisible lg:visible grid grid-cols-4 font-bold">
                <p>S No.</p>
                <p>Number of Shares</p>
                <p>Date Purchased</p>
                <p>Action</p>
              </div>
            </div>
            <div className="h-px bg-gray-600 w-full" />
            {member.shareDetails.map((detail, ind) => (
              <>
                <div className="flex flex-col lg:grid lg:grid-cols-4 w-full lg:px-20">
                  <div className="flex flex-row items-center">
                    <p className="lg:hidden">
                      <b>S No.: </b>
                    </p>
                    <p> {ind + 1}</p>
                  </div>
                  <div className="flex flex-row items-center">
                    <p className="lg:hidden">
                      <b>Number of Shares: </b>
                    </p>
                    <p> {detail.noOfShares}</p>
                  </div>
                  <div className="flex flex-row items-center">
                    <p className="lg:hidden">
                      <b>Date Purchased</b>
                    </p>
                    <p> {new Date(detail.datePurchased).toDateString()}</p>
                  </div>
                  <div className="flex flex-row items-center">
                    <p className="lg:hidden">
                      <b>Actions: </b>
                    </p>
                    <p
                      className="text-brandGreen-500 underline cursor-pointer"
                      onClick={() => {
                        setPrintComponent(
                          <ShareReceipt member={member} detailId={detail._id} />
                        );
                        setPrintName(
                          `Share_Receipt_${member.phoneNumber}_${new Date(
                            detail.datePurchased
                          ).toLocaleDateString()}`
                        );
                        navigate(`/print`);
                      }}
                    >
                      Generate Receipt
                    </p>
                  </div>
                </div>
                <div className="h-px bg-gray-400 w-full" />
              </>
            ))}
          </div>

          <div className="bg-gray-300 px-4 py-10 shadow-md shadow-gray-400 flex flex-col justify-between items-center">
            <h1 className="text-3xl text-brandGreen-500 font-bold">
              Buy More Shares
            </h1>
            <p>₹10/- per share</p>
            <form className="flex flex-col justify-evenly items-center m-2">
              <TextInput
                handleFormChange={(e) => setNoOfShares(e.target.value)}
                errorMessage="Can't be less than 1."
                type="number"
                onSelectType="number"
                name="noOfShares"
                placeholder="No. of shares..."
                validate={(text) => {
                  if (parseInt(text) < 1) {
                    setNoOfShares(null);
                    return false;
                  }
                  return true;
                }}
                value={noOfShares}
              />
              <p className="text-red-500">{errorMessage}</p>
              <button type="submit">
                <Button
                  type="primary"
                  text="Purchase"
                  additional={"my-4"}
                  handleClick={raiseSharePayment}
                  disabled={!noOfShares}
                />
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default CoordinatorShares;
