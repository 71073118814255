import React, { useContext, useEffect, useRef, useState } from "react";
import TextInput from "../../components/TextInput";
import { Button } from "../../components";
import { getDemandsByDateRequest, getReportByDateRequest } from "../../api";
import { LoadingContext } from "../../context/Context";
import { generateAndDownloadCsv } from "../../utils/generateCsv";

const AdminData = () => {
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const { setIsLoading } = useContext(LoadingContext);

  const [errorMessage, setErrorMessage] = useState("");

  const ref = useRef();

  const generateDemant = async () => {
    try {
      setIsLoading(true);
      setErrorMessage("");
      const demands = await getDemandsByDateRequest(fromDate, toDate);
      const csvContent = generateAndDownloadCsv(
        demands.data.columnNames,
        demands.data.rows
      );
      const downloadElement = document.createElement("a");
      downloadElement.setAttribute(
        "href",
        `data:text/csv;charset=utf-8,${csvContent}`
      );
      downloadElement.setAttribute("download", `demands_${fromDate}_${toDate}`);
      downloadElement.click();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error.message);
    }
  };

  const generateReport = async () => {
    try {
      setIsLoading(true);
      setErrorMessage("");
      const reportData = await getReportByDateRequest(fromDate, toDate);
      const csvContent = generateAndDownloadCsv(
        reportData.data.columnNames,
        reportData.data.rows
      );
      const downloadElement = document.createElement("a");
      downloadElement.setAttribute(
        "href",
        `data:text/csv;charset=utf-8,${csvContent}`
      );
      downloadElement.setAttribute("download", `report_${fromDate}_${toDate}`);
      downloadElement.click();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="p-4 bg-gray-300 flex flex-col justify-center items-center ">
      <p className="mx-4 text-red-500 text-semibold">{errorMessage}</p>
      <div className=" m-2 flex">
        <TextInput
          type="text"
          onSelectType="date"
          name="from"
          placeholder="From"
          value={fromDate}
          ref={ref}
          onFocus={() => (ref.current.type = "date")}
          onBlur={() => (ref.current.type = "text")}
          handleFormChange={(e) => setFromDate(e.target.value)}
          validate={(text) => {
            const selectedDate = new Date(text);
            const current = new Date();
            const isValid = selectedDate <= current;
            console.log(isValid);
            if (!isValid) {
              setFromDate("");
            }
            return isValid;
          }}
          errorMessage={"Cannot be greater than today's date."}
        />
        <TextInput
          type="text"
          onSelectType="date"
          name="to"
          placeholder="To"
          value={toDate}
          ref={ref}
          onFocus={() => (ref.current.type = "date")}
          onBlur={() => (ref.current.type = "text")}
          handleFormChange={(e) => setToDate(e.target.value)}
          validate={(text) => {
            const selectedDate = new Date(text);
            const current = new Date();
            const isValid = selectedDate <= current;
            if (!isValid) {
              setToDate("");
            }
            return isValid;
          }}
          errorMessage={"Cannot be greater than today's date."}
        />
      </div>
      <div className=" flex flex-col justify-center">
        <Button
          text={"Generate Report"}
          additional={"m-4"}
          handleClick={generateReport}
          disabled={!fromDate || !toDate}
        />
        <Button
          text={"Generate Demands"}
          additional={"m-4"}
          handleClick={generateDemant}
          disabled={!fromDate || !toDate}
        />
      </div>
    </div>
  );
};

export default AdminData;
