import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from ".";
import { UserContext, PrintContext } from "../context/Context";
import MemberIdCard from "./MemberIdCard";

const MemberDetails = ({ member }) => {
  return (
    <div className="px-8 py-8 flex flex-col justify-center items-center">
      <h1 className="text-4xl font-bold text-brandGreen-500 text-center">
        Member Details
      </h1>
      <MemberPersonal member={member} />
      <div className="h-px bg-gray-600 w-full" />
      <Address member={member} />
      <div className="h-px bg-gray-600 w-full" />
    </div>
  );
};

export const MemberPersonal = ({ member }) => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { setPrintComponent, setPrintName } = useContext(PrintContext);
  return (
    <div className="flex flex-col p-2 justify-center items-center my-10">
      <h1 className="font-bold text-2xl">Personal Details</h1>
      <img
        src={member.personalInfo.selfPhoto}
        alt="member"
        width={100}
        className="rounded-full object-cover aspect-square mx-5 my-5 shadow-lg shadow-gray-400"
      />
      <div className="flex flex-col lg:flex-row justify-center items-center">
        <div className=" flex flex-col justify-center items-start lg:grid lg:grid-cols-3 lg:justify-evenly lg:px-20">
          <p className="flex">
            <b>Is Active: </b>
            {member.hasBeenActivated ? (
              <p className="text-green-600">Yes</p>
            ) : (
              <p className="text-red-600">No</p>
            )}
          </p>
          <p className="w-full  px-4">
            <b>Name: </b>
            {member.personalInfo.firstName} {member.personalInfo.middle}{" "}
            {member.personalInfo.lastName}
          </p>
          <p className="w-full  px-4">
            <b>DOB: </b>
            {member.personalInfo.dateOfBirth && member.personalInfo.dateOfBirth.slice(0, 10)}
          </p>
          <p className="w-full  px-4">
            <b>Member ID: </b>
            {member.memberId}
          </p>
          <p className="w-full  px-4">
            <b>Phone Number: </b>
            {member.phoneNumber}
          </p>
          <p className="w-full  px-4">
            <b>Email: </b>
            {member.personalInfo.emailAddress}
          </p>
        </div>
      </div>
      {member._id === user._id && (
        <div className="w-full flex justify-evenly">
          <Button
            text="Download ID Card"
            additional={"my-2"}
            handleClick={() => {
              setPrintComponent(<MemberIdCard member={user} />);
              setPrintName("Coordinator_Certificate");
              navigate("/print");
            }}
          />
          <Button
            text="Change Password"
            additional={"my-2"}
            handleClick={() => {
              navigate("/resetPassword");
            }}
          />
        </div>
      )}
    </div>
  );
};

const Address = ({ member }) => {
  return (
    <div className="flex flex-col p-2 justify-center items-center my-10 w-full">
      <h1 className="font-bold text-2xl">Address</h1>
      <div className="flex flex-col lg:flex-row justify-evenly items-center w-full">
        <div className="flex flex-col justify-start w-full my-5">
          <h2 className="font-bold">Permanent Address</h2>
          <div className="ml-4">
            <p>{member.permanentAddress.addressLine1},</p>
            <p>{member.permanentAddress.addressLine2},</p>
            <p>{member.permanentAddress.addressLine3},</p>
            <p>
              {member.permanentAddress.block}, {member.permanentAddress.tehsil}
            </p>
            <p>
              {member.permanentAddress.district},{" "}
              {member.permanentAddress.state}
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-start w-full my-5">
          <h2 className="font-bold">Current Address</h2>
          <div className="ml-4">
            <p>{member.permanentAddress.addressLine1},</p>
            <p>{member.permanentAddress.addressLine2},</p>
            <p>{member.permanentAddress.addressLine3},</p>
            <p>
              {member.permanentAddress.block}, {member.permanentAddress.tehsil}
            </p>
            <p>
              {member.permanentAddress.district},{" "}
              {member.permanentAddress.state}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberDetails;
