import React, { useContext, useEffect, useState } from "react";
import { MdToday } from "react-icons/md";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Button } from ".";
import {
  createAccountRequest,
  getCurrentRateRequest,
  getMemberRequest,
} from "../api";
import {
  LoadingContext,
  PaymentContext,
  UserContext,
} from "../context/Context";
import TextInput from "./TextInput";

const CreateAccountForm = () => {
  const params = useParams();
  const [phoneNumber, setPhoneNumber] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const [member, setMember] = useState(null);
  const [currentRate, setCurrentRate] = useState(null);
  const { user } = useContext(UserContext);
  const { setIsLoading } = useContext(LoadingContext);

  const getCurrentRate = async () => {
    setIsLoading(true);
    const rate = await getCurrentRateRequest();
    setIsLoading(false);
    setCurrentRate(rate.data);
  };

  useEffect(() => {
    getCurrentRate();
    return () => {};
  }, []);

  useEffect(() => {
    if (params.id) {
      setPhoneNumber(params.id.slice(3));
      getDetails(params.id);
    }
    return () => {};
  }, [params.id]);

  const getDetails = async (id) => {
    try {
      setIsLoading(true);
      const existingMember = await getMemberRequest(id);
      setIsLoading(false);
      if (!existingMember.data.parentIds.includes(user._id)) {
        setErrorMessage("You do not have access to this member. ");
        return;
      }
      setMember(existingMember.data);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setErrorMessage("Member not found. ");
    }
  };
  return (
    <div>
      {member && currentRate ? (
        <Form member={member} currentRate={currentRate} user={user} />
      ) : (
        <p>{errorMessage}</p>
      )}
    </div>
  );
};

const Form = ({ member, currentRate, user }) => {
  const [accountType, setAccountType] = useState("default");
  const [scheme, setScheme] = useState("default");
  const [duration, setDuration] = useState("default");
  const [availablePeriods, setAvailablePeriods] = useState([]);
  const [amount, setAmount] = useState();
  const [applicableInterest, setApplicableInterest] = useState();
  const [matureDate, setMatureDate] = useState("");
  const [schemeName, setschemeName] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isDaily, setIsDaily] = useState(false);
  const { setIsLoading } = useContext(LoadingContext);
  const today = new Date().getDate();
  const navigate = useNavigate();

  const { setPaymentState } = useContext(PaymentContext);

  let ordinal;
  switch (today) {
    case 1:
      ordinal = "st";
      break;
    case 2:
      ordinal = "nd";
      break;
    case 3:
      ordinal = "rd";
      break;

    default:
      ordinal = "th";
      break;
  }

  const selectScheme = (e) => {
    const s = e.target.value;
    setDuration("default");
    setApplicableInterest(null);
    setScheme(s);
    switch (s) {
      case "S":
        setAvailablePeriods([
          { value: 12, text: "12 months" },
          { value: 24, text: "24 months" },
        ]);
        break;

      case "M":
        setAvailablePeriods([
          { value: 36, text: "36 months" },
          { value: 48, text: "48 months" },
        ]);
        break;

      case "L":
        setAvailablePeriods([
          { value: 60, text: "60 months" },
          { value: 72, text: "72 months" },
        ]);
        break;

      default:
        break;
    }
  };

  const selectInterest = (e) => {
    setDuration(e.target.value);
    const schemeNameAndDuration =
      accountType.toUpperCase() + scheme.toUpperCase() + e.target.value;
    setApplicableInterest(currentRate[accountType + scheme + e.target.value]);
    setschemeName(accountType + scheme);
    const md = new Date();
    md.setMonth(md.getMonth() + parseInt(e.target.value));
    setMatureDate(md.toDateString());
  };

  const makeAccountRequest = async () => {
    try {
      const monthlyAmount = accountType === "RD" ? amount : null;
      setIsLoading(true);
      const newAccount = await createAccountRequest({
        accountHolderDetails: {
          name:
            member.personalInfo.firstName +
            " " +
            member.personalInfo.middleName +
            " " +
            member.personalInfo.lastName,
          dateOfBirth: member.personalInfo.dateOfBirth,
          gender: member.personalInfo.gender,
          emailAddress: member.personalInfo.emailAddress,
          aadharNumber: member.personalInfo.aadharNumber,
          panNumber: member.personalInfo.panNumber,
        },
        credits: [],
        debits: [],
        userId: member.phoneNumber,
        type: schemeName.toUpperCase(),
        creatorId: user._id,
        principalAmounts: [amount],
        balance: amount,
        duration: duration,
        matureDate: new Date(matureDate),
        interestApplicable: [currentRate._id],
        monthlyAmount,
        monthlyDate:
          accountType === "RD" ? (isDaily ? "Daily" : today + ordinal) : null,
        isActive: true,
      });
      setIsLoading(false);
      console.log(newAccount);
      navigate(`/coordinator/accounts/${member.phoneNumber}`);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setErrorMessage(
        "Something went wrong, please try again or contact admin."
      );
    }
  };

  const openAccount = (e) => {
    e.preventDefault();
    setErrorMessage(null);
    setPaymentState({
      isOpen: true,
      totalAmount: amount,
      breakDown: [
        {
          itemName: "Deposit",
          value: amount,
          multiple: 1,
        },
      ],
      handleSuccess: () => makeAccountRequest(),
      handleFailure: () => setErrorMessage("Payment Failed"),
      accountId: user.collectionAccount,
      remark: `Member ${member.phoneNumber} deposit ${accountType}`,
      source: "Member",
      kind: "credit",
    });
  };

  return (
    <div>
      <div className="flex flex-col p-2 justify-center items-center lg:flex-row">
        <img
          src={member.personalInfo.selfPhoto}
          alt="member"
          width={100}
          className="rounded-full object-cover aspect-square mx-5 my-5 shadow-lg shadow-gray-400"
        />
        <div className="flex flex-col lg:flex-row justify-center items-center">
          <div className=" flex flex-col justify-center items-start">
            <p>
              <b>Name:</b> {member.personalInfo.firstName}{" "}
              {member.personalInfo.middleName} {member.personalInfo.lastName}
            </p>
            <p className="flex">
              <b>Is Active: </b>
              {member.hasBeenActivated ? (
                <p className="text-green-600">Yes</p>
              ) : (
                <p className="text-red-600">No</p>
              )}
            </p>
            <p>
              <b>Shares:</b> {member.shares.length}
            </p>
          </div>
        </div>

        {!member.hasBeenActivated && (
          <p className="text-gray-500">
            Please complete the member form to activate member.
          </p>
        )}
      </div>

      <div className="flex flex-col lg:flex-row bg-gray-300 mb-20 justify-center items-center">
        <form
          className="flex flex-col py-4 px-5 lg:px-24 justify-center items-center w-full lg:w-1/2"
          onSubmit={openAccount}
        >
          <h1 className="text-3xl text-brandGreen-500 font-bold m-4">
            Account Details
          </h1>
          <select
            className="py-1.5 px-4 h-full rounded-lg w-full my-2"
            name="accountType"
            onChange={(e) => {
              setAccountType(e.target.value);
              setScheme("default");
              setApplicableInterest(null);
            }}
            value={accountType}
          >
            <option value="default" disabled>
              Account Type
            </option>
            <option value="RD">Recurring Deposit</option>
            <option value="FD">Fixed Deposit</option>
          </select>

          {accountType === "RD" && (
            <div className="flex flex-col w-full px-4">
              <h2 className="font-semibold text-xl">Frequency</h2>
              <div>
                <input
                  type="radio"
                  name="frequency"
                  value={true}
                  id="is-daily"
                  onClick={(e) => setIsDaily(true)}
                />
                <label className="m-2" htmlFor="is-daily">
                  Daily
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  name="frequency"
                  value={false}
                  id="is-monthly"
                  onClick={(e) => setIsDaily(false)}
                />
                <label className="m-2" htmlFor="is-monthly">
                  Monthly
                </label>
              </div>
            </div>
          )}

          <select
            className="py-1.5 px-4 h-full rounded-lg w-full my-2"
            name="scheme"
            onChange={selectScheme}
            value={scheme}
            disabled={accountType === "default"}
          >
            <option value="default" disabled>
              Scheme
            </option>
            <option value="S">Laghu Samyavadhi</option>
            <option value="M">Madhyam Samyavadhi</option>
            <option value="L">Deergha Samyavadhi</option>
          </select>

          <select
            className="py-1.5 px-4 h-full rounded-lg w-full my-2"
            name="duration"
            onChange={selectInterest}
            value={duration}
            disabled={scheme === "default"}
          >
            <option value="default" disabled>
              Period
            </option>
            {availablePeriods.map((period) => (
              <option key={period.value} value={period.value}>
                {period.text}
              </option>
            ))}
          </select>
          <div className="py-1.5 lg:py-0 ">
            <TextInput
              type="Number"
              onSelectType="Number"
              name="amount"
              validate={(text) => {
                if (parseInt(text) < 1) {
                  setAmount(null);
                  return false;
                }
                return true;
              }}
              value={amount}
              handleFormChange={(e) => setAmount(e.target.value)}
              placeholder="Amount"
              isPhoneNumber={true}
              countryCode="₹"
              errorMessage={"Can't be less than ₹1."}
              disabled={duration === "default"}
            />
            <p>Interest Rate: {applicableInterest}%</p>
            {accountType === "RD" && (
              <p>Monthly Date: {isDaily ? "Daily" : today + ordinal}</p>
            )}
          </div>
          {errorMessage && <p className="text-red-500">{errorMessage}</p>}
          <button type="submit">
            <Button
              type="primary"
              text="Open Account"
              additional={"my-4"}
              disabled={!amount}
            />
          </button>
        </form>

        {/* <div className="w-full bg-gray-200 flex flex-col justify-between items-start lg:p-20 p-4">
          <div className="my-4">
            <p>
              <b>Current Rate of Interest: </b>
              {applicableInterest}
            </p>
            <p>
              <b>Mature Date: </b>
              {duration !== "default" && matureDate}
            </p>
            <p>
              <b>Expected Mature Amount: </b>
              {matureAmount}
            </p>
            {accountType === "rd" && (
              <>
                <p>
                  <b>Montly Deposit Amount: </b>
                  {amount && Math.round((amount / duration) * 100) / 100}
                </p>
                <p>
                  <b>Montly Deposit Date: </b>
                  {today + ordinal}
                </p>
              </>
            )}
          </div>
          <div className="bg-gray-400 h-px w-full"></div>
        </div> */}
      </div>
    </div>
  );
};

export default CreateAccountForm;
