export const userRoles = {
  COORDINATOR: "Coordinator",
  MEMBER: "Member",
  ADMIN: "Admin",
  GUEST: "Guest",
};

export const colors = {
  primary: "blue-600",
  secondary: "yellow-600",
  success: "green-600",
  error: "red-600",
};

export const countryCodes = {
  INDIA: "+91",
};

export const images = {
  kshitij_transparent: require("./assets/images/kshitij_transparent.png"),
  kccsl_transparent: require("./assets/images/kccsl_transparent.png"),
  long_bg: require("./assets/images/long_bg.png"),
  light_bulb: require("./assets/images/light_bulb.png"),
  receipt_bg: require("./assets/images/receipt_bg.png"),
  intro_letter_bg: require("./assets/images/intro_letter_bg.png"),
  certificate_bg: require("./assets/images/certificate_bg.png"),
  payment_qr: require("./assets/images/payment_qr.png"),
  loader: require("./assets/images/loader.svg"),
  flip_left: require("./assets/images/flip_left.png"),
  flip_right: require("./assets/images/flip_right.png"),
  rd_interest: require("./assets/images/rd_interest.png"),
  fd_interest: require("./assets/images/fd_interest.png"),
  letter_head: require("./assets/images/letter_head.png"),
  tiranga: require("./assets/images/tiranga.avif"),
};

export const imageSources = {
  receipt_bg: "src/assets/images/receipt_bg.png",
};

export const accountCodes = {
  loan: ["FIXED", "FLEX"],
  account: ["S", "RDS", "RDM", "RDL", "FDS", "FDM", "FDL"],
};
