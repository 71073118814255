import React, { useContext, useRef, useState } from "react";
import { LoadingContext } from "../context/Context";

const TextInput = ({
  handleFormChange,
  errorMessage,
  type = "text",
  onSelectType = "text",
  name,
  placeholder = "",
  validate,
  isPhoneNumber = false,
  countryCode = "",
  disabled,
  value,
  id = "",
  title = null,
}) => {
  const [showErrorMessage, setshowErrorMessage] = useState(false);
  const { setIsLoading } = useContext(LoadingContext);
  const paddingLeft = isPhoneNumber ? "pl-10" : "";
  const noErrorStyle = `${paddingLeft} py-1 px-4 mx-2 w-11/12 lg:w-fit rounded-lg`;
  const errorStyle = `${paddingLeft} py-1 px-4 mx-2 w-11/12 my-1.5 lg:w-fit rounded-lg border-red-500 border-2`;
  const divNoErroStyle = "relative my-2 flex flex-col w-full";
  const divErroStyle = "relative flex flex-col w-full";
  const codeNoErrorStyle =
    "absolute p-0.5 top-0.5 left-2.5 bg-gray-300 rounded-l-lg";
  const codoErrorStyle =
    "absolute p-0.5 top-2.5 left-3 bg-gray-300 rounded-l-lg";

  const ref = useRef();

  return (
    <div className={showErrorMessage ? divErroStyle : divNoErroStyle}>
      <input
        type={type}
        value={value}
        ref={ref}
        title={title}
        onFocus={() => (ref.current.type = onSelectType)}
        className={showErrorMessage ? errorStyle : noErrorStyle}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        id={id}
        onChange={(e) => {
          setshowErrorMessage(false);
          handleFormChange(e);
        }}
        onBlur={async (e) => {
          ref.current.type = { type };
          setIsLoading(true);
          const isValid = await validate(e.target.value);
          setIsLoading(false);
          if (!isValid) setshowErrorMessage(true);
        }}
      />
      {showErrorMessage ? (
        <label className="mx-4 text-red-500 text-semibold">
          {errorMessage}
        </label>
      ) : (
        <></>
      )}
      {isPhoneNumber ? (
        <div className={showErrorMessage ? codoErrorStyle : codeNoErrorStyle}>
          {countryCode}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TextInput;
