import React, { useContext } from "react";
import { RoleContext } from "../context/Context";

const LoanList = ({ loanAccounts }) => {
  const { role } = useContext(RoleContext);

  const collectInterest = () => {};
  const collectPrincipal = () => {};
  return (
    <div className="w-full px-4">
      {loanAccounts && (
        <div className="my-5 mb-20 w-full lg:px-2">
          <div className="w-full">
            <h1 className="font-bold text-center text-2xl ">Current Loans</h1>
            <div className="invisible lg:visible grid grid-cols-7 font-bold">
              <p>S No.</p>
              <p>Amount</p>
              <p>Date Started</p>
              <p>Tenure</p>
              <p>Status</p>
              <p>Instalment Date</p>
              {role === "Coordinator" && <p>Collection</p>}
            </div>
          </div>
          <div className="h-px bg-gray-600 w-full" />
          {loanAccounts.map((loanAccount, ind) => (
            <>
              <div className="flex flex-col lg:grid lg:grid-cols-7 w-full text-center">
                <div className="flex flex-row items-center">
                  <p className="lg:hidden">
                    <b>S No.: </b>
                  </p>
                  <p> {ind + 1}</p>
                </div>
                <div className="flex flex-row items-center">
                  <p className="lg:hidden">
                    <b>Amount: </b>
                  </p>
                  <p> {loanAccount.principalAmounts[0]}</p>
                </div>
                <div className="flex flex-row items-center">
                  <p className="lg:hidden">
                    <b>Date Started</b>
                  </p>
                  <p> {new Date(loanAccount.createdAt).toLocaleDateString()}</p>
                </div>
                <div className="flex flex-row items-center">
                  <p className="lg:hidden">
                    <b>Tenure: </b>
                  </p>
                  {loanAccount.type === "FLEX" ? (
                    <p> Flexible </p>
                  ) : (
                    <p> {loanAccount.duration} years </p>
                  )}
                </div>
                <div className="flex flex-row items-center">
                  <p className="lg:hidden">
                    <b>Status</b>
                  </p>
                  {loanAccount.status === "DENIED" ? (
                    <p className="text-red-500"> {loanAccount.status}</p>
                  ) : (
                    <p className="text-green-500"> {loanAccount.status}</p>
                  )}
                </div>

                <div className="flex flex-row items-center">
                  <p className="lg:hidden">
                    <b>Instalment Date</b>
                  </p>
                  <p> {loanAccount.monthlyDate}</p>
                </div>
                {role === "Coordinator" && (
                  <div className="flex flex-row items-center">
                    <p className="lg:hidden">
                      <b>Collection: </b>
                    </p>
                    <div>
                      <p
                        className="text-brandGreen-500 underline cursor-pointer"
                        onClick={collectInterest}
                      >
                        Interest
                      </p>
                      <p
                        className="text-brandGreen-500 underline cursor-pointer"
                        onClick={collectPrincipal}
                      >
                        Principal
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className="h-px bg-gray-400 w-full" />
            </>
          ))}
        </div>
      )}
    </div>
  );
};

export default LoanList;
