import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from ".";
import { makePaymentRequest } from "../api";
import {
  DemandContext,
  LoadingContext,
  PaymentContext,
  RoleContext,
  UserContext,
} from "../context/Context";

const AccountsDetails = ({ member, accounts }) => {
  const navigate = useNavigate();
  const { role } = useContext(RoleContext);
  return (
    <div className="lg:px-10 w-full">
      <div className="flex flex-col p-2 justify-center items-center my-10">
        <h1 className="font-bold text-2xl">Personal Details</h1>
        <img
          src={member.personalInfo.selfPhoto}
          alt="member"
          width={100}
          className="rounded-full object-cover aspect-square mx-5 my-5 shadow-lg shadow-gray-400"
        />
        <div className="flex flex-col lg:flex-row justify-center items-center">
          <div className=" flex flex-col justify-center items-start">
            <p>
              <b>Name:</b> {member.personalInfo.firstName}{" "}
              {member.personalInfo.middleName} {member.personalInfo.lastName}
            </p>
            <p className="flex">
              <b>Is Active: </b>
              {member.hasBeenActivated ? (
                <p className="text-green-600">Yes</p>
              ) : (
                <p className="text-red-600">No</p>
              )}
            </p>
            <p>
              <b>Shares:</b> {member.shares.length}
            </p>
            <p>
              <b>Credit Limit:</b> {member.creditLine}
            </p>
            <p>
              <b>Credit Effective Date:</b>{" "}
              {new Date(member.creditEffectiveDate).toLocaleDateString()}
            </p>
          </div>
        </div>

        {role === "Coordinator" && (
          <div className="flex m-4 flex-col lg:flex-row w-full justify-evenly">
            <Button
              type="primary"
              text="Open New Account"
              disabled={!member.hasBeenActivated}
              handleClick={() =>
                navigate(`/coordinator/accounts/${member.phoneNumber}/create`)
              }
              additional={"my-2"}
            />
            <Button
              type="secondary"
              text="Shares"
              disabled={!member.hasBeenActivated}
              handleClick={() => {
                navigate(`/coordinator/shares/${member.phoneNumber}`);
              }}
            />
          </div>
        )}
        {!member.hasBeenActivated && (
          <p className="text-gray-500">
            Please complete the member form to activate member.
          </p>
        )}
      </div>

      <div className="h-px bg-gray-600 w-full" />
      <div className="my-5">
        <h1 className="font-bold text-center text-2xl">Accounts</h1>
        <div className="invisible lg:visible grid grid-cols-7 font-bold">
          <p>A/C Number</p>
          <p>A/C Type</p>
          <p>Balance</p>
          <p>Monthly Amount</p>
          <p>Monthly Date</p>
          <p>Mature Date</p>
          {role === "Coordinator" && <p>Actions</p>}
        </div>
        {accounts.map((account) => (
          <AccountDetails role={role} account={account} key={account._id} />
        ))}
      </div>
    </div>
  );
};

export const AccountDetails = ({ account, role }) => {
  const { setPaymentState } = useContext(PaymentContext);
  const { user } = useContext(UserContext);
  const { setDemandState } = useContext(DemandContext);
  const { setIsLoading } = useContext(LoadingContext);
  const navigate = useNavigate();

  const matureDate = account.matureDate
    ? new Date(account.matureDate).toDateString()
    : "N/A";

  const makeCollectionTransaction = async (amt) => {
    try {
      setIsLoading(true);
      const transaction = await makePaymentRequest({
        amount: amt,
        accountId: account._id,
        remark: `Deposit ${account._id}`,
        kind: "credit",
        breakDown: [
          {
            itemName: "Deposit",
            value: amt,
            multiple: 1,
          },
        ],
        source: "member",
        method: "internal",
        proof: "null",
        isCollection: account.type !== "S",
        goesToCredit: account.type !== "S",
        coordinatorId: user._id,
        userId: "",
        password: "",
      });
      console.log(transaction);
      setIsLoading(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      alert("Something went wrong, please reload and try again.");
    }
  };

  const openCollection = () => {
    setPaymentState({
      isOpen: true,
      totalAmount: account.monthlyAmount,
      breakDown: [
        {
          itemName: "Deposit",
          value: account.monthlyAmount,
          multiple: 1,
        },
      ],
      hasFollowTransaction: true,
      handleSuccess: (amount) => makeCollectionTransaction(amount),
      handleFailure: () => alert("Collection failed, try again."),
      accountId: user.collectionAccount,
      remark: `Collection ${account._id}`,
      source: "Member",
      kind: "credit",
    });
  };

  const debitUserAccount = async (amt) => {
    setIsLoading(true);
    const transaction = await makePaymentRequest({
      amount: amt,
      accountId: account._id,
      remark: `Withdrawal ${account._id}`,
      kind: "debit",
      breakDown: [
        {
          itemName: "",
          value: amt,
          multiple: 1,
        },
      ],
      source: "member",
      method: "internal",
      proof: "null",
      userId: "",
      password: "",
    });
    console.log(transaction);
    window.location.reload();
    setIsLoading(false);
  };

  const generateDemand = () => {
    setDemandState({
      isOpen: true,
      account: account,
      handleSuccess: (amount) => debitUserAccount(amount),
      handleFailure: () => alert("Withdrawal demand failed, try again."),
    });
  };

  return (
    <>
      <div className="flex flex-col lg:grid lg:grid-cols-7 justify-evenly m-2">
        <div className="flex flex-row items-center">
          <p className="lg:hidden">
            <b>A/C Number: </b>
          </p>
          {!account.type.includes("fd") ? (
            <p
              className="text-brandGreen-500 underline cursor-pointer"
              onClick={() => {
                navigate(`/accountStatement/${account.accountNumber}`);
              }}
            >
              {" "}
              {account.accountNumber}
            </p>
          ) : (
            <p> {account.accountNumber}</p>
          )}
        </div>
        <div className="flex flex-row items-center">
          <p className="lg:hidden">
            <b>A/C Type: </b>
          </p>
          <p> {account.type}</p>
        </div>
        <div className="flex flex-row items-center">
          <p className="lg:hidden">
            <b>Balance: </b>
          </p>
          <p> {account.balance}</p>
        </div>
        <div className="flex flex-row items-center">
          <p className="lg:hidden">
            <b>Monthly Amount: </b>
          </p>
          <p> {account.monthlyAmount || "N/A"}</p>
        </div>
        <div className="flex flex-row items-center">
          <p className="lg:hidden">
            <b>Monthly Date: </b>
          </p>
          <p> {account.monthlyDate || "N/A"}</p>
        </div>
        <div className="flex flex-row items-center">
          <p className="lg:hidden">
            <b>Mature Date: </b>
          </p>
          <p> {matureDate || "N/A"}</p>
        </div>
        {role === "Coordinator" ? (
          <div className="flex flex-col lg:flex-row items-center">
            {account.type.includes("FD") ? (
              <p className="text-brandGreen-100 px-4">Collect</p>
            ) : (
              <p
                className="text-brandGreen-500 underline px-4 cursor-pointer"
                onClick={openCollection}
              >
                Collect
              </p>
            )}
            {account.type === "S" ? (
              <p
                className="text-brandGreen-500 underline px-4 cursor-pointer"
                onClick={generateDemand}
              >
                Withdraw
              </p>
            ) : (
              <p className="text-brandGreen-100 px-4">Withdraw</p>
            )}
          </div>
        ) : (
          account.type !== "S" &&
          account.type !== "C" && (
            <div className="flex flex-col lg:flex-row items-center">
              <p
                className="text-brandGreen-500 underline px-4 cursor-pointer"
                onClick={() => {
                  navigate(`/accounts/receipt/${account.accountNumber}`);
                }}
              >
                Get Receipt
              </p>
            </div>
          )
        )}
      </div>
      <div className="h-px bg-gray-400 w-full" />
    </>
  );
};

export default AccountsDetails;
