import React, { useContext, useEffect, useState } from "react";
import ListDeposits from "../../components/ListDeposits";
import { LoadingContext, UserContext } from "../../context/Context";
import { getAllDeposits } from "../../api";
import { Button } from "../../components";
import { useNavigate } from "react-router-dom";

const AdminAdmin = () => {
  const { setIsLoading } = useContext(LoadingContext);
  const { user } = useContext(UserContext);
  const [previousDeposits, setPreviousDeposits] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const getDeposits = async () => {
    try {
      setIsLoading(true);
      const deposits = await getAllDeposits();
      setIsLoading(false);
      setPreviousDeposits(deposits.data);
    } catch (error) {
      console.log(error);
      setErrorMessage(error.message);
    }
  };
  const navigate = useNavigate();

  useEffect(() => {
    getDeposits();

    return () => {};
  }, []);

  return (
    <div className="bg-gray-300 w-full py-8 flex flex-col justify-center items-center">
      <div className="mb-4">
        <Button
          type="primary"
          text="Transacitons"
          handleClick={() => navigate("/admin/transactions")}
        />
      </div>
      <h1 className="text-brandGreen-500 font-bold text-4xl text-center">
        Deposits
      </h1>
      {errorMessage ? (
        <p className="font-medium text-red-600 my-2">{errorMessage}</p>
      ) : (
        <></>
      )}
      {previousDeposits && (
        <ListDeposits
          deposits={previousDeposits}
          isAdmin={user.roles.includes("admin")}
          setIsLoading={setIsLoading}
        />
      )}
    </div>
  );
};

export default AdminAdmin;
