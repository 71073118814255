import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RoleContext, UserContext } from "../context/Context";
import { userRoles } from "../constants";

const Home = () => {
  const { user } = useContext(UserContext);
  const { role } = useContext(RoleContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      console.log("no user");
      navigate("/login");
    } else {
      switch (role) {
        case userRoles.COORDINATOR:
          navigate("/coordinator");
          break;

        case userRoles.ADMIN:
          navigate("/admin");
          break;

        case userRoles.MEMBER:
          navigate("/member");
          break;

        default:
          break;
      }
    }
  }, []);

  return <h1>Something went wrong.</h1>;
};

export default Home;
