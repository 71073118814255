import React from "react";

const Button = ({
  text,
  type = "primary",
  disabled = false,
  handleClick,
  additional,
}) => {
  const calculatedType = disabled ? `${type}Disabled` : type;
  const buttonClasses = {
    primary:
      "inline-block px-7 py-3 bg-brandGreen-500 text-white font-medium text-sm leading-snug uppercase rounded-lg shadow-md hover:bg-brandGreen-600 hover:shadow-lg focus:bg-brandGreen-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-brandGreen-400 active:shadow-lg transition duration-150 ease-in-out",
    secondary:
      "inline-block px-7 py-3 bg-gray-100 text-brandGreen-500 font-medium text-sm leading-snug uppercase rounded-lg shadow-md hover:bg-gray-200 hover:shadow-lg focus:bg-gray-200 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-300 active:shadow-lg transition duration-150 ease-in-out",
    primaryDisabled:
      "inline-block px-7 py-3 bg-brandGreen-100 text-white font-medium text-sm leading-snug uppercase rounded-lg shadow-md transition duration-150 ease-in-out",
    secondaryDisabled:
      "inline-block px-7 py-3 bg-gray-100 text-brandGreen-100 font-medium text-sm leading-snug uppercase rounded-lg shadow-md transition duration-150 ease-in-out",
  };

  return (
    <button
      type="button"
      className={`${additional} ${buttonClasses[calculatedType]}`}
      disabled={disabled}
      onClick={handleClick}
    >
      {text}
    </button>
  );
};

export default Button;
