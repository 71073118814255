import React, { useContext } from "react";
import { approveGuaranteeRequest, denyGuaranteeRequest } from "../api";
import { LoadingContext } from "../context/Context";
import Button from "./Button";

const Guarantee = ({ Guarantees }) => {
  const { setIsLoading } = useContext(LoadingContext);
  const approveGuarantee = async (id) => {
    try {
      setIsLoading(true);
      await approveGuaranteeRequest({ id });
      setIsLoading(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const denyGuarantee = async (id) => {
    try {
      setIsLoading(true);
      await denyGuaranteeRequest({ id });
      setIsLoading(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  return (
    <div className="p-4 lg:px-20">
      {Guarantees.map((g) => (
        <div className="bg-white p-5 shadow-sm shadow-gray-500 flex flex-col justify-center items-center">
          <h1 className="text-center text-2xl text-brandGreen-500 font-bold">
            Guarantee Request
          </h1>
          <p className="text-sm m-2">
            Coordinator {g.coordinatorDetails.name} (C
            {g.coordinatorDetails.memberId}) is requesting that you act as
            guarenter for loan availed by {g.borrowerDetails.name} (
            {g.borrowerDetails.memberId}) for amount of <b>₹{g.amount}/-. </b>If
            you accept, the amount will be deducted from your credit limit.
          </p>
          <div className="my-2 flex flex-col">
            <p>
              <b>Coordinator Name: </b>
              {g.coordinatorDetails.name}
            </p>
            <p>
              <b>Coordinator Contact: </b> {g.coordinatorDetails.id}
            </p>
          </div>
          <div className="flex w-full justify-evenly">
            <Button
              text="Approve"
              handleClick={() => approveGuarantee(g._id)}
            />
            <Button
              text="Decline"
              type="secondary"
              handleClick={() => denyGuarantee(g._id)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Guarantee;
