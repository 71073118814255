import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getMemberAccountRequest } from "../api";
import { LoadingContext, PrintContext } from "../context/Context";
import Button from "./Button";
import CoordinatorCertificate from "./CoordinatorCertificate";
import CoordinatorIntroLetter from "./CoordinatorIntroLetter";
import CoordinatorSecurityReceipt from "./CoordinatorSecurityReceipt";

const CoordinatorDetails = ({ coordinator }) => {
  return (
    <div className="px-8 py-8 flex flex-col justify-center items-center">
      <h1 className="text-4xl font-bold text-brandGreen-500 text-center">
        Coordinator Details
      </h1>
      <CoordinatorPersonal coordinator={coordinator} />
      <div className="h-px bg-gray-400 w-full" />
      <CoordinatorDocs coordinator={coordinator} />
      <div className="h-px bg-gray-400 w-full" />
      <CoordinatorAccount collectionAccountId={coordinator.collectionAccount} />
    </div>
  );
};

const CoordinatorPersonal = ({ coordinator }) => {
  const { setIsLoading } = useContext(LoadingContext);
  const [savingsAccount, setSavingsAccount] = useState(null);
  const getSavingsAccount = async () => {
    try {
      setIsLoading(true);
      const result = await getMemberAccountRequest(
        coordinator.mainSavingsAccount
      );
      setSavingsAccount(result.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getSavingsAccount();

    return () => {};
  }, []);

  return (
    <div className="flex flex-col p-2 justify-evenly items-evenly  w-full">
      <div className="flex flex-col lg:flex-row justify-center items-center">
        <img
          src={coordinator.personalInfo.selfPhoto}
          alt="member"
          width={100}
          className="rounded-full object-cover aspect-square mx-5"
        />

        <div className=" flex flex-col justify-center items-center lg:grid lg:grid-cols-3 lg:justify-evenly">
          <p className="w-full  px-4">
            <b>Name: </b>
            {coordinator.personalInfo.firstName}{" "}
            {coordinator.personalInfo.middle}{" "}
            {coordinator.personalInfo.lastName}
          </p>
          <p className="w-full  px-4">
            <b>Phone Number: </b>
            {coordinator.phoneNumber}
          </p>
          <p className="w-full  px-4">
            <b>Coordinator ID: </b>
            {coordinator.coordinatorId}
          </p>
          <p className="w-full  px-4">
            <b>Members Created: </b>
            {coordinator.subMembers.length}
          </p>
          <p className="w-full  px-4">
            <b>Accounts Created: </b>
            {coordinator.subAccounts.length}
          </p>
          <p className="w-full  px-4">
            <b>Total Incentive: </b>
            {coordinator.totalIncentive}
          </p>
        </div>
      </div>
      <div className="w-full flex justify-center">
        {savingsAccount && (
          <div>
            <p>
              <b className="">Savings Account Number:</b>{" "}
              {savingsAccount.accountNumber}
            </p>
            <p>
              <b className="">Savings Account Balance:</b>{" "}
              {savingsAccount.balance}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

const CoordinatorDocs = ({ coordinator }) => {
  const { setPrintComponent, setPrintName } = useContext(PrintContext);
  const navigate = useNavigate();
  return (
    <div className="m-2">
      <h1 className="text-4xl font-bold text-brandGreen-500 text-center">
        Documents
      </h1>
      <div className="flex flex-col lg:flex-row p-2">
        <Button
          text="Certificate"
          additional={"m-4"}
          handleClick={() => {
            setPrintComponent(
              <CoordinatorCertificate coordinator={coordinator} />
            );
            setPrintName("Coordinator_Certificate");
            navigate("/print");
          }}
        />
        {/* <Button
          text="Security Receipt"
          additional={"m-4"}
          handleClick={() => {
            setPrintComponent(
              <CoordinatorSecurityReceipt coordinator={coordinator} />
            );
            setPrintName("Coordinator_Security_Receipt");
            navigate("/print");
          }}
        /> */}
        <Button
          text="Introduction Letter"
          additional={"m-4"}
          handleClick={() => {
            setPrintComponent(
              <CoordinatorIntroLetter coordinator={coordinator} />
            );
            setPrintName("Coordinator_Introduction_Letter");
            navigate("/print");
          }}
        />
      </div>
    </div>
  );
};

const CoordinatorAccount = ({ collectionAccountId, savingsAccountId }) => {
  const [account, setAccount] = useState(null);
  const { setIsLoading } = useContext(LoadingContext);

  const getCollectionAccount = async () => {
    try {
      setIsLoading(true);
      const result = await getMemberAccountRequest(collectionAccountId);
      setAccount(result.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getCollectionAccount();
    return () => {};
  }, []);

  return (
    <div className="m-4 flex flex-col justify-center items-center text-xl">
      <h1 className="text-4xl font-bold text-brandGreen-500 text-center">
        Collection Account Details
      </h1>
      {account && (
        <div>
          <p>
            <b className="">Account Number:</b> {account.accountNumber}
          </p>
          <p>
            <b className="">Account Balance:</b> {account.balance}
          </p>
        </div>
      )}
      <div className="lg:w-3/4 my-5 lg:my-10">
        <p className="text-center text-sm text-gray-500">
          Coordinator account will be suspended if balance in collection account
          is more than ₹5,000/- for more than three days. Please make bank
          deposit accordingly{" "}
        </p>
      </div>
    </div>
  );
};

export default CoordinatorDetails;
