import React, { useContext, useState } from "react";
import { Button } from "../components";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { countryCodes } from "../constants";
import TextInput from "../components/TextInput";
import {
  checkOldMemberIdRequest,
  getMemberRequest,
  updateMemberRequest,
} from "../api";
import MemberDetails from "./MemberDetails";
import {
  LoadingContext,
  PaymentContext,
  RoleContext,
  UserContext,
} from "../context/Context";
import { makeCoordinatorRequest } from "../api";

const CoordinatorForm = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [member, setMember] = useState(null);
  const [isExistingMember, setIsExistingMember] = useState(false);
  const [existingMemberId, setExistingMemberId] = useState();
  const [memberIdError, setMemberIdError] = useState("");
  const [canCreateWithoutPayment, setCanCreateWithoutPayment] = useState(false);

  const { setPaymentState } = useContext(PaymentContext);
  const { user } = useContext(UserContext);
  const { role } = useContext(RoleContext);
  const { setIsLoading } = useContext(LoadingContext);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState();
  const navigate = useNavigate();

  const fetchMemberDetails = async (e) => {
    e.preventDefault();
    setMember(null);
    if (phoneNumber.length !== 10) {
      setErrorMessage("Invalid Phone Number");
      return;
    }
    try {
      setIsLoading(true);
      const existingMember = await getMemberRequest(`+91${phoneNumber}`);
      console.log(existingMember);
      setIsLoading(false);
      if (!existingMember.data.hasBeenActivated) {
        setErrorMessage("Member has not been activated");
        return;
      }
      if (existingMember.data.isCoordinator) {
        setErrorMessage("Member is already a coordinator");
        return;
      }
      setMember(existingMember.data);
    } catch (error) {
      setIsLoading(false);
      setErrorMessage("Member not found. ");
    }
  };

  const makeRequest = async () => {
    console.log("Making request");
    setIsLoading(true);
    const coordinator = await makeCoordinatorRequest({
      phoneNumber: member.phoneNumber,
      converterId: user.phoneNumber,
    });
    setIsLoading(false);
    console.log(coordinator);
    navigate(`/${role.toLowerCase()}/coordinator/${member.phoneNumber}`);
  };

  const makeCoordinator = async () => {
    if (canCreateWithoutPayment) {
      await makeRequest();
      await updateMid();
      return;
    }
    const paymentData = {
      isOpen: true,
      totalAmount: 10000,
      breakDown: [
        { itemName: "Coordinator Secuirty", value: 10000, multiple: 1 },
      ],
      handleSuccess: () => makeRequest(),
      handleFailure: () => setPaymentErrorMessage("Payment Failed"),
      accountId: user.collectionAccount,
      remark: "Coordinator security",
      source: `Member ${member.phoneNumber} -> Coordinator by ${user.phoneNumber}`,
      kind: "credit",
    };
    setPaymentState(paymentData);
  };

  const checkMemberId = async (memberId) => {
    try {
      setIsLoading(true);
      setCanCreateWithoutPayment(false);
      const response = await checkOldMemberIdRequest(memberId);
      console.log(response);
      if (response.data === null) {
        throw new Error("No memebr found with this MID.");
      }
      const oldMemberName = response.data.name;
      let memberName;
      const firstName = member.personalInfo.firstName;
      const middleName = member.personalInfo.middleName;
      const lastName = member.personalInfo.lastName;
      if (middleName !== "") {
        memberName = `${firstName} ${middleName} ${lastName}`;
      } else {
        memberName = `${firstName} ${lastName}`;
      }
      if (oldMemberName !== memberName) {
        throw new Error(
          `MID does not match - ${memberId} is registered with ${oldMemberName}. `
        );
      }
      setMemberIdError("");
      setErrorMessage("");
      setIsLoading(false);
      setCanCreateWithoutPayment(true);
      return true;
    } catch (error) {
      console.log("Error", error);
      setMemberIdError(error.message);
      setIsLoading(false);
    }
  };

  const checkButtonDisabled = () => {
    if (isExistingMember) {
      if (canCreateWithoutPayment) {
        return false;
      }
      return true;
    }
    return false;
  };

  const updateMid = async () => {
    try {
      await updateMemberRequest({
        phoneNumber: member.phoneNumber,
        data: { mid: existingMemberId },
      });
    } catch (error) {
      setErrorMessage("Somehting went wrong...");
    }
  };

  return (
    <div className="">
      <div className="shadow-lg bg-white flex flex-col lg:flex-row justify-center items-center pt-8 lg:justify-evenly lg:p-8">
        <div className="rounded-xl m-8 relative overflow-hidden lg:w-1/3">
          <form onSubmit={fetchMemberDetails}>
            <input
              className="w-full border-2 border-gray-600 rounded-xl py-2 px-12 focus:outline-brandGreen-500"
              placeholder="Search Member"
              type="number"
              value={phoneNumber}
              onChange={(e) => {
                e.preventDefault();
                setPhoneNumber(e.target.value);
                setErrorMessage(null);
              }}
            />
            <div className="absolute top-1 left-1.5 p-1.5 rounded-l-lg  bg-gray-300">
              {countryCodes.INDIA}
            </div>

            {errorMessage ? (
              <p className="font-medium text-red-600 my-2">{errorMessage}</p>
            ) : (
              <></>
            )}
            <button type="submit">
              <div className="absolute top-0 right-0 bg-brandGreen-500 text-white hover:bg-brandGreen-600 p-3.5 cursor-pointer">
                <FaSearch />
              </div>
            </button>
          </form>
        </div>
      </div>
      <div>
        {member && (
          <div className="flex flex-col justify-center items-center">
            <MemberDetails member={member} />
            <div className="flex flex-col justify-center items-center">
              <div className="m-2">
                <label htmlFor="same-as-permanent">Use Old Member ID</label>
                <input
                  type="checkbox"
                  id="same-as-permanent"
                  className="mx-2"
                  onChange={(e) => setIsExistingMember(e.target.checked)}
                />
              </div>
              <div className="p-2 text-center flex justify-center items-center">
                {isExistingMember && (
                  <div className="flex justify-center items-center">
                    <TextInput
                      type="text"
                      onSelectType="text"
                      name="establishmetnName"
                      validate={async (text) => {
                        return checkMemberId(text);
                      }}
                      value={existingMemberId}
                      handleFormChange={(e) => {
                        setErrorMessage(null);
                        setMemberIdError(null);
                        setExistingMemberId(e.target.value);
                      }}
                      placeholder="Member Id"
                      isPhoneNumber={false}
                      errorMessage={memberIdError}
                    />
                    <Button
                      tyoe={"secondary"}
                      text={"Check MID"}
                      additional={""}
                    />
                  </div>
                )}
              </div>
              <Button
                tyoe={"primary"}
                text={"Create Coordinator"}
                additional={"lg:text-xl mb-8"}
                disabled={checkButtonDisabled()}
                handleClick={() => {
                  makeCoordinator();
                }}
              />
            </div>
            <p className="text-red-500">{paymentErrorMessage}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CoordinatorForm;
