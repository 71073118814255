import { useContext, useRef, useState } from "react";
import { CameraContext, LoadingContext } from "../../context/Context";
import { Button } from "../.";
import { AiOutlineDown } from "react-icons/ai";
import { countryCodes } from "../../constants";
import TextInput from "../TextInput";
import { checkUserIdRequest } from "../../api/.";

const PersonalDetailsForm = ({
  handleFormChange,
  isPersonalDataOpen,
  setIsPersonalDataOpen,
  setIsAddressOpen,
  formData,
  setFormData,
  isPersonalDetailsFormValid,
  setIsPersonalDetailsFormValid,
  isMemberUpdate,
}) => {
  const ref = useRef();
  const { cameraState, setCameraState } = useContext(CameraContext);
  const { setIsLoading } = useContext(LoadingContext);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState(
    "Must be 10 digits long."
  );

  const setSelfPhotoFunction = (photoSrc) => {
    setFormData({ ...formData, selfPhoto: photoSrc });
    setIsPersonalDetailsFormValid({
      ...isPersonalDetailsFormValid,
      selfPhoto: true,
    });
  };

  const setAadharFrontFunction = (photoSrc) => {
    setFormData({ ...formData, aadharFront: photoSrc });
  };

  const setAadharBacktFunction = (photoSrc) => {
    setFormData({ ...formData, aadharBack: photoSrc });
  };

  const setPanFunction = (photoSrc) => {
    setFormData({ ...formData, pan: photoSrc });
  };

  const setSignatureFunction = (photoSrc) => {
    setFormData({ ...formData, signature: photoSrc });
  };

  const checkUserPhone = async (phoneNumber) => {
    if (phoneNumber.length === 10) {
      try {
        setIsLoading(true);
        await checkUserIdRequest(`+91${phoneNumber}`);
        setIsLoading(false);
        return true;
      } catch (error) {
        setIsLoading(false);
        setPhoneErrorMessage("Member with this phone number already exists.");
        return false;
      }
    } else {
      setPhoneErrorMessage("Must be 10 digits long.");
      return false;
    }
  };

  return (
    <div className="relative bg-orange-400 mb-2 pt-2 w-full border-b-2 border-white">
      <h1 className="text-white mx-8 mb-2 font-bold">Personal Details</h1>
      <div
        className="text-white absolute top-0 right-0  w-full flex justify-end p-3 shadow-md cursor-pointer"
        onClick={() => {
          setIsPersonalDataOpen(!isPersonalDataOpen);
        }}
      >
        <AiOutlineDown
          className={
            "transition-all duration-300 rotate-180" +
            (!isPersonalDataOpen ? "rotate-180" : "")
          }
        />
      </div>
      <div
        className={
          isPersonalDataOpen
            ? "transition-all duration-300 bg-gray-300 w-full flex flex-col justify-center items-center"
            : "transition-all duration-300 h-0"
        }
      >
        <div className={isPersonalDataOpen ? "visible" : "hidden"}>
          <div className="w-full flex flex-col">
            <div className="relative overflow-hidden rounded-lg flex justify-center items-center">
              <div>
                <TextInput
                  type="number"
                  onSelectType="number"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  handleFormChange={handleFormChange}
                  isPhoneNumber={true}
                  value={formData.phoneNumber}
                  countryCode={countryCodes.INDIA}
                  disabled={isMemberUpdate}
                  validate={(text) => {
                    const isValid = checkUserPhone(text);
                    if (!isValid) {
                      setIsPersonalDetailsFormValid({
                        ...isPersonalDetailsFormValid,
                        phoneNumber: false,
                      });
                    } else {
                      setIsPersonalDetailsFormValid({
                        ...isPersonalDetailsFormValid,
                        phoneNumber: true,
                      });
                    }
                    return isValid;
                  }}
                  errorMessage={phoneErrorMessage}
                />
              </div>
            </div>
            <div className=" w-full flex flex-col justify-center items-center lg:flex-row lg:justify-evenly">
              <div
                className="m-4 p-4 cursor-pointer photo flex justify-center items-center text-xl font-semibold text-gray-600"
                onClick={() => {
                  setCameraState({
                    ...cameraState,
                    isOpen: true,
                    setTargetImage: setSelfPhotoFunction,
                  });
                }}
              >
                {formData.selfPhoto !== "" ? (
                  <img
                    src={formData.selfPhoto}
                    alt={"self"}
                    className="aspect-square object-cover"
                  />
                ) : (
                  <input
                    className="bg-transparent text-center text-gray-600 cursor-pointer"
                    name="selfPhoto"
                    disabled={true}
                    placeholder="Member Photo"
                  />
                )}
              </div>

              <div className="flex flex-col justify-center items-start w-full lg:w-fit lg:grid lg:grid-cols-3 lg:grid-rows-4">
                <TextInput
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  handleFormChange={handleFormChange}
                  value={formData.firstName}
                  errorMessage={"Can't be empty"}
                  id={"isName"}
                  validate={(text) => {
                    const isValid = text.length !== 0;
                    if (!isValid) {
                      setIsPersonalDetailsFormValid({
                        ...isPersonalDetailsFormValid,
                        firstName: false,
                      });
                    } else {
                      setIsPersonalDetailsFormValid({
                        ...isPersonalDetailsFormValid,
                        firstName: true,
                      });
                    }
                    return isValid;
                  }}
                />
                <TextInput
                  type="text"
                  name="middleName"
                  placeholder="Middle Name"
                  id={"isName"}
                  value={formData.middleName}
                  handleFormChange={handleFormChange}
                  errorMessage={"Can't be empty"}
                  validate={(text) => {
                    return true;
                  }}
                />
                <TextInput
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  id={"isName"}
                  value={formData.lastName}
                  handleFormChange={handleFormChange}
                  errorMessage={"Can't be empty"}
                  validate={(text) => {
                    const isValid = text.length !== 0;
                    if (!isValid) {
                      setIsPersonalDetailsFormValid({
                        ...isPersonalDetailsFormValid,
                        lastName: false,
                      });
                    } else {
                      setIsPersonalDetailsFormValid({
                        ...isPersonalDetailsFormValid,
                        lastName: true,
                      });
                    }
                    return isValid;
                  }}
                />
                <TextInput
                  type="text"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  handleFormChange={handleFormChange}
                  errorMessage={"Must be email"}
                  validate={(text) => {
                    const isValid = text.includes("@") && text.includes(".");
                    if (!isValid) {
                      setIsPersonalDetailsFormValid({
                        ...isPersonalDetailsFormValid,
                        email: false,
                      });
                    } else {
                      setIsPersonalDetailsFormValid({
                        ...isPersonalDetailsFormValid,
                        email: true,
                      });
                    }
                    return isValid;
                  }}
                />

                <TextInput
                  type="text"
                  onSelectType="date"
                  name="dateOfBirth"
                  placeholder="Date Of Birth"
                  value={formData.dateOfBirth}
                  ref={ref}
                  onFocus={() => (ref.current.type = "date")}
                  onBlur={() => (ref.current.type = "text")}
                  handleFormChange={handleFormChange}
                  disabled={isMemberUpdate}
                  validate={(text) => {
                    const dob = new Date(text);
                    const current = new Date();
                    current.setFullYear(current.getFullYear() - 18);
                    const isValid = dob < current;
                    if (!isValid) {
                      setIsPersonalDetailsFormValid({
                        ...isPersonalDetailsFormValid,
                        dob: false,
                      });
                    } else {
                      setIsPersonalDetailsFormValid({
                        ...isPersonalDetailsFormValid,
                        dob: true,
                      });
                    }
                    return isValid;
                  }}
                  errorMessage={"Member must be at least 18 years old."}
                />

                <div className="px-2 w-full my-2">
                  <select
                    className="py-1.5 px-4 h-full w-full  rounded-lg"
                    name="gender"
                    placeholder="Gender"
                    onChange={handleFormChange}
                    value={formData.gender}
                    title="Gender"
                  >
                    <option value="default" disabled>
                      Gender
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="others">Other</option>
                  </select>
                </div>

                <TextInput
                  type="text"
                  name="fatherName"
                  placeholder="Father's Name"
                  id={"isName"}
                  value={formData.fatherName}
                  handleFormChange={handleFormChange}
                  errorMessage={"Can't be empty"}
                  validate={(text) => {
                    const isValid = text.length !== 0;
                    if (!isValid) {
                      setIsPersonalDetailsFormValid({
                        ...isPersonalDetailsFormValid,
                        fatherName: false,
                      });
                    } else {
                      setIsPersonalDetailsFormValid({
                        ...isPersonalDetailsFormValid,
                        fatherName: true,
                      });
                    }
                    return isValid;
                  }}
                />
                <TextInput
                  type="text"
                  name="motherName"
                  placeholder="Mother's Name"
                  value={formData.motherName}
                  id={"isName"}
                  handleFormChange={handleFormChange}
                  errorMessage={"Can't be empty"}
                  validate={(text) => {
                    const isValid = text.length !== 0;
                    if (!isValid) {
                      setIsPersonalDetailsFormValid({
                        ...isPersonalDetailsFormValid,
                        motherName: false,
                      });
                    } else {
                      setIsPersonalDetailsFormValid({
                        ...isPersonalDetailsFormValid,
                        motherName: true,
                      });
                    }
                    return isValid;
                  }}
                />

                <div className="px-2 w-full my-2">
                  <select
                    className="py-1.5 px-4 h-full w-full  rounded-lg"
                    name="maritalStatus"
                    onChange={handleFormChange}
                    value={formData.maritalStatus}
                    title="Marrital Status"
                  >
                    <option value="default" disabled>
                      Marital Status
                    </option>
                    <option value="unmarried">Unmarried</option>
                    <option value="married">Married</option>
                    <option value="divorced">Divorced</option>
                    <option value="spouseDeceased">Spouse Deceased</option>
                    <option value="other">Other</option>
                  </select>
                </div>

                <div className="px-2 w-full my-2">
                  <select
                    className="py-1.5 px-4 h-full w-full  rounded-lg"
                    name="religion"
                    onChange={handleFormChange}
                    value={formData.religion}
                    title="Religion"
                  >
                    <option value="default" disabled>
                      Religion
                    </option>
                    <option value="hindu">Hindu</option>
                    <option value="muslim">Muslim</option>
                    <option value="sikh">Sikh</option>
                    <option value="christian">Christian</option>
                    <option value="other">Other</option>
                  </select>
                </div>

                <div className="px-2 w-full my-2">
                  <select
                    className="py-1.5 px-4 h-full w-full  rounded-lg"
                    name="occupation"
                    onChange={handleFormChange}
                    value={formData.occupation}
                    title="Occupation"
                  >
                    <option value="default" disabled>
                      Occupation
                    </option>
                    <option value="student">Student</option>
                    <option value="farmer">Farmer</option>
                    <option value="private">Private</option>
                    <option value="service">Service</option>
                    <option value="business">Business</option>
                    <option value="professional">Professional</option>
                    <option value="govtServices">Govt Services</option>
                    <option value="defenceServices">Defence Services</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="bg-gray-100 h-px w-full"></div>

            <div className="flex flex-col lg:grid lg:grid-cols-2 place-items-center justify-center items-center py-4">
              <div className="">
                <div
                  className="m-4 p-2 cursor-pointer card flex justify-center items-center text-2xl font-semibold text-gray-600"
                  onClick={() => {
                    setCameraState({
                      ...cameraState,
                      isOpen: true,
                      setTargetImage: setAadharFrontFunction,
                    });
                  }}
                >
                  {formData.aadharFront ? (
                    <img src={formData.aadharFront} alt={"self"} />
                  ) : (
                    <input
                      className="bg-transparent text-sm text-center text-gray-600 cursor-pointer"
                      name="aadhar"
                      disabled={true}
                      placeholder="Member Aadhar Front"
                    />
                  )}
                </div>
                <div className="hidden lg:block">
                  <TextInput
                    type="number"
                    onSelectType="number"
                    name="aadharNumber"
                    placeholder="Aadhar Number"
                    disabled={isMemberUpdate}
                    value={formData.aadharNumber}
                    handleFormChange={handleFormChange}
                    errorMessage={"Must be 12 digits long"}
                    validate={(text) => {
                      const isValid = text.length === 12;
                      if (!isValid) {
                        setIsPersonalDetailsFormValid({
                          ...isPersonalDetailsFormValid,
                          aadharNumber: false,
                        });
                      } else {
                        setIsPersonalDetailsFormValid({
                          ...isPersonalDetailsFormValid,
                          aadharNumber: true,
                        });
                      }
                      return isValid;
                    }}
                  />
                </div>
              </div>

              <div className="lg:mb-10">
                <div
                  className="m-4 p-2 cursor-pointer card flex justify-center items-center text-2xl font-semibold text-gray-600"
                  onClick={() => {
                    setCameraState({
                      ...cameraState,
                      isOpen: true,
                      setTargetImage: setAadharBacktFunction,
                    });
                  }}
                >
                  {formData.aadharBack ? (
                    <img src={formData.aadharBack} alt={"self"} />
                  ) : (
                    <input
                      className="bg-transparent text-sm text-center text-gray-600 cursor-pointer"
                      name="aadhar"
                      disabled={true}
                      placeholder="Member Aadhar Back"
                    />
                  )}
                </div>
                <div className="lg:hidden">
                  <TextInput
                    type="number"
                    onSelectType="number"
                    name="aadharNumber"
                    placeholder="Aadhar Number"
                    disabled={isMemberUpdate}
                    value={formData.aadharNumber}
                    handleFormChange={handleFormChange}
                    errorMessage={"Must be 12 digits long"}
                    validate={(text) => {
                      const isValid = text.length === 12;
                      if (!isValid) {
                        setIsPersonalDetailsFormValid({
                          ...isPersonalDetailsFormValid,
                          aadharNumber: false,
                        });
                      } else {
                        setIsPersonalDetailsFormValid({
                          ...isPersonalDetailsFormValid,
                          aadharNumber: true,
                        });
                      }
                      return isValid;
                    }}
                  />
                </div>
              </div>

              <div>
                <div
                  className="m-4 p-2 cursor-pointer card flex justify-center items-center text-2xl font-semibold text-gray-600"
                  onClick={() => {
                    setCameraState({
                      ...cameraState,
                      isOpen: true,
                      setTargetImage: setPanFunction,
                    });
                  }}
                >
                  {formData.pan ? (
                    <img src={formData.pan} alt={"self"} height="" />
                  ) : (
                    <input
                      className="bg-transparent text-sm text-center text-gray-600 cursor-pointer"
                      name="pan"
                      disabled={true}
                      placeholder="Member PAN Photo"
                    />
                  )}
                </div>
                <TextInput
                  type="text"
                  name="panNumber"
                  placeholder="PAN Number"
                  id={"isName"}
                  value={formData.panNumber}
                  handleFormChange={handleFormChange}
                  disabled={isMemberUpdate}
                  errorMessage={"Must be 10 digits long"}
                  validate={(text) => {
                    const isValid = text.length === 10;
                    if (!isValid) {
                      setIsPersonalDetailsFormValid({
                        ...isPersonalDetailsFormValid,
                        panNumber: false,
                      });
                    } else {
                      setIsPersonalDetailsFormValid({
                        ...isPersonalDetailsFormValid,
                        panNumber: true,
                      });
                    }
                    return isValid;
                  }}
                />
              </div>
              <div>
                <div
                  className="m-4 p-2 cursor-pointer card flex justify-center items-center text-2xl font-semibold text-gray-600"
                  onClick={() => {
                    setCameraState({
                      ...cameraState,
                      isOpen: true,
                      setTargetImage: setSignatureFunction,
                    });
                  }}
                >
                  {formData.signature ? (
                    <img src={formData.signature} alt={"self"} />
                  ) : (
                    <input
                      className="bg-transparent text-sm text-center text-gray-600 cursor-pointer"
                      name="signature"
                      disabled={true}
                      placeholder="Member Sign Photo"
                    />
                  )}
                </div>
                <h1 className="py-0.5 px-4 my-3 w-full text-center font-bold">
                  Signature
                </h1>
              </div>
            </div>
            <div className="flex flex-col-reverse lg:flex-row w-full justify-center items-center mb-4">
              <Button
                text={"Continue"}
                type="primary"
                additional={"mx-4"}
                handleClick={() => {
                  setIsPersonalDataOpen(false);
                  setIsAddressOpen(true);
                  const addressForm = document.getElementById("address-form");
                  addressForm.scrollIntoView({ behavior: "smooth" });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetailsForm;
